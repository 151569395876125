import classnames from "classnames";
import PropTypes from "prop-types";
import "./ScrollerPositionMarker.scss";

const ScrollerPositionMarker = props => {
  // ************************************
  // Properties
  // ************************************

  const classPrefix = "bwl-ScrollerPositionMarker";

  const { theme, alignment, current, total, onClick } = props;

  // ************************************
  // General Functionality
  // ************************************

  const getClampedCurrent = () => {
    let clampedCurrent = current;

    if (current > total - 1) {
      clampedCurrent = 0;
    } else if (current < 0) {
      clampedCurrent = total - 1;
    }

    return clampedCurrent;
  };

  // ************************************
  // Render Functionality
  // ************************************

  const getDots = () => {
    let dotElements = [];

    for (let i = 0; i < total; i++) {
      dotElements.push(
        <div
          key={`dot_item_${i}`}
          className={`${classPrefix}__grid__item`}
          onClick={() => {
            // eslint-disable-next-line no-unused-expressions
            onClick ? onClick(i) : null;
          }}
        >
          <div
            className={classnames(`${classPrefix}__grid__item__graphic`, {
              [`${classPrefix}__dot-graphic__${theme}`]: i !== getClampedCurrent(),
              [`${classPrefix}__dot-graphic__${theme}__active`]: i === getClampedCurrent(),
              [`${classPrefix}__grid__item__interactive`]: onClick,
            })}
          ></div>
        </div>
      );
    }

    return dotElements;
  };

  // ************************************
  // Render
  // ************************************

  return (
    <div className={`${classPrefix}`}>
      <div
        className={classnames(`${classPrefix}__grid`, {
          [`${classPrefix}__grid__${alignment}`]: alignment,
        })}
      >
        {getDots()}
      </div>
    </div>
  );
};

// ************************************
// Validation
// ***********************************

ScrollerPositionMarker.propTypes = {
  theme: PropTypes.oneOf(["light", "dark"]),
  alignment: PropTypes.oneOf(["vertical", "horizontal"]),
  current: PropTypes.number,
  total: PropTypes.number,
};

ScrollerPositionMarker.defaultProps = {
  theme: "light",
  alignment: "vertical",
  current: 0,
  total: 1,
};

export default ScrollerPositionMarker;
