import React, {useRef} from 'react';
import classnames from 'classnames';
import Arrow from '../Arrow';
import './anchor.scss';
import useHover from './useHover';

export default ({children, as, arrow, ...other}) => {
  const clx = classnames('bwl-anchor--container');
  const [hoverRef, isHovered] = useHover();
  const Comp = as ? as : 'a';
  let content = children;
  if (React.Children.count(children) === 1 && typeof children === 'string') {
    content = <span className="bwl-anchor bwl-anchor--container__text bwl-anchor--container__text--with-arrow">{children}</span>;
  }
  return <Comp ref={hoverRef} className={clx} {...other}>
    {content}
    {arrow && <Arrow
      className="bwl-anchor__arrow"
      isDisabled={other.disabled}
      isFocused={isHovered}
      variant="medium"
    />}
  </Comp>;
}