import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Card, Heading, Text } from "../../../";
import { GridColumn, GridRow } from "../../../Grid";
import "./topicHeader.scss";

class TopicHeader extends React.PureComponent {
  render() {
    const { contentBackground, className, image, title, text, bottomContent } = this.props;
    const headerClasses = classnames(className, "bwl-header", "bwl-base-header", `bwl-header--${contentBackground}-content-background`);

    return (
      <div className="bwl-topic-header">
        <header className={headerClasses}>
          <div className="bwl-header__top-container">
            <GridRow backgroundImage={image}>
              <GridColumn className="bwl-header__content-column" span={8}>
                <Card backgroundColor={contentBackground}>
                  {title && (
                    <div className="bwl-header__title-container">
                      <Heading className="bwl-header__title" variant="h1">
                        {title}
                      </Heading>
                    </div>
                  )}
                  {text && (
                    <div className="bwl-header__text-container">
                      <Text variant="lead" className="bwl-header__text">
                        {text}
                      </Text>
                    </div>
                  )}
                  {bottomContent && <div className="bwl-header__bottom-content">{bottomContent}</div>}
                </Card>
              </GridColumn>
            </GridRow>
          </div>
        </header>
      </div>
    );
  }
}
TopicHeader.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.shape({
    fallback: PropTypes.string,
    webp: PropTypes.string.isRequired
  }),
  bottomContent: PropTypes.array,
  className: PropTypes.string,
  contentBackground: PropTypes.oneOf(["purple", "transparent", "white"])
};

export default TopicHeader;
