import React from "react";

export const RectanglesCutTop = ({ strokeColor = "#46264E", strokeWidth = 4, ...rest }) => {
  return (
    <svg width="1035" height="480" viewBox="240 0 1035 480" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect x="268.587" y="-54.4539" width="120.858" height="56.1126" transform="rotate(45 268.587 -54.4539)" stroke={strokeColor} strokeWidth={strokeWidth} />
      <rect x="589.06" y="-100.236" width="120.858" height="120.858" transform="rotate(45 589.06 -100.236)" stroke={strokeColor} strokeWidth={strokeWidth} />
      <rect x="497.496" y="-8.67212" width="56.1126" height="120.858" transform="rotate(45 497.496 -8.67212)" stroke={strokeColor} strokeWidth={strokeWidth} />
      <rect x="634.842" y="37.1101" width="120.858" height="120.858" transform="rotate(45 634.842 37.1101)" stroke={strokeColor} strokeWidth={strokeWidth} />
      <rect x="543.278" y="220.238" width="120.858" height="56.1126" transform="rotate(45 543.278 220.238)" stroke={strokeColor} strokeWidth={strokeWidth} />
      <rect x="589.06" y="174.456" width="56.1126" height="56.1126" transform="rotate(45 589.06 174.456)" stroke={strokeColor} strokeWidth={strokeWidth} />
      <rect x="543.278" y="37.1096" width="56.1126" height="56.1126" transform="rotate(45 543.278 37.1096)" stroke={strokeColor} strokeWidth={strokeWidth} />
      <rect x="817.969" y="128.674" width="56.1126" height="56.1126" transform="rotate(45 817.969 128.674)" stroke={strokeColor} strokeWidth={strokeWidth} />
      <rect x="405.932" y="174.455" width="56.1126" height="56.1126" transform="rotate(45 405.932 174.455)" stroke={strokeColor} strokeWidth={strokeWidth} />
      <rect x="1001.1" y="-146.017" width="120.858" height="185.603" transform="rotate(45 1001.1 -146.017)" stroke={strokeColor} strokeWidth={strokeWidth} />
      <rect x="909.533" y="37.1101" width="120.858" height="120.858" transform="rotate(45 909.533 37.1101)" stroke={strokeColor} strokeWidth={strokeWidth} />
      <rect x="726.405" y="-54.4534" width="185.603" height="120.858" transform="rotate(45 726.405 -54.4534)" stroke={strokeColor} strokeWidth={strokeWidth} />
      <rect x="817.969" y="-54.4534" width="120.858" height="56.1126" transform="rotate(45 817.969 -54.4534)" stroke={strokeColor} strokeWidth={strokeWidth} />
      <rect x="863.751" y="174.456" width="120.858" height="120.858" transform="rotate(45 863.751 174.456)" stroke={strokeColor} strokeWidth={strokeWidth} />
      <rect x="772.188" y="266.02" width="56.1126" height="120.858" transform="rotate(45 772.188 266.02)" stroke={strokeColor} strokeWidth={strokeWidth} />
      <rect x="909.533" y="311.801" width="120.858" height="120.858" transform="rotate(45 909.533 311.801)" stroke={strokeColor} strokeWidth={strokeWidth} />
      <rect x="817.969" y="311.801" width="56.1126" height="56.1126" transform="rotate(45 817.969 311.801)" stroke={strokeColor} strokeWidth={strokeWidth} />
      <rect x="1046.88" y="-8.67212" width="56.1126" height="120.858" transform="rotate(45 1046.88 -8.67212)" stroke={strokeColor} strokeWidth={strokeWidth} />
      <rect x="1046.88" y="82.8916" width="120.858" height="120.858" transform="rotate(45 1046.88 82.8916)" stroke={strokeColor} strokeWidth={strokeWidth} />
      <rect x="1001.1" y="220.237" width="185.603" height="120.858" transform="rotate(45 1001.1 220.237)" stroke={strokeColor} strokeWidth={strokeWidth} />
      <rect x="955.315" y="174.455" width="56.1126" height="56.1126" transform="rotate(45 955.315 174.455)" stroke={strokeColor} strokeWidth={strokeWidth} />
    </svg>
  );
};

export const RectlangesWithTop = ({ strokeColor = "#46264E", strokeWidth = 4, ...rest }) => {
  return (
    <svg width="1041" height="1042" viewBox="0 0 1041 1042" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect x="268.587" y="275.073" width="120.858" height="120.858" transform="rotate(45 268.587 275.073)" stroke={strokeColor} strokeWidth={strokeWidth}/>
      <rect x="177.023" y="366.637" width="56.1126" height="120.858" transform="rotate(45 177.023 366.637)" stroke={strokeColor} strokeWidth={strokeWidth}/>
      <rect x="314.369" y="412.419" width="120.858" height="120.858" transform="rotate(45 314.369 412.419)" stroke={strokeColor} strokeWidth={strokeWidth}/>
      <rect x="177.023" y="458.201" width="120.858" height="120.858" transform="rotate(45 177.023 458.201)" stroke={strokeColor} strokeWidth={strokeWidth}/>
      <rect x="268.587" y="549.765" width="56.1126" height="56.1126" transform="rotate(45 268.587 549.765)" stroke={strokeColor} strokeWidth={strokeWidth}/>
      <rect x="222.805" y="412.419" width="56.1126" height="56.1126" transform="rotate(45 222.805 412.419)" stroke={strokeColor} strokeWidth={strokeWidth}/>
      <rect x="497.496" y="503.983" width="56.1126" height="56.1126" transform="rotate(45 497.496 503.983)" stroke={strokeColor} strokeWidth={strokeWidth}/>
      <rect x="85.46" y="549.765" width="56.1126" height="56.1126" transform="rotate(45 85.46 549.765)" stroke={strokeColor} strokeWidth={strokeWidth}/>
      <rect x="589.06" y="137.728" width="120.858" height="120.858" transform="rotate(45 589.06 137.728)" stroke={strokeColor} strokeWidth={strokeWidth}/>
      <rect x="680.624" y="229.292" width="120.858" height="185.603" transform="rotate(45 680.624 229.292)" stroke={strokeColor} strokeWidth={strokeWidth}/>
      <rect x="589.06" y="412.419" width="120.858" height="120.858" transform="rotate(45 589.06 412.419)" stroke={strokeColor} strokeWidth={strokeWidth}/>
      <rect x="405.933" y="320.855" width="185.603" height="120.858" transform="rotate(45 405.933 320.855)" stroke={strokeColor} strokeWidth={strokeWidth}/>
      <rect x="497.496" y="320.855" width="120.858" height="56.1126" transform="rotate(45 497.496 320.855)" stroke={strokeColor} strokeWidth={strokeWidth}/>
      <rect x="543.278" y="275.073" width="56.1126" height="56.1126" transform="rotate(45 543.278 275.073)" stroke={strokeColor} strokeWidth={strokeWidth}/>
      <rect x="772.188" y="229.292" width="56.1126" height="56.1126" transform="rotate(45 772.188 229.292)" stroke={strokeColor} strokeWidth={strokeWidth}/>
      <rect x="817.97" y="275.073" width="120.858" height="120.858" transform="rotate(45 817.97 275.073)" stroke={strokeColor} strokeWidth={strokeWidth}/>
      <rect x="726.406" y="366.638" width="56.1126" height="120.858" transform="rotate(45 726.406 366.638)" stroke={strokeColor} strokeWidth={strokeWidth}/>
      <rect x="863.752" y="412.419" width="120.858" height="120.858" transform="rotate(45 863.752 412.419)" stroke={strokeColor} strokeWidth={strokeWidth}/>
      <rect x="726.406" y="458.201" width="120.858" height="120.858" transform="rotate(45 726.406 458.201)" stroke={strokeColor} strokeWidth={strokeWidth}/>
      <rect x="772.188" y="595.547" width="120.858" height="56.1126" transform="rotate(45 772.188 595.547)" stroke={strokeColor} strokeWidth={strokeWidth}/>
      <rect x="817.971" y="549.765" width="56.1126" height="56.1126" transform="rotate(45 817.971 549.765)" stroke={strokeColor} strokeWidth={strokeWidth}/>
      <rect x="772.188" y="412.419" width="56.1126" height="56.1126" transform="rotate(45 772.188 412.419)" stroke={strokeColor} strokeWidth={strokeWidth}/>
      <rect x="634.843" y="549.765" width="56.1126" height="56.1126" transform="rotate(45 634.843 549.765)" stroke={strokeColor} strokeWidth={strokeWidth}/>
    </svg>
  );
};

export const TopicRectangles = ({ strokeColor = "#46264E", strokeWidth = 4, ...rest }) => {
  return (
    <svg width="337" height="491" viewBox="0 0 337 491" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect x="131.019" y="-0.218155" width="56.1126" height="120.858" transform="rotate(45 131.019 -0.218155)" stroke={strokeColor} strokeWidth={strokeWidth} />
          <rect x="268.365" y="45.5637" width="120.858" height="120.858" transform="rotate(45 268.365 45.5637)" stroke={strokeColor} strokeWidth={strokeWidth} />
          <rect x="359.928" y="137.127" width="120.858" height="185.603" transform="rotate(45 359.928 137.127)" stroke={strokeColor} strokeWidth={strokeWidth} />
          <rect x="176.801" y="228.691" width="120.858" height="56.1126" transform="rotate(45 176.801 228.691)" stroke={strokeColor} strokeWidth={strokeWidth} />
          <rect x="222.583" y="182.909" width="56.1126" height="56.1126" transform="rotate(45 222.583 182.909)" stroke={strokeColor} strokeWidth={strokeWidth} />
          <rect x="176.801" y="45.5637" width="56.1126" height="56.1126" transform="rotate(45 176.801 45.5637)" stroke={strokeColor} strokeWidth={strokeWidth} />
          <rect x="39.4551" y="182.909" width="56.1126" height="56.1126" transform="rotate(45 39.4551 182.909)" stroke={strokeColor} strokeWidth={strokeWidth} />
          <rect x="178.677" y="321.003" width="56.1126" height="56.1126" transform="rotate(45 178.677 321.003)" stroke={strokeColor} strokeWidth={strokeWidth} />
          <rect x="224.463" y="366.003" width="56.1126" height="120.864" transform="rotate(45 224.463 366.003)" stroke={strokeColor} strokeWidth={strokeWidth} />
    </svg>
  );
}

export const RectanglesWithTop = RectlangesWithTop;