import React from "react";
import MapContainer from "../components/MapContainer";
import { getPropValue } from "../logic/contentLogic";

export const renderMapNestedContent = (content, props = {}) => {
  const title = getPropValue(content, "title");
  const link = getPropValue(content, "link");
  const endpoint = getPropValue(content, "mapEndpoint");

  return <MapContainer title={title} link={link} endpoint={endpoint} />;
};
