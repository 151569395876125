import React from "react";
import MemberIFrame from "../components/MemberIFrame";
import { getPropValue, getUniqueIdForContentTypeAlias } from "../logic/contentLogic";

import classnames from "classnames";
import { Grid, GridColumn, GridRow } from "../bob-web-library/components";
import { contentTypeAliasAsCssClass, get, getPropVariant } from "../logic/contentLogic";

export const renderNestedIFramqe = (content, props = {}) => {
  const title = getPropValue(content, "iFrame");

  return (
    <React.Fragment key={getUniqueIdForContentTypeAlias(content.contentTypeAlias)}>
      <MemberIFrame title={title} />
    </React.Fragment>
  );
};

export const renderNestedIFrame = (content, props = {}) => {
  const alignItem = get(content, "props.alignItem.value", undefined);
  return (
    <Grid key={getUniqueIdForContentTypeAlias(content.contentTypeAlias)}>
      <GridRow
        key={getUniqueIdForContentTypeAlias(content.contentTypeAlias)}
        className={classnames("row-content", contentTypeAliasAsCssClass(content.contentTypeAlias))}
        alignItem={getPropVariant(alignItem)}
      >
        <GridColumn
          key={getUniqueIdForContentTypeAlias(content.contentTypeAlias)}
          className={classnames("column-content", contentTypeAliasAsCssClass(content.contentTypeAlias))}
          span={6}
          offset={4}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: content.props.iFrame.value,
            }}
          ></div>
        </GridColumn>
      </GridRow>
    </Grid>
  );
};
