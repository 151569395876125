import PropTypes from "prop-types";
import React from "react";

const ThickArrowRight = ({ className, fill = "000", width = 12, height = 21 }) => {
    return (
        <svg
            className={className}
            width={width}
            height={height}
            viewBox="0 0 13 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path fillRule="evenodd" clipRule="evenodd" d="M0.761522 0.768845C1.77689 -0.256282 3.42311 -0.256282 4.43848 0.768845L12.2385 8.64384C13.2538 9.66897 13.2538 11.331 12.2385 12.3562L4.43848 20.2312C3.42311 21.2563 1.77689 21.2563 0.761522 20.2312C-0.253841 19.206 -0.253841 17.544 0.761522 16.5188L6.72304 10.5L0.761522 4.48116C-0.253841 3.45603 -0.253841 1.79397 0.761522 0.768845Z" fill={`${fill}`} />
        </svg>
    );
}

// ************************************
// Validation
// ***********************************

ThickArrowRight.propTypes = {
    className: PropTypes.string,
    fill: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number
}

ThickArrowRight.defaultProps = {
    fill: "000",
    width: 12,
    height: 21
}

export default ThickArrowRight;