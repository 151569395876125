export function registerListener(event, fn) {
  if (window.addEventListener) {
    window.addEventListener(event, fn);
  } else {
    window.attachEvent("on" + event, fn);
  }
}

export function isInViewport(ref) {
  const rect = ref.current.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.left <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

export function canUseWebP() {
  // https://stackoverflow.com/questions/5573096/detecting-webp-support
  var elem = document.createElement("canvas");

  if (!!(elem.getContext && elem.getContext("2d"))) {
    // was able or not to get WebP representation
    return elem.toDataURL("image/webp").indexOf("data:image/webp") == 0;
  }

  // very old browser like IE 8, canvas not supported
  return false;
}

export const isSvgSupported = () => document.implementation.hasFeature("http://www.w3.org/TR/SVG11/feature#Image", "1.1");
