import React from "react";
import { getContentPageProps } from "../logic/contentLogic";

const Campaign = React.lazy(() => import("../components/Campaign"));

export const renderCampaignContent = (content, props = {}) => {
  const Helmet = props.helmet;

  return (
    <>
      <Helmet />
      <Campaign key={content.id} {...getContentPageProps(content)} />
    </>
  );
};
