import React, { useEffect } from "react";
import "./vimeoPlayerModal.scss";

const VimeoPlayerModal = props => {
  const { videoId, closeVideo } = props;

  useEffect(() => {
    const escFunction = event => {
      if (event.keyCode === 27) {
        closeVideo();
      }
    };
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  });

  return (
    <div className="bwl-video-modal__container">
      <div className="bwl-video-modal__video-wrapper">
        <button className="bwl-video-modal__close-button" onClick={closeVideo} autoFocus>
          ✖
        </button>
        <div className="bwl-video-modal__video">
          <iframe src={`https://player.vimeo.com/video/${videoId}?title=0&byline=0&portrait=0`} allow="autoplay; fullscreen" allowFullScreen />
        </div>
      </div>
    </div>
  );
};

export default VimeoPlayerModal;
