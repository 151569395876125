import PropTypes from "prop-types";
import BreadcrumbBWL from "../../bob-web-library/components/Breadcrumb";
import { LINK_INTERNAL } from "../../constants/umbracoConstants";
import { ensureTrailingSlash } from "../../logic/navigationLogic";

const createBreadcrumbPaths = path => {
  var pathParts = path.split("/").filter(e => e !== "");

  var breadcrumbs = [];
  const pathCount = pathParts.length;
  for (let i = 0; i < pathCount; i++) {
    breadcrumbs.push(`/${pathParts.join("/")}/`);
    pathParts.pop();
  }
  breadcrumbs.reverse();
  breadcrumbs.pop();
  return breadcrumbs;
};

const Breadcrumb = ({ currentPath, sitemapUrls, className }) => {
  const breadcrumbPaths = createBreadcrumbPaths(currentPath);
  const links = breadcrumbPaths.map(e => ({ link: { url: e, ...LINK_INTERNAL }, name: sitemapUrls[e] ? sitemapUrls[e].name : "" }));
  if (links.length === 0 || !sitemapUrls[ensureTrailingSlash(currentPath)]) {
    return null;
  }
  const siteMapHit = sitemapUrls[ensureTrailingSlash(currentPath)];
  return <BreadcrumbBWL crumbs={links} topic={siteMapHit} className={className} />;
};

Breadcrumb.propTypes = {
  //** The path to the page displaying the breadcrumb trail*/
  currentPath: PropTypes.string.isRequired,
  //** Object return from sitemap.url */
  sitemapUrls: PropTypes.object.isRequired,
};

export default Breadcrumb;
