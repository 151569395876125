export const variantProps = {
  horizontalOverlap: {
    useBackgroundImage: true,
    contentSpan: 7,
    contentSpanMobile: 11,
    contentOffset: 1,
    contentOffsetMobile: 2,
    imageSpan: 12,
    imageSpanMobile: 12,
    imageOffset: 1,
    imageOffsetMobile: 1,
    imageIsFirst: false,
    imageIsFirstOnMobile: false
  },
  verticalOverlap: {
    useBackgroundImage: false,
    contentSpan: 10,
    contentSpanMobile: 11,
    contentOffset: 3,
    contentOffsetMobile: 2,
    imageSpan: 10,
    imageSpanMobile: 11,
    imageOffset: 1,
    imageOffsetMobile: 1,
    imageIsFirst: true,
    imageIsFirstOnMobile: true
  }
};
