import React from "react";
import PropTypes from "prop-types";
import "./message-box.scss";

const MessageBox = props => {
  const { state } = props;
  return (
    state !== null && (
      <div className={`bwl-message-box bwl-message-box--${state.status}`}>
        <h5>{state.status}</h5>
        <span>{state.message}</span>
      </div>
    )
  );
};

MessageBox.propTypes = {
  state: PropTypes.shape(PropTypes.string, PropTypes.string)
};

MessageBox.defaultProps = {
  message: "No error"
};

export default MessageBox;
