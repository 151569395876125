import classnames from "classnames";
import React from "react";
import { contentTypeAliasAsCssClass, getUniqueIdForContentTypeAlias } from "../logic/contentLogic";
import { renderContent } from "./contentFactory";

export const renderSingleFeaturedItemContent = (content, props = {}) => {
  return (
    <div
      key={getUniqueIdForContentTypeAlias(content.contentTypeAlias)}
      className={classnames("single-featured-item-content", contentTypeAliasAsCssClass(content.contentTypeAlias))}
    >
      {renderContent({
        ...content,
        contentTypeAlias: "featuredItemNestedContent"
      })}
    </div>
  );
};
