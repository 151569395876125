import classnames from "classnames";
import styled from "styled-components";
import { Grid, GridColumn, GridRow } from "../bob-web-library/components";
import { offsetMap, spanMap } from "../constants/gridLayoutConstants";
import { contentTypeAliasAsCssClass, get, getPropVariant, getUniqueIdForContentTypeAlias } from "../logic/contentLogic";
import { renderContent } from "./contentFactory";
import { renderRichText } from "./internalRichTextFactory";
import { renderNestedIFrame } from "./nestedIFrameContentFactory";

const renderRowContent = nestedContent => {
  if (nestedContent.contentTypeAlias === "richTextEditorNestedContent") {
    return renderRichText(nestedContent);
  } else if (nestedContent.contentTypeAlias === "iFrameNestedContent") {
    return renderNestedIFrame(nestedContent);
  } else if (nestedContent.contentTypeAlias === "imageNestedContent") {
    return <ImageNoPaddingContainer>{renderContent(nestedContent)}</ImageNoPaddingContainer>;
  } else {
    return renderContent(nestedContent);
  }
};

const ImageNoPaddingContainer = styled.div`
  & > .bwl-grid > .bwl-grid-row > .bwl-grid-column {
    grid-column: span 12;
  }
`;

export const renderRowNestedContent = (content, props = {}) => {
  const rowContent = get(content, "props.row.value", {}, props);
  const variant = get(content, "props.columnArrangement.value", undefined);
  const alignItem = get(content, "props.alignItem.value", undefined);

  const displayContentAsRows = variant === "Vertical columns";
  const span = displayContentAsRows ? 12 : 12 / rowContent.length;
  const hasValidColumnTemplate = offsetMap[variant] && rowContent.length === offsetMap[variant].length;
  const renderColumnsWithUnformWidth = variant === "Uniform width" || displayContentAsRows || !hasValidColumnTemplate;

  return (
    <Grid>
      <GridRow
        key={getUniqueIdForContentTypeAlias(content.contentTypeAlias)}
        className={classnames("row-content", contentTypeAliasAsCssClass(content.contentTypeAlias))}
        alignItem={getPropVariant(alignItem)}
      >
        {rowContent.map((nestedContent, index) => (
          <GridColumn
            key={getUniqueIdForContentTypeAlias(nestedContent.contentTypeAlias)}
            className={classnames("column-content", contentTypeAliasAsCssClass(nestedContent.contentTypeAlias))}
            span={renderColumnsWithUnformWidth ? span : spanMap[variant][index]}
            offset={renderColumnsWithUnformWidth ? null : offsetMap[variant][index]}
          >
            {renderRowContent(nestedContent)}
          </GridColumn>
        ))}
      </GridRow>
    </Grid>
  );
};
