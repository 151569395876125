import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import Image from "../../bob-web-library/components/Image";
import { getLogo } from "../../logic/contentLogic";
import "./logoContainer.scss";

class LogoContainer extends React.Component {
  render() {
    const { className, onClick, logoType } = this.props;
    const logo = getLogo(logoType);
    const image = { webp: logo, fallback: logo, alt: "BOB hjemmeside" };

    return (
      <div className={classnames("logo-container", className)} onClick={onClick}>
        <Link to={"/"}>
          <Image image={image} />
        </Link>
      </div>
    );
  }
}

LogoContainer.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  logoType: PropTypes.string,
};

export default LogoContainer;
