import React from "react";
import { CROP_SIZE } from "../constants/imageSizeConstants";
import { get, getImageCropUrl, getPropValue, getUniqueIdForContentTypeAlias } from "../logic/contentLogic";

const FeaturedContentCard = React.lazy(() => import("../bob-web-library/components/FeaturedContentCard"));

export const renderFeaturedContentCardNestedContent = (content, props = {}) => {
  const variantRaw = getPropValue(content, "transition") || "verticalOverlap";
  const variant = variantRaw === "horizontalOverlap" ? "horizontalOverlap" : "verticalOverlap";

  const link = getPropValue(content, "link") || null;
  const image = getPropValue(content, "image");
  const smallerHeading = getPropValue(content, "smallerHeading") || false;

  return (
    link && (
      <FeaturedContentCard
        key={getUniqueIdForContentTypeAlias(get(content, "contentTypeAlias", "featured-item"))}
        variant={variant}
        tag={getPropValue(content, "tag")}
        title={getPropValue(content, "title")}
        text={getPropValue(content, "text")}
        link={link}
        image={getImageCropUrl(image, CROP_SIZE.MEDIUM, "jpg")}
        smallerHeading={smallerHeading}
      />
    )
  );
};
