export const offsetTop = element => {
  const rect = element.getBoundingClientRect();
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return rect.top + scrollTop;
};

export const scroll = {
  scrollTo: (el, behavior = "smooth") => {
    window.scrollTo({ top: offsetTop(el), left: 0, behavior: behavior });
  },

  scrollToTop: () => {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  },

  shouldScrollToTop: (currentPath, prevPath) => {
    return currentPath !== prevPath || (currentPath === "/" && prevPath === "/");
  },

  getAnchor: location => {
    return location.hash.replace("#", "");
  },

  getAnchorElement: location => {
    const { hash } = location;
    return hash && document.querySelector(hash);
  },

  shouldScrollToAnchor: (el, prevState, currentState) => {
    return el && prevState === currentState;
  },
};
