import classnames from "classnames";
import React from "react";
import "./svg-button.scss";

const SvgButton = props => {
  const { svg, title, round, className, click } = props;
  const btnClass = classnames("svg-button", { "svg-button--round": round }, className);
  return (
    <button className={btnClass} onClick={click}>
      <img src={svg} alt={title}></img>
    </button>
  );
};

export default SvgButton;
