import history from "../history";

const isDifferentRouteThanUrl = (link, currentLocation) => {
  return link !== currentLocation;
};

const navigateTo = to => {
  history.push(to);
};

const makeItemsDisabled = (items, currentLocation) => {
  return Object.values(items).map(topLevel => {
    const isSameRoute = !isDifferentRouteThanUrl(topLevel.titleLink.url, currentLocation);
    return {
      ...topLevel,
      titleDisabled: topLevel.titleDisabled !== undefined ? topLevel.titleDisabled : isSameRoute,
      collectionItems: topLevel.collectionItems.map(linkCollectionItem => {
        const isSameRoute = !isDifferentRouteThanUrl(linkCollectionItem.link.url, currentLocation);
        return { ...linkCollectionItem, disabled: linkCollectionItem.disabled !== undefined ? linkCollectionItem.disabled : isSameRoute };
      }),
    };
  });
};

const ensureTrailingSlash = path => {
  if ([...path].pop() === "/") return path;
  return path + "/";
};

export { isDifferentRouteThanUrl, makeItemsDisabled, navigateTo, ensureTrailingSlash };
