import React, { PureComponent } from "react";
import { isFetchFailed } from "../loadingStates";
import { hasFactoryFor } from "./../factories/contentFactory";
import lazyRoute from "./lazyRoute";
import loadContent from "./loadContent";

const dynamicContentRoute = (ContentPage, PageNotFound) => {
  return loadContent(
    lazyRoute(
      class dynamicContentRoute extends PureComponent {
        render() {
          const { contentEntity } = this.props;
          const hasFactory = hasFactoryFor(contentEntity.content.contentTypeAlias);
          return isFetchFailed(contentEntity) || !hasFactory ? <PageNotFound /> : <ContentPage {...this.props} />;
        }
      }
    )
  );
};

export default dynamicContentRoute;
