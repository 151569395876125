import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import "./fullscreenImage.scss";

const FullscreenImage = (props) => {

    // ************************************
    // Properties
    // ************************************

    const classPrefix = "bwl-FullscreenImage";

    const {
        theme,
        url,
        caption
    } = props;

    // ************************************
    // Render template
    // ************************************

    return (
        <div className={classnames(`${classPrefix}`, {
            [`${classPrefix}__background__${theme}`]: theme,
            [`${classPrefix}__with-caption`]: caption
        })}>
            <div
                className={classnames(`${classPrefix}__image`, {
                    [`${classPrefix}__with-caption__image`]: caption
                })}
                style={{
                    backgroundImage: `url(${url})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                }}></div>
            {caption &&
                <div className={classnames(`${classPrefix}__caption`, {
                    [`${classPrefix}__background__${theme}`]: theme
                })}>
                    <label className={classnames(`${classPrefix}__caption__text`, {
                        [`${classPrefix}__text__${theme}`]: theme
                    })}>{caption}</label>
                </div>
            }
        </div>
    );
}

// ************************************
// Validation
// ***********************************

FullscreenImage.propTypes = {
    theme: PropTypes.oneOf(["light", "dark"]),
    url: PropTypes.string,
    caption: PropTypes.string
}

FullscreenImage.defaultProps = {
    theme: "light"
}

export default FullscreenImage;