import { AUTH_PROXY_URL } from "../constants/forgeRockConstants";

export const USER_PROPERTIES_SUCCESS = "USER_PROPERTIES_SUCCESS";
export const USER_PROPERTIES_FAILED = "USER_PROPERTIES_FAILED";
export const USER_PROPERTIES_STARTED = "USER_PROPERTIES_STARTED";

export function userPropertiesStarted() {
  return {
    type: USER_PROPERTIES_STARTED
  };
}

export function userPropertiesSuccess(json) {
  return {
    type: USER_PROPERTIES_SUCCESS,
    json,
    receivedAt: Date.now()
  };
}

export function userPropertiesFailed(error) {
  return {
    type: USER_PROPERTIES_FAILED,
    error: error
  };
}

export const userPropertiesService = token => {
  const devUrl = `${AUTH_PROXY_URL}user/properties`;
  return dispatch => {
    dispatch(userPropertiesStarted());
    return fetch(devUrl, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token: token
      })
    })
      .then(response => {
        if (!response.ok) throw response;
        return response.json();
      })
      .then(json => dispatch(userPropertiesSuccess(json)))
      .catch(error => dispatch(userPropertiesFailed(error)));
  };
};
