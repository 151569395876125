import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import "./menuItem.scss";

const MenuItem = React.forwardRef((props, ref) => {
  const { className, leftPaddingDesktop, leftPaddingMobile, rightPaddingMobile, removable, noHover, fullWidth, lessPadding, onClick, ...rest } = props;

  const classes = classnames("bwl-menu-item", className, {
    "bwl-menu-item--left-padding-desktop": leftPaddingDesktop,
    "bwl-menu-item--left-padding-mobile": leftPaddingMobile,
    "bwl-menu-item--right-padding-mobile": rightPaddingMobile,
    "bwl-menu-item--removable": removable,
    "bwl-menu-item--no-hover": noHover,
    "bwl-menu-item--full-width": fullWidth,
    "bwl-menu-item--less-padding": lessPadding
  });

  return (
    <div ref={ref} className={classes} onClick={onClick} {...rest}>
      {props.children}
    </div>
  );
});

MenuItem.propTypes = {
  /** OnClick function */
  onClick: PropTypes.func,
  /** Less padding towards edge items */
  leftPaddingDesktop: PropTypes.bool,
  /** Less padding towards edge items */
  leftPaddingMobile: PropTypes.bool,
  /** Less padding towards edge items */
  rightPaddingMobile: PropTypes.bool,
  /** Is removed upon resizing */
  removable: PropTypes.bool,
  /** No hover effects */
  noHover: PropTypes.bool,
  /** Span the full width */
  fullWidth: PropTypes.bool,
  /** Sets less padding on the menuItem. Useful for <Search/> */
  lessPadding: PropTypes.bool,
  /** classname injector. */
  className: PropTypes.string
};

export default MenuItem;
