import React from "react";
import MemberIFrame from "../components/MemberIFrame";
import { getPropValue, getUniqueIdForContentTypeAlias } from "../logic/contentLogic";

export const renderNewMemberIFrame = (content, props = {}) => {
  const title = getPropValue(content, "iFrameTitle");

  return (
    <React.Fragment key={getUniqueIdForContentTypeAlias(content.contentTypeAlias)}>
      <MemberIFrame title={title} />
    </React.Fragment>
  );
};
