import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import "./text.scss";

export const textMapping = {
  lead: "lead",
  titleTag: "title-tag",
  description: "description",
  paragraph: "paragraph",
};

const Text = ({ children, className, animate, variant, component = "p", ...other }) => {
  return React.createElement(
    component,
    {
      className: classnames(className, "bwl-text", [`bwl-text--${textMapping[variant]}`], {
        "bwl-text--animate": animate,
      }),
      ...other,
    },
    children
  );
};

Text.defaultProps = {
  variant: "lead",
};

Text.propTypes = {
  /** Children for the component */
  children: PropTypes.node,
  /** Class name for root node */
  className: PropTypes.string,
  /** Text style as defined by design */
  variant: PropTypes.oneOf(["lead", "titleTag", "description", "paragraph"]),
};

export default Text;
