import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { CaptionImage, Heading, Text } from "../../../";
import { GridColumn, GridRow } from "../../../Grid";
import { MaxWidthWrapper } from "../../util/MaxWidthWrapper";
import "./newsHeader.scss";

class NewsHeader extends React.PureComponent {
  render() {
    const { className, title, text, image, imageCaption, bottomText } = this.props;
    const headerClasses = classnames(className, "bwl-news-header");

    return (
      <div className={headerClasses}>
        <header className="bwl-news-header__header">
          <MaxWidthWrapper>
            <GridRow>
              <GridColumn span={12}>
                {title && (
                  <div className="bwl-news-header__title-wrapper">
                    <Heading className="bwl-news-header__title" variant="h1">
                      {title}
                    </Heading>
                  </div>
                )}
              </GridColumn>
            </GridRow>
            <GridRow>
              <GridColumn span={8} offset={3}>
                {text && (
                  <Text variant="lead" className="bwl-news-header__text">
                    {text}
                  </Text>
                )}
                {bottomText && (
                  <Text variant="description" className="bwl-news-header__text">
                    {bottomText}
                  </Text>
                )}
              </GridColumn>
            </GridRow>
          </MaxWidthWrapper>
        </header>
        <MaxWidthWrapper>
          <CaptionImage image={image} caption={imageCaption} />
        </MaxWidthWrapper>
      </div>
    );
  }
}

NewsHeader.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  bottomText: PropTypes.string,
  imageCaption: PropTypes.string,
  image: PropTypes.shape({
    fallback: PropTypes.string.isRequired,
    webp: PropTypes.string.isRequired
  })
};

export default NewsHeader;
