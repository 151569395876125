// It should to be noted that this function isn't equivalent to `text-transform: capitalize`.
//
// A strict capitalization should uppercase the first letter of each word a the sentence.
// We only handle the first word.
export function capitalize(string) {
  if (process.env.NODE_ENV !== "production" && typeof string !== "string") {
    throw new Error("Material-UI: capitalize(string) expects a string argument.");
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function contains(obj, pred) {
  return Object.keys(pred).every(key => obj.hasOwnProperty(key) && obj[key] === pred[key]);
}

export function findIndex(arr, pred) {
  const predType = typeof pred;
  for (let i = 0; i < arr.length; i += 1) {
    if (predType === "function" && !!pred(arr[i], i, arr) === true) {
      return i;
    }
    if (predType === "object" && contains(arr[i], pred)) {
      return i;
    }
    if (["string", "number", "boolean"].indexOf(predType) !== -1) {
      return arr.indexOf(pred);
    }
  }
  return -1;
}

export function find(arr, pred) {
  const index = findIndex(arr, pred);
  return index > -1 ? arr[index] : undefined;
}

/**
 * Safe chained function
 *
 * Will only create a new function if needed,
 * otherwise will pass back existing functions or null.
 *
 * @param {function} functions to chain
 * @returns {function|null}
 */
export function createChainedFunction(...funcs) {
  return funcs.reduce(
    (acc, func) => {
      if (func == null) {
        return acc;
      }
      return function chainedFunction(...args) {
        acc.apply(this, args);
        func.apply(this, args);
      };
    },
    () => {}
  );
}
