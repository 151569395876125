import {GET_PERSON_SUCCESS, GET_PERSON_START, GET_PERSON_FAILED} from '../constants/personConstants';

export default (state = {loading: false}, action) => {
  const {payload = {}} = action;
  switch (action.type) {
    case GET_PERSON_SUCCESS:
      return {
        ...state,
        loading: false,
        person: payload.person
      };
    case GET_PERSON_FAILED:
      return {...state, loading: false, errorMessage: payload.message};
    case GET_PERSON_START:
      return {...state, loading: true};

    default:
      return state;
  }
};
