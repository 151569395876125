import classnames from "classnames";
import _chunk from "lodash/chunk";
import PropTypes from "prop-types";
import { Image, LinkWrapper, RectanglesCutTop, Text } from "../../bob-web-library/components";
import { LOGO_FOOTER, SOME } from "../../constants/footerConstants";
import LogoContainer from "../LogoContainer";
import { LOGO_FOOTER_MOBILE } from "./../../constants/footerConstants";
import "./footer.scss";

const renderLinkCollections = linkBatches => {
  return linkBatches.map((items, index) => (
    <div className="footer__column" key={`footer__collection-list-${index}`}>
      <ul className="footer__collection-list">
        {items.map((link, index) => (
          <li key={`footer__collection-item-${index}`} className="footer__collection-item">
            <LinkWrapper link={link}>
              <Text variant="paragraph">{link.name}</Text>
            </LinkWrapper>
          </li>
        ))}
      </ul>
    </div>
  ));
};

const renderIconCollection = SOME => {
  return (
    <div className="footer__content-horizontal">
      {SOME.map((icon, index) => (
        <div className="footer__image-container" key={`some-icon-${index}`}>
          <LinkWrapper link={icon.link}>
            <Image image={{ webp: icon.src, fallback: icon.src, alt: icon.alt }} noLazyLoad />
          </LinkWrapper>
        </div>
      ))}
    </div>
  );
};

const renderDesktopFooter = (linkBatches, props) => {
  return (
    <>
      <div className="footer__content footer__content--desktop">
        <div className="footer__row">
          <div className="footer__column footer__logo">
            <LogoContainer logoType={LOGO_FOOTER} {...props} />
          </div>
          {renderLinkCollections(linkBatches)}
          {renderIconCollection(SOME)}
        </div>
      </div>
    </>
  );
};

const renderMobileFooter = (linkBatches, props) => {
  return (
    <>
      <div className="footer__content footer__content--mobile">
        <div className="footer__row">{renderLinkCollections(linkBatches)}</div>
        <div className="footer__row">
          <div className="footer__column">
            <LogoContainer logoType={LOGO_FOOTER_MOBILE} {...props} />
          </div>
          <div className="footer__column">
            <div className="footer__some-mobile">
              {SOME.map((icon, index) => (
                <LinkWrapper link={icon.link} key={`some-icon-${index}`}>
                  <Image image={{ webp: icon.src, fallback: icon.src, alt: icon.alt }} noLazyLoad />
                </LinkWrapper>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Footer = ({ links, linkBatch = 4, className, ...props }) => {
  const linkBatches = _chunk(links, linkBatch);
  const classNameFooter = classnames("footer", className);

  return (
    props.isMenuOpen || (
      <footer className={classNameFooter}>
        {renderMobileFooter(linkBatches, props)}
        {renderDesktopFooter(linkBatches, props)}
        <div className="footer__background">
          <RectanglesCutTop className="footer__background-svg" aria-label="Rotated rectangles" />
        </div>
      </footer>
    )
  );
};

Footer.propTypes = {
  /** link array containing objects with `url` and `name` with option for `type` and `target` */
  links: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      name: PropTypes.string,
      type: PropTypes.number,
      target: PropTypes.target,
    })
  ),
  /** how many links that should be included in each column */
  linkBatch: PropTypes.number,
};

export default Footer;
