import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import CollectionItem from "../CollectionItem";
import Icon from "../Icon";
import LinkWrapper from "../LinkWrapper";
import { Heading } from "../Typography";
import "./link-collection.scss";

class LinkCollection extends React.Component {
  render() {
    const { icon, title, titleLink, titleDisabled, items, openTitleLinkInNewWindow } = this.props;
    return (
      <div className="bwl-link-collection">
        {icon && (
          <div className="bwl-link-collection__icon">
            <Icon icon={icon} />
          </div>
        )}
        <div className="bwl-link-collection__title">
          <LinkWrapper link={titleLink} disabled={titleDisabled}>
            <Heading variant="h4" animate={!titleDisabled}>
              {title}
            </Heading>
          </LinkWrapper>
        </div>
        <ul className="bwl-link-collection__list">
          {items.map((element, index) => {
            const classNames = classnames("bwl-link-collection__item", { "bwl-link-collection__item--disabled": element.disabled });
            return (
              <li key={`bwl-list-${index}`} className={classNames}>
                <LinkWrapper link={element.link} disabled={element.disabled}>
                  <CollectionItem marginRight icon={element.icon} text={element.text} />
                </LinkWrapper>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

/**
 *
 *
 */

LinkCollection.propTypes = {
  /** Title for the group of links */
  title: PropTypes.string.isRequired,
  /** Link to the top level page for the links contained in items */
  titleLink: PropTypes.shape({
    target: PropTypes.string,
    type: PropTypes.number,
    url: PropTypes.string.isRequired
  }).isRequired,
  /** Link to the top level page for the links contained in items is disabled */
  titleDisabled: PropTypes.bool,
  /** Icon to render above title */
  icon: PropTypes.string,
  /** An array of links with to and text,  */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      link: PropTypes.shape({
        target: PropTypes.string,
        type: PropTypes.number,
        url: PropTypes.string.isRequired
      }).isRequired,
      text: PropTypes.string,
      disabled: PropTypes.bool
    })
  ).isRequired
};

export default LinkCollection;
