import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import GridRow from "../GridRow";
import "./grid.scss";

const Grid = props => {
  const { children, className } = props;

  return <div className={classnames(className, "bwl-grid")}>{children}</div>;
};

Grid.propTypes = {
  /** A number of "GridRow"s */
  children: PropTypes.node.isRequired,
  /** Injected className */
  className: PropTypes.string
};

export default Grid;
