import umbracoService from "../services/umbracoService";

export const IMPORTANT_MESSAGE_FETCH_STARTED = "IMPORTANT_MESSAGE_FETCH_STARTED";
export const IMPORTANT_MESSAGE_FETCH_SUCCESS = "IMPORTANT_MESSAGE_FETCH_SUCCESS";
export const IMPORTANT_MESSAGE_FETCH_FAILED = "IMPORTANT_MESSAGE_FETCH_FAILED";

export function importantMessageFetchStarted(id) {
  return {
    type: IMPORTANT_MESSAGE_FETCH_STARTED,
    id
  };
}

export function importantMessageFetchSuccess(id, importantMessage) {
  return {
    type: IMPORTANT_MESSAGE_FETCH_SUCCESS,
    id,
    importantMessage
  };
}

export function importantMessageFetchFailed(id, error) {
  return {
    type: IMPORTANT_MESSAGE_FETCH_FAILED,
    id,
    error: error
  };
}

export const getImportantMessageById = id => {
  return async dispatch => {
    dispatch(importantMessageFetchStarted(id));
    try {
      const importantMessage = await umbracoService.getPublishedContentById(id);
      dispatch(importantMessageFetchSuccess(id, importantMessage));
    } catch (e) {
      dispatch(importantMessageFetchFailed(id, e));
    }
  };
};
