import React from "react";
import { CROP_SIZE } from "../constants/imageSizeConstants";
import { get, getImageCropUrl, getPropValue, getUniqueIdForContentTypeAlias } from "../logic/contentLogic";
import { renderContent } from "./contentFactory";

const FeaturedItem = React.lazy(() => import("../bob-web-library/components/FeaturedItem"));

export const renderFeaturedItemNestedContent = (content, props = {}) => {
  const variantRaw = getPropValue(content, "transition");
  const variant = variantRaw === "horizontalImageRight" ? "horizontalImageRight" : "horizontalImageLeft";
  const link = getPropValue(content, "link") || null;
  const linkText = get(link, "name") || "default link text";
  const smallerHeading = getPropValue(content, "smallerHeading") || false;
  const sideContentRaw = getPropValue(content, "nestedSideContent");
  const sideContent = sideContentRaw && sideContentRaw.length > 0 ? renderContent(sideContentRaw[0]) : null;
  const bottomContentRaw = getPropValue(content, "nestedBottomContent");
  const bottomContent = bottomContentRaw && bottomContentRaw.length > 0 ? renderContent(bottomContentRaw[0]) : null;

  const imageRaw = getPropValue(content, "image");
  const imageOverflow = getPropValue(content, "imageOverflow") || false;
  const image = imageOverflow ? getImageCropUrl(imageRaw, CROP_SIZE.LARGE, "jpg") : getImageCropUrl(imageRaw, CROP_SIZE.XLARGE);
  const imageOrNestedContentProp = sideContent ? { sideContent: sideContent } : { image: image };

  return (
    <FeaturedItem
      key={getUniqueIdForContentTypeAlias(get(content, "contentTypeAlias", "featured-item"))}
      variant={variant}
      tag={getPropValue(content, "tag")}
      title={getPropValue(content, "title")}
      text={getPropValue(content, "text")}
      link={link}
      linkText={linkText}
      sideContent={sideContent}
      {...imageOrNestedContentProp}
      smallerHeading={smallerHeading}
      imageOverflow={imageOverflow}
      bottomContent={bottomContent}
    />
  );
};
