import React from "react";
import { SuspenseContent } from "../bob-web-library/components";
import { getPropValue, getUniqueIdForContentTypeAlias } from "../logic/contentLogic";

const YouTubeVideo = React.lazy(() => import("../components/YouTubeVideo"));

export const renderYouTubeVideoNestedContent = (content, props) => {
  return (
    <SuspenseContent>
      <YouTubeVideo
        key={getUniqueIdForContentTypeAlias(content.contentTypeAlias)}
        isWide={getPropValue(content, "isWide")}
        id={getPropValue(content, "youTubeId")}
      />
    </SuspenseContent>
  );
};
