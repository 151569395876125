import "babel-polyfill";
import React from "react";
import "react-app-polyfill/ie9";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import smoothscroll from "smoothscroll-polyfill";
import { Routes } from "./routes";
import store from "./store";
import "./styles/index.scss";

ReactDOM.render(
  <Provider store={store}>
    <Routes />
  </Provider>,
  document.getElementById("app")
);

smoothscroll.polyfill();
