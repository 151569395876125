import {GET_PERSON_START, GET_PERSON_SUCCESS, GET_PERSON_FAILED} from '../constants/personConstants';
import {bobApiUrl} from '../constants/auth0';

const getPersonStart = () => ({type: GET_PERSON_START});
const getPersonSuccess = ({person}) => ({type: GET_PERSON_SUCCESS, payload: {person}});
const getPersonFailed = ({message}) => ({type: GET_PERSON_FAILED, payload: {message}});


export const getPerson = ({bobPersonId, getAccessTokenSilently}) => async (dispatch) => {
  try {
    dispatch(getPersonStart());
    const at = await getAccessTokenSilently();
    if (!at) {
      dispatch(getPersonFailed({message: 'No access token present.'}));
    }

    const response = await fetch(`${bobApiUrl}/person/v2/Person/${bobPersonId}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${at}`
      }
    });
    if (!response.ok) {
      dispatch(getPersonFailed({message: `Status: ${response.status} - ${response.statusText}`}));
    }
    else if (!response.headers.get('content-type').includes('application/json')) {
      dispatch(getPersonFailed({message: `Response was not json. Status: ${response.status} - ${response.statusText}`}));

    } else {
      const person = await response.json();
      dispatch(getPersonSuccess({person}))
    }
  } catch (e) {
    dispatch(getPersonFailed({message: e.toString()}))
  }


};