import { VIMEO_API_BASE_ADDRESS, VIMEO_BASE_ADDRESS } from "../constants/vimeoConstants";
import { ApiClient } from "../utils/apiClient";

const client = new ApiClient();

export default {
  getVideoByIdWidthHeight: (id, width, height) => {
    const url = VIMEO_BASE_ADDRESS + id;

    // Vimeo CORS policy: Request header field content-type is not allowed by Access-Control-Allow-Headers
    const headers = {};
    return client.get(VIMEO_API_BASE_ADDRESS, { url, width, height }, { headers });
  }
};
