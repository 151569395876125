import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Heading, Text } from "../../../";
import { GridColumn, GridRow } from "../../../Grid";
import { MaxWidthWrapper } from "../../util/MaxWidthWrapper";
import "./videoHeader.scss";

class VideoHeader extends React.PureComponent {
  render() {
    const { className, title, text, videoId } = this.props;
    const headerClasses = classnames(className, "bwl-video-header");

    return (
      <div className={headerClasses}>
        <header className="bwl-video-header__header">
          <MaxWidthWrapper>
            <GridRow>
              <GridColumn span={10} offset={2}>
                {title && (
                  <div className="bwl-video-header__title-wrapper">
                    <Heading className="bwl-video-header__title" variant="h1">
                      {title}
                    </Heading>
                  </div>
                )}
              </GridColumn>
            </GridRow>
            <GridRow>
              <GridColumn span={8} offset={3}>
                {text && (
                  <Text variant="lead" className="bwl-video-header__text">
                    {text}
                  </Text>
                )}
              </GridColumn>
            </GridRow>
          </MaxWidthWrapper>
        </header>
        <MaxWidthWrapper>
          <GridRow>
            <GridColumn span={10} offset={2}>
              <div className="bwl-video">
                <iframe src={`https://player.vimeo.com/video/${videoId}?title=0&byline=0&portrait=0`} allow="autoplay; fullscreen" allowFullScreen />
              </div>
            </GridColumn>
          </GridRow>
        </MaxWidthWrapper>
      </div>
    );
  }
}

VideoHeader.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  videoId: PropTypes.string.isRequired,
};

export default VideoHeader;
