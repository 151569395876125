import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./paper.scss";

function Paper(props) {
  const { classes, className: classNameProp, component: Component, square, elevation, ...other } = props;

  const className = classNames(
    classes.root,
    classes[`elevation${elevation}`],
    {
      [classes.rounded]: !square
    },
    classNameProp
  );

  return <Component className={className} {...other} />;
}

Paper.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css-api) below for more details.
   */
  classes: PropTypes.object.isRequired,
  /**
   * @ignore
   */
  className: PropTypes.string,
  /**
   * The component used for the root node.
   * Either a string to use a DOM element or a component.
   */
  component: PropTypes.elementType,
  /**
   * Shadow depth, corresponds to `dp` in the spec.
   * It's accepting values between 0 and 24 inclusive.
   */
  elevation: PropTypes.number,
  /**
   * If `true`, rounded corners are disabled.
   */
  square: PropTypes.bool
};

Paper.defaultProps = {
  component: "div",
  elevation: 2,
  square: false
};

const withStyles = () => Component => {
  class WithStyles extends React.Component {
    render() {
      const { classes, ...other } = this.props;
      return <Component {...other} classes={this.props.classes || {}} />;
    }
  }
  return WithStyles;
};

export default withStyles()(Paper);
