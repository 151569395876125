export const UMBRACO_BASE_ADDRESS = process.env.REACT_APP_UMBRACO_BASE_ADDRESS;
export const UMBRACO_API_BASE_ADDRESS = process.env.REACT_APP_UMBRACO_API_BASE_ADDRESS;
export const ROOT_CONTENT_ID = process.env.REACT_APP_UMBRACO_ROOT_CONTENT_ID;
export const ROOT_SETTINGS_ID = process.env.REACT_APP_UMBRACO_ROOT_SETTINGS_ID;
export const ROOT_IMPORTANT_MESSAGE_ID = process.env.REACT_APP_UMBRACO_ROOT_IMPORTANT_MESSAGE_ID;
export const POLL_SITEMAP_INTERVAL_IN_SECONDS = 900;
export const LINK_EXTERNAL = { type: 2 };
export const LINK_INTERNAL = { type: 0 };
export const LINK_NEW_WINDOW = { target: "_blank" };
export const CMS_MEDIA_ADDRESS = "https://cms.bob.no/media/";
