import hoistNonReactStatic from "hoist-non-react-statics";
import React from "react";

export const withFocusState = Component => {
  class FocusHoc extends React.Component {
    constructor(props) {
      super(props);

      this.handleFocusChange = this.handleFocusChange.bind(this);
      this.state = {
        isFocused: false,
      };
    }

    handleFocusChange(e) {
      this.setState({
        isFocused: e.type === "mouseenter" || e.type === "focus",
      });
    }

    render() {
      return (
        <Component
          onFocus={this.handleFocusChange}
          onBlur={this.handleFocusChange}
          onMouseEnter={this.handleFocusChange}
          onMouseLeave={this.handleFocusChange}
          isFocused={this.state.isFocused}
          {...this.props}
        />
      );
    }
  }
  hoistNonReactStatic(FocusHoc, Component);
  FocusHoc.displayName = Component.displayName || Component.name;

  return FocusHoc;
};
