import React from "react";
import SuspenseContent from "../bob-web-library/components/SuspenseContent";
import contentLoader from "../components/ContentLoader";

const lazyRoute = Component => {
  return class lazyRoute extends React.PureComponent {
    render() {
      return (
        <SuspenseContent fallback={contentLoader()}>
          <Component {...this.props} />
        </SuspenseContent>
      );
    }
  };
};

export default lazyRoute;
