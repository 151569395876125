import React from "react";
import { SuspenseContent } from "../bob-web-library/components";
import contentLoader from "../components/ContentLoader";
import { getContentPageProps } from "../logic/contentLogic";
const Animation = React.lazy(() => import("../components/Animation"));

export const renderAnimationNestedContent = (content, props = {}) => {
  return (
    <SuspenseContent fallback={contentLoader("Video laster")}>
      <Animation {...getContentPageProps(content)} />
    </SuspenseContent>
  );
};
