import PropTypes from "prop-types";
import React from "react";

const PlayIcon = ({ className, fill = "000", width = 222, height = 176 }) => {
    return (
        <svg
            width={222}
            height={176}
            viewBox={`0 0 ${222} ${176}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_d)">
                <path
                    className={className}
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M68 44C56.9543 44 48 52.9543 48 64V103.14C48 114.186 56.9543 123.14 68 123.14H154C165.046 123.14 174 114.186 174 103.14V64C174 52.9543 165.046 44 154 44H68ZM95.3025 105.238C95.0304 105.095 94.8598 104.814 94.8598 104.507V63.6741C94.8598 63.3671 95.0304 63.0854 95.3025 62.9431C95.5419 62.818 95.8275 62.818 96.0669 62.9431L134.818 83.2043C135.533 83.5784 135.533 84.6025 134.818 84.9766L96.0669 105.238C95.8275 105.363 95.5419 105.363 95.3025 105.238Z"
                    fill="white"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d"
                    x="0"
                    y="0"
                    width={width}
                    height={height}
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="24" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.133333 0 0 0 0 0.133333 0 0 0 0 0.133333 0 0 0 0.08 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                </filter>
            </defs>
        </svg>
    );
}

// ************************************
// Validation
// ***********************************

PlayIcon.propTypes = {
    className: PropTypes.string,
    fill: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number
}

PlayIcon.defaultProps = {
    fill: "000",
    width: 12,
    height: 21
}

export default PlayIcon;