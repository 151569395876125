import PropTypes from "prop-types";
import React from "react";
import Card from "../Card";
import Grid, { GridColumn, GridRow } from "../Grid";
import Image from "../Image";
import { Heading, Text } from "../Typography";
import "./contactCard.scss";

const wordBreakEmail = email => {
  const s = email.split("@");
  if (s.length !== 2) {
    return email;
  }
  return (
    <>
      {s[0]}
      @
      <wbr />
      {s[1]}
    </>
  );
};

const formatPhoneNumber = phonenumber => {
  const phoneString = phonenumber + "";
  if (phoneString.length !== 8) {
    return phonenumber;
  }
  const formatted = phoneString.substr(0, 3) + " " + phoneString.substr(3, 2) + " " + phoneString.substr(5);
  return formatted;
};

const renderDesktop = props => {
  const { title, text, job, name, phoneNumber, email, image } = props;
  return (
    <div>
      <div className="bwl-contact__highlight" />
      <div className="bwl-contact">
        <div className="bwl-contact__featured-column">
          <Heading className="bwl-contact__title" variant="h2">
            {title}
          </Heading>
          <Text className="bwl-contact__text">{text}</Text>
        </div>
        <div className="bwl-contact__employee">
          <Image image={image} />
        </div>
        <Card className="bwl-contact__card">
          {job && (
            <Text variant="titleTag" className="bwl-contact__job">
              {job}
            </Text>
          )}
          {name && (
            <Heading variant={"h4"} className="bwl-contact__name">
              {name}
            </Heading>
          )}
          {phoneNumber && (
            <Text variant="paragraph" className="bwl-contact__phone-number">
              <a href={`tel:+47${phoneNumber}`}>{formatPhoneNumber(phoneNumber)}</a>
            </Text>
          )}

          {email && (
            <Text variant="paragraph" className="bwl-contact__email">
              <a href={`mailto:${email}`}>{wordBreakEmail(email)}</a>
            </Text>
          )}
        </Card>
      </div>
    </div>
  );
};
const renderMobile = props => {
  const { title, text, job, name, phoneNumber, email, image } = props;
  return (
    <div className="bwl-contact-mobile">
      <Grid>
        <GridRow backgroundSpan={3} backgroundSpanMobile={6} backgroundImage={image || null}>
          <GridColumn className="bwl-contact-mobile__featured-column" span={5} spanMobile={12} offset={2} offsetMobile={1}>
            <Heading className="bwl-contact-mobile__title" variant="h2">
              {title}
            </Heading>
            <Text className="bwl-contact-mobile__text">{text}</Text>
          </GridColumn>
          <GridColumn className="bwl-contact-mobile__employee-column" span={4} spanMobile={11} offset={8} offsetMobile={1}>
            <Card>
              {job && (
                <Text variant="titleTag" className="bwl-contact-mobile__job">
                  {job}
                </Text>
              )}
              {name && (
                <Heading variant={"h4"} className="bwl-contact-mobile__name">
                  {name}
                </Heading>
              )}
              {phoneNumber && (
                <Text variant="paragraph" className="bwl-contact-mobile__phone-number">
                  <a href={`tel:+47${phoneNumber}`}>{formatPhoneNumber(phoneNumber)}</a>
                </Text>
              )}

              {email && (
                <Text variant="paragraph" className="bwl-contact-mobile__email">
                  <a href={`mailto:${email}`}>{wordBreakEmail(email)}</a>
                </Text>
              )}
            </Card>
          </GridColumn>{" "}
        </GridRow>
      </Grid>
    </div>
  );
};
const ContactCard = props => {
  return (
    <>
      {renderDesktop(props)}
      {renderMobile(props)}
    </>
  );
};

ContactCard.propTypes = {
  /** "Featured" title */
  title: PropTypes.string,
  /** "Featured" text */
  text: PropTypes.string,
  /** Employees job title */
  job: PropTypes.string,
  /** Employees full name */
  name: PropTypes.string.isRequired,
  /** Employees phone number (without country code) */
  phoneNumber: PropTypes.string,
  /** Employees email address */
  email: PropTypes.string.isRequired,
  /** Image of employee */
  image: PropTypes.shape({
    fallback: PropTypes.string.isRequired,
    webp: PropTypes.string.isRequired
  })
};

export default ContactCard;
