import _get from "lodash/get";
import _uniqueId from "lodash/uniqueId";
import Logo from "../assets/bob_logo_desktop.svg";
import LogoFooter from "../assets/bob_logo_footer_desktop.svg";
import LogoFooterMobile from "../assets/bob_logo_footer_mobile.svg";
import { LOGO_FOOTER, LOGO_FOOTER_MOBILE } from "../constants/footerConstants";
import { UMBRACO_BASE_ADDRESS } from "../constants/umbracoConstants";
import { setFetchUninitiated } from "../loadingStates";

export const getContent = (content, id) => {
  return content && content.contents && content.contents[id] ? content.contents[id] : { ...setFetchUninitiated() };
};

export const getContentPageProps = content => {
  return {
    content,
    ...Object.keys(content.props).reduce((props, prop) => {
      return {
        ...props,
        [prop]: getPropValue(content, prop),
      };
    }, {}),
  };
};

export const get = (content, prop, defaultValue) => {
  return defaultValue ? _get(content, prop, defaultValue) : _get(content, prop);
};

export const getPropValue = (content, name, value) => {
  return value ? _get(content, `props.${name}.value.${value}`) : _get(content, `props.${name}.value`);
};

export const tryGetLinkPropValue = (content, name, contentTypeAlias) => {
  const result = name && _get(content, `props.${name}`) && getPropValue(content, name) ? getPropValue(content, name) : null;
  if (!result) {
    console.warn(contentTypeAlias, `content link prop named "${name}" - or its value - is undefined`);
  }
  return result;
};

export const getLinkUrl = url => {
  return url;
};

export const getVideoUrl = url => {
  const isExternal = url[0] !== "/";

  return isExternal ? url : UMBRACO_BASE_ADDRESS + url;
};

export const getImageUrl = image => {
  return image ? UMBRACO_BASE_ADDRESS + image.url : "";
};

export const getImageCropUrl = (image, size, fileTypeRaw) => {
  if (!image) {
    return { webp: "", fallback: "" };
  }

  const fileType = fileTypeRaw ? fileTypeRaw : image.props.umbracoExtension.value;

  return {
    webp: `${UMBRACO_BASE_ADDRESS + image.url + getCropUrlSegment(image, size)}quality=90&format=webp`,
    fallback: `${UMBRACO_BASE_ADDRESS + image.url + getCropUrlSegment(image, size)}quality=90&format=${fileType}`,
    alt: getImageAlt(image),
  };
};

const getCropUrlSegment = (image, size) => {
  const [width, height] = size ?? [0, 0];
  const isCropped = width > 0 && height > 0;
  const focalPointTop = get(image, "props.umbracoFile.value.focalPoint.top");
  const focalPointLeft = get(image, "props.umbracoFile.value.focalPoint.left");

  const crop = isCropped ? `mode=crop&width=${width}&height=${height}&` : "";
  const focalPoint = isCropped && focalPointTop && focalPointLeft ? `center=${focalPointTop},${focalPointLeft}&` : "";

  return `?${crop}${focalPoint}`;
};

export const getImageAlt = image => {
  return _get(image, "props.alt.value");
};

export const getImageCaption = image => {
  return _get(image, "props.caption.value");
};

export const contentTypeAliasAsCssClass = contentTypeAlias => {
  if (!contentTypeAlias) {
    // prevent crash if a reuasable refers to a deleted component
    return "";
  }
  return contentTypeAlias
    .split(/(?=[A-Z])/)
    .join("-")
    .toLowerCase();
};

export const getUniqueIdForContentTypeAlias = contentTypeAlias => {
  return _uniqueId(`${contentTypeAliasAsCssClass(contentTypeAlias)}-`);
};

export const getPropVariant = value => {
  return value ? value.split(/\s/).join("-").toLowerCase() : undefined;
};

export const getFirstPropValueOf = (propKeys, result, defaultValue = "") => {
  for (let prop of propKeys) {
    const propValue = _get(result, `props.${prop}.value`, undefined);
    if (propValue) {
      return propValue;
    }
  }
  return defaultValue;
};

export const getLogo = logoType => {
  switch (logoType) {
    case LOGO_FOOTER:
      return LogoFooter;
    case LOGO_FOOTER_MOBILE:
      return LogoFooterMobile;
    default:
      return Logo;
  }
};

export const getPublishedDate = content => {
  if (!content?.createDate) {
    return "";
  }
  return formatDate(content.createDate);
};

export const getUpdateDate = content => {
  if (!content?.updateDate) {
    return "";
  }
  return formatDate(content.updateDate);
};

export const getOverrideDate = content => {
  let dateString = content?.props?.dateOverride?.value ?? null;
  if (new Date(dateString).getFullYear() <= 1970) {
    //a null input to date gives unix time 0, but undefined is invalid. Default umbraco year is 0001.
    dateString = content?.createDate;
  }
  if (!dateString) {
    return "";
  }
  return formatDate(dateString);
};

//helper
const formatDate = date => {
  return new Date(date).toLocaleDateString("no", { year: "numeric", month: "long", day: "numeric" });
};
