import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Arrow, Card, Heading, LinkWrapper } from "../";
import { withFocusState } from "../WithFocusState";
import "./linkCollectionCard.scss";

/** Simple collection of links rendered on a card. Needs to be checked for external/internal link issues. */
const LinkCollectionCard = ({ title, items, className }) => {
  const classNames = classnames("bwl-simple-link-collection", className);
  return (
    <Card className={classNames} background="white">
      <Heading variant="h4" className="bwl-simple-link-collection__title">
        {title}
      </Heading>
      <ul className="bwl-simple-link-collection__list">
        {items.map((item, index) => (
          <SimpleLinkWithFocusState item={item} key={`bwl-simple-link-collection__item-${index}`} />
        ))}
      </ul>
    </Card>
  );
};

const SimpleLink = ({ item, isFocused, ...rest }) => {
  return (
    <li {...rest}>
      <LinkWrapper link={item.link}>
        <div className="bwl-simple-link-collection__item">
          <div className="bwl-simple-link-collection__item__text">{item.text}</div>
          <Arrow isFixedWidth variant="medium" isFocused={isFocused} />
        </div>
      </LinkWrapper>
    </li>
  );
};

const SimpleLinkWithFocusState = withFocusState(SimpleLink);

LinkCollectionCard.propTypes = {
  /**the title */
  title: PropTypes.string.isRequired,
  /** the link collection containing shapes of { target, type, url } */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.shape({
        target: PropTypes.string,
        type: PropTypes.number,
        url: PropTypes.string.isRequired
      }).isRequired,
      text: PropTypes.string.isRequired
    }).isRequired
  )
};

export default LinkCollectionCard;
