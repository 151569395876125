import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Fade from "../Fade";

export const styles = {
  /* Styles applied to the root element. */
  root: {
    zIndex: -1,
    position: "fixed",
    right: 0,
    bottom: 0,
    top: 0,
    left: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    // Remove grey highlight
    WebkitTapHighlightColor: "transparent",
    // Disable scroll capabilities.
    touchAction: "none"
  },
  /* Styles applied to the root element if `invisible={true}`. */
  invisible: {
    backgroundColor: "transparent"
  }
};

class Backdrop extends React.Component {
  constructor(props) {
    super();
    this.style = styles;
  }

  render() {
    const { className, invisible, open, transitionDuration, ...other } = this.props;
    const classes = { styles, ...this.props.classes };
    return (
      <Fade in={open} timeout={transitionDuration} {...other}>
        <div
          data-mui-test="Backdrop"
          className={classNames(
            classes.root,
            {
              [classes.invisible]: invisible
            },
            className
          )}
          aria-hidden="true"
        />
      </Fade>
    );
  }
}

Backdrop.propTypes = {
  /**
   * @ignore
   */
  className: PropTypes.string,
  /**
   * If `true`, the backdrop is invisible.
   * It can be used when rendering a popover or a custom select component.
   */
  invisible: PropTypes.bool,
  /**
   * If `true`, the backdrop is open.
   */
  open: PropTypes.bool.isRequired,
  /**
   * The duration for the transition, in milliseconds.
   * You may specify a single timeout for all transitions, or individually with an object.
   */
  transitionDuration: PropTypes.oneOfType([PropTypes.number, PropTypes.shape({ enter: PropTypes.number, exit: PropTypes.number })])
};

Backdrop.defaultProps = {
  invisible: false
};

export default Backdrop;
