import umbracoService from "../services/umbracoService";

export const SEARCH_FETCH_STARTED = "SEARCH_FETCH_STARTED";
export const SEARCH_FETCH_SUCCESS = "SEARCH_FETCH_SUCCESS";
export const SEARCH_FETCH_FAILED = "SEARCH_FETCH_FAILED";
export const SEARCH_RESET = "SEARCH_RESET";

export function searchFetchStarted(pagedQuery, referer) {
  return {
    type: SEARCH_FETCH_STARTED,
    referer: referer,
    pagedQuery
  };
}

export function searchFetchSuccess(pagedQuery, result, referer) {
  return {
    type: SEARCH_FETCH_SUCCESS,
    referer: referer,
    pagedQuery,
    result
  };
}

export function searchFetchFailed(pagedQuery, error, referer) {
  return {
    type: SEARCH_FETCH_FAILED,
    referer: referer,
    pagedQuery,
    error
  };
}

export function searchReset(referer) {
  return {
    type: SEARCH_RESET,
    referer: referer
  };
}

/*
 * pagedQuery = {
 *   query: 'value',
 *   page: 2
 *   size: 10,
 * }
 */
export const searchPublishedContent = (pagedQuery, referer) => {
  return async dispatch => {
    dispatch(searchFetchStarted(pagedQuery, referer));
    try {
      const result = await umbracoService.search(pagedQuery);
      dispatch(searchFetchSuccess(pagedQuery, result, referer));
    } catch (e) {
      dispatch(searchFetchFailed(pagedQuery, e, referer));
    }
  };
};

export const searchExact = (pagedQuery, referer) => {
  return async dispatch => {
    dispatch(searchFetchStarted(pagedQuery, referer));
    try {
      const results = await umbracoService.searchExact(pagedQuery);
      dispatch(searchFetchSuccess(pagedQuery, results, referer));
    } catch (e) {
      dispatch(searchFetchFailed(pagedQuery, e, referer));
    }
  };
};

export const reset = referer => {
  return dispatch => {
    dispatch(searchReset(referer));
  };
};
