import { VIMEO_FETCH_FAILED, VIMEO_FETCH_STARTED, VIMEO_FETCH_SUCCESS } from "../actions/vimeo";
import { setFetchFailed, setFetchIsFetching, setFetchSuccess, setFetchUninitiated } from "../loadingStates";

const initialState = {
  videos: {},
  ...setFetchUninitiated()
};

const vimeo = (state = initialState, action) => {
  switch (action.type) {
    case VIMEO_FETCH_STARTED:
      return {
        ...state,
        ...setFetchIsFetching(),
        videos: {
          ...state.videos,
          [action.id]: { ...setFetchIsFetching(), video: { id: action.id } }
        }
      };
    case VIMEO_FETCH_SUCCESS:
      return {
        ...state,
        ...setFetchSuccess(),
        videos: {
          ...state.videos,
          [action.id]: { ...setFetchSuccess(), video: action.video }
        }
      };
    case VIMEO_FETCH_FAILED:
      return {
        ...state,
        ...setFetchFailed(action.error),
        videos: {
          ...state.videos,
          [action.id]: { ...setFetchFailed(), video: { id: action.id } }
        }
      };
    default:
      return state;
  }
};

export default vimeo;
