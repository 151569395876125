import umbracoService from "../services/umbracoService";

export const CONTENT_FETCH_STARTED = "CONTENT_FETCH_STARTED";
export const CONTENT_FETCH_SUCCESS = "CONTENT_FETCH_SUCCESS";
export const CONTENT_FETCH_FAILED = "CONTENT_FETCH_FAILED";

export function contentFetchStarted(id) {
  return {
    type: CONTENT_FETCH_STARTED,
    id
  };
}

export function contentFetchSuccess(id, content) {
  return {
    type: CONTENT_FETCH_SUCCESS,
    id,
    content
  };
}

export function contentFetchFailed(id, error) {
  return {
    type: CONTENT_FETCH_FAILED,
    id,
    error: error
  };
}

export const getPublishedContentById = id => {
  return async dispatch => {
    dispatch(contentFetchStarted(id));
    try {
      const content = await umbracoService.getPublishedContentById(id);
      dispatch(contentFetchSuccess(id, content));
    } catch (e) {
      dispatch(contentFetchFailed(id, e));
    }
  };
};

export const getPublishedContentByUrl = url => {
  return async dispatch => {
    // TODO: Should resolve id from sitemap state
    dispatch(contentFetchStarted(url));
    try {
      const content = await umbracoService.getPublishedContentByUrl(url);
      dispatch(contentFetchSuccess(url, content));
    } catch (e) {
      // TODO: Should resolve id from sitemap state
      dispatch(contentFetchFailed(url, e));
    }
  };
};
