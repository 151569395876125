import FullScreen from "mobile-safari-fullscreen";
import styles from "mobile-safari-fullscreen/dist/index.module.css";
import React from "react";
import { connect } from "react-redux";
import closeImg from "../../assets/close.svg";
import openImg from "../../assets/expand.svg";
import Map from "../../bob-standalone-map/Main";
import { Grid, GridColumn, GridRow, Heading, LinkButton } from "../../bob-web-library/components";
import Modal from "../../bob-web-library/components/MaterialUi/Modal";
import { isBrowserMobileSfari } from "../../logic/compatibilityLogic";
import { offsetTop } from "../../logic/domElementLogic";
import SvgButton from "../SvgButton";
import "./map.scss";

class MapContainer extends React.Component {
  constructor() {
    super();
    this.state = { fullScreen: false };
    this.containerRef = React.createRef();
  }

  render() {
    const { settings, title, link, endpoint } = this.props;

    if (!settings || (!settings.mapEndpoint && !endpoint) || !settings.googleApiKey) {
      return null;
    }

    const apiKey = window.location.host.includes("localhost") ? "AIzaSyDgPtDJN6R3uhDFq_RjGKxKRVJf_9ikWiI" : settings.googleApiKey;
    const args = {
      maxWidth: "",
      maxHeight: "",
      width: "",
      api: {
        clientsEndpoint: endpoint || settings.mapEndpoint,
      },
      googleApiProps: {
        apiKey: apiKey,
        version: "3.42",
      },
    };

    const enterFullscreen = () => {
      this.setState({ fullScreen: true });
    };

    const exitFullscreen = () => {
      this.setState({ fullScreen: false });
      if (this.containerRef.current) {
        if (isBrowserMobileSfari()) {
          setTimeout(() => {
            const top = offsetTop(this.containerRef.current);
            window.scrollTo({ top: top - 32, left: 0, behavior: "auto" });
          }, 0);
        }
      }
    };

    return (
      <>
        <FullScreen classNames={styles} isOpen={this.state.fullScreen}>
          <Modal open={this.state.fullScreen} onEscapeKeyDown={exitFullscreen}>
            <div className="fullscreen-wrapper">
              <SvgButton
                round
                className="bob-map-container__button bob-map-container__button--full-screen"
                svg={closeImg}
                title="Lukk fullskjerm"
                click={exitFullscreen}
              />
              <Map {...args} />
            </div>
          </Modal>
        </FullScreen>

        <Grid>
          <GridRow>
            <GridColumn>
              <div className="bob-map-heading">
                {title && <Heading variant="h2">{title}</Heading>}
                {link && <LinkButton text={link.name} variant="text" link={link} />}
              </div>
              <div className="bob-map-container" ref={this.containerRef}>
                <SvgButton round className="bob-map-container__button" svg={openImg} title="Vis fullskjerm" click={enterFullscreen} />
                <Map {...args} />
              </div>
            </GridColumn>
          </GridRow>
        </Grid>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    settings: state.settings,
  };
};

export default connect(mapStateToProps)(MapContainer);
