import React from "react";
import AnchorContainer from "../containers/AnchorContainer";
import { contentTypeAliasAsCssClass, getPropValue } from "../logic/contentLogic";
import { renderContent } from "./contentFactory";

export const renderAnchorWrapperContent = (content, props = {}) => {
  const nestedContentArray = getPropValue(content, "anchorContent");
  const ref = getPropValue(content, "anchorReference");

  return (
    <AnchorContainer className={contentTypeAliasAsCssClass(content.contentTypeAlias)} refProp={ref}>
      {nestedContentArray && nestedContentArray.length > 0 && nestedContentArray.map(nestedContent => renderContent(nestedContent))}
    </AnchorContainer>
  );
};
