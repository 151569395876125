import "whatwg-fetch";
import { encode } from "./queryStringHelper";

const MIME_TYPES = {
  JSON: "application/json"
};

export class ApiClient {
  async request(url, options) {
    const response = await fetch(url, options);

    if (!response.ok) {
      throw new Error(response.status, response.statusText);
    }

    const contentType = response.headers.get("Content-Type");

    if (contentType && contentType.includes(MIME_TYPES.JSON)) {
      return await response.json();
    }

    return response;
  }

  async get(url, queryParams, options) {
    if (queryParams) {
      url = `${url}?${encode(queryParams)}`;
    }
    const defaults = { mode: "cors", method: "GET", headers: { Accept: MIME_TYPES.JSON } };

    return await this.request(url, { ...defaults, ...options });
  }
}
