import classnames from "classnames";
import React from "react";
import { contentTypeAliasAsCssClass, get, getUniqueIdForContentTypeAlias } from "../logic/contentLogic";
import { renderContent } from "./contentFactory";

export const renderReusableNestedContent = (content, props = {}) => {
  const reusableContent = get(content, "props.reusableContent.value", {});

  return (
    <div
      key={getUniqueIdForContentTypeAlias(reusableContent.contentTypeAlias)}
      className={classnames("reusable-content", contentTypeAliasAsCssClass(reusableContent.contentTypeAlias))}
    >
      {renderContent(reusableContent)}
    </div>
  );
};
