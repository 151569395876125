import umbracoService from "../services/umbracoService";

export const SITEMAP_FETCH_STARTED = "SITEMAP_FETCH_STARTED";
export const SITEMAP_FETCH_SUCCESS = "SITEMAP_FETCH_SUCCESS";
export const SITEMAP_FETCH_FAILED = "SITEMAP_FETCH_FAILED";

export function sitemapFetchStarted(id) {
  return {
    type: SITEMAP_FETCH_STARTED,
    id
  };
}

export function sitemapFetchSuccess(id, content) {
  return {
    type: SITEMAP_FETCH_SUCCESS,
    id,
    sitemap: content.sitemap
  };
}

export function sitemapFetchFailed(id, error) {
  return {
    type: SITEMAP_FETCH_FAILED,
    id,
    error
  };
}

export const getSitemapById = id => {
  return async dispatch => {
    dispatch(sitemapFetchStarted(id));
    try {
      const sitemap = await umbracoService.getSitemapById(id);
      dispatch(sitemapFetchSuccess(id, sitemap));
    } catch (e) {
      console.warn(e);
      dispatch(sitemapFetchFailed(id, e));
    }
  };
};
