import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import "./gridColumn.scss";

const GridColumn = props => {
  const {
    children,
    className,
    hideOnMobile,
    span,
    spanMobile,
    offset,
    offsetMobile,
    isFirst,
    isFirstOnMobile
  } = props;

  return (
    <div
      className={classnames(
        className,
        "bwl-grid-column",
        `bwl-grid-column--span-${span}`,
        `bwl-grid-column--span-mobile-${spanMobile}`,
        {
          [`bwl-grid-column--offset-${offset}`]: offset,
          [`bwl-grid-column--offset-mobile-${offsetMobile}`]: offsetMobile,
          "bwl-grid-column--hide-on-mobile": hideOnMobile,
          "bwl-grid-column--first": isFirst,
          "bwl-grid-column--first-on-mobile": isFirstOnMobile
        }
      )}
    >
      {children}
    </div>
  );
};

GridColumn.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  hideOnMobile: PropTypes.bool,
  span: PropTypes.number,
  spanMobile: PropTypes.number,
  offset: PropTypes.number,
  offsetMobile: PropTypes.number,
  isFirst: PropTypes.bool,
  isFirstOnMobile: PropTypes.bool
};

GridColumn.defaultProps = {
  span: 12,
  spanMobile: 12,
};

export default GridColumn;
