import React from "react";

class MemberIFrame extends React.PureComponent {
  setupBBL() {
    const iframe = document.getElementById("blimedlem-app");
    window.addEventListener(
      "message",
      function(message) {
        if (message.data.type === "BBLD_BliMedlem_LayoutMeasured") {
          iframe.style.height = message.data.scrollHeight + "px";
        } else if (message.data.type === "BBLD_BliMedlem_LayoutChanged") {
          iframe.style.height = "0px";
        } else if (message.data.type === "BBLD_BliMedlem_ChangePage") {
          window.dataLayer.push({
            event: "bblPageview",
            eventCategory: "Bli medlem",
            eventAction: document.location.pathname,
            eventLabel: message.data.title
          });
        }
      },
      false
    );
  }

  render() {
    const { title } = this.props;
    return (
      <div className={"iframe-container"}>
        <iframe
          title={title}
          id="blimedlem-app"
          src="https://blimedlem.bbl.no/BOB/"
          onLoad={() => this.setupBBL()}
          style={{ width: "100%", minHeight: "600px", border: "none" }}
        />
      </div>
    );
  }
}

export default MemberIFrame;
