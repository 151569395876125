import React from "react";
import { getContentPageProps } from "../logic/contentLogic";

const News = React.lazy(() => import("../components/News"));

export const renderNewsContent = (content, props = {}) => {
  const Helmet = props.helmet;

  return (
    <>
      <Helmet />
      <News key={content.id} {...getContentPageProps(content)} />
    </>
  );
};
