import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Icon from "../Icon";
import "./collection-item.scss";

const CollectionItem = props => {
  const { text, icon, noMargin, wrap } = props;
  const classes = classnames("bwl-collection-item", {
    "bwl-collection-item--wrap": wrap
  });

  return (
    <div className={classes}>
      {icon && <Icon noMargin={noMargin} icon={icon} />}
      {!!text && text}
    </div>
  );
};

CollectionItem.propTypes = {
  /** Nullable text */
  text: PropTypes.string,
  /** Nullable icon */
  icon: PropTypes.string,
  /** Bool prop to set a margin between the icon and text */
  noMargin: PropTypes.bool,
  /** Bool prop to allow wrap on text */
  wrap: PropTypes.bool
};

export default CollectionItem;
