import { SITEMAP_FETCH_FAILED, SITEMAP_FETCH_STARTED, SITEMAP_FETCH_SUCCESS } from "../actions/sitemap";
import { LINK_INTERNAL } from "../constants/umbracoConstants";
import { setFetchFailed, setFetchIsFetching, setFetchSuccess, setFetchUninitiated } from "../loadingStates";

const initialState = {
  url: {},
  key: {},
  menu: [],
  ...setFetchUninitiated()
};

const handleNavMenuGeneration = sitemap => {
  const filteredItems = sitemap.filter(item => !item.hideInMenu);
  const topLevelItems = filteredItems.filter(filteredItem => filteredItem.level === 2);
  const subLevelItems = filteredItems.filter(filteredItem => filteredItem.level > 2);
  return topLevelItems.map(navCategory => {
    return {
      title: navCategory.name,
      titleLink: { url: navCategory.url, ...LINK_INTERNAL },
      collectionItems: subLevelItems
        .filter(subLevelItem => subLevelItem.url.includes(navCategory.url))
        .map(filteredSubLevelItem => {
          return { link: { url: filteredSubLevelItem.url, ...LINK_INTERNAL }, text: filteredSubLevelItem.name };
        })
    };
  });
};

const sitemap = (state = initialState, action) => {
  switch (action.type) {
    case SITEMAP_FETCH_STARTED:
      return {
        ...state,
        ...setFetchIsFetching()
      };
    case SITEMAP_FETCH_SUCCESS:
      return {
        ...state,
        ...setFetchSuccess(),
        url: createMap(action.sitemap, "url"),
        key: createMap(action.sitemap, "key"),
        menu: handleNavMenuGeneration(action.sitemap)
      };
    case SITEMAP_FETCH_FAILED:
      return {
        ...state,
        ...setFetchFailed(action.error)
      };
    default:
      return state;
  }
};

const createMap = (sitemap, key) => {
  let map = {};
  for (const value of sitemap) {
    const mapKey = value[key];
    map[mapKey] = value;
  }
  return map;
};

export default sitemap;
