import { default as classnames, default as clx } from "classnames";
import PropTypes from "prop-types";
import React from "react";
import Arrow from "../Arrow";
import Icon from "../Icon";
import ProgressSpinner from "../ProgressSpinner";
import SimpleSpinner from "../SimpleSpinner";
import { withFocusState } from "../WithFocusState";
import "./button.scss";
import { LOADING_STATES } from "./utils/constants";

/**
 * Creates a button
 */
class Button extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      status: LOADING_STATES.NEUTRAL,
    };

    this.handleButtonClick = this.handleButtonClick.bind(this);
  }

  handleButtonClick = e => {
    if (!this.props.onClick) return;
    if (this.props.variant !== "positive" && this.props.variant !== "negative") {
      this.props.onClick(e);
    } else {
      this.startLoadingFromServer(this.props.onClick, e);
    }
  };

  handleMouseDown = e => {
    e.preventDefault();
  };

  startLoadingFromServer = async (callback, e) => {
    // Action already in progress, abort
    if (this.state.status != LOADING_STATES.NEUTRAL) {
      return;
    }
    var msEllapsed = 0;
    this.setState({
      status: LOADING_STATES.LOADING,
    });

    // Do server call
    await callback(e);

    setTimeout(() => {
      this.setState({
        status: LOADING_STATES.SUCCESS,
      });
    }, (msEllapsed += 2000));

    setTimeout(() => {
      this.setState({
        status: LOADING_STATES.TO_NEUTRAL,
      });
    }, (msEllapsed += 2000));

    setTimeout(() => {
      this.setState({
        status: LOADING_STATES.NEUTRAL,
      });
    }, (msEllapsed += 200));
  };

  getVariant = () => {
    const { success, error, variant } = this.props;
    let overriddenVariant = variant;
    if (success) overriddenVariant = "positive";
    else if (error) overriddenVariant = "negative";
    return overriddenVariant;
  };

  renderIcon = e => {
    const { disabled, isFocused, iconOrder, customIcon, loading } = this.props;
    const orientation = iconOrder === "before" ? "left" : "right";
    const variant = this.getVariant();
    let colorScheme;
    switch (variant) {
      case "text-light":
        colorScheme = "mist";
        break;
      case "positive":
      case "negative":
        colorScheme = "light";
        break;
      default:
        colorScheme = "dark";
        break;
    }

    if (loading)
      return (
        <div className="bwl-button__spinner">
          <SimpleSpinner />
        </div>
      );
    if (customIcon) return <Icon className={clx({ "bwl-button__custom-icon": this.props.text })} icon={customIcon} noMargin />;
    return (
      <Arrow
        colorScheme={colorScheme}
        className="bwl-button__arrow"
        isDisabled={disabled}
        isFixedWidth={!["text", "text-light"].includes(variant)}
        isFocused={isFocused}
        orientation={orientation}
        variant={["text", "text-light"].includes(variant) ? "medium" : "short"}
      />
    );
  };

  renderRegisterWithVippsIcon = () => <img alt="Registrer med Vipps" src="https://cms.bob.no/media/ipajbozi/log_in_with_vipps_pill_250_no.svg" />;

  renderRegisterWithVippsIcon210 = () => <img alt="Registrer med Vipps" src="https://cms.bob.no/media/f3phu4ct/log_in_with_vipps_pill_210_no.svg" />;

  renderPayWithVippsIcon = () => <img alt="Betal med Vipps" src="https://cms.bob.no/media/kagnzsxp/pay_with_vipps_pill_250_no.svg" />;

  renderPayWithVippsIcon210 = () => <img alt="Betal med Vipps" src="https://cms.bob.no/media/udobcytg/pay_with_vipps_pill_210_no.svg" />;

  renderTitle = () => {
    return (
      <div className="bwl-button__content">
        {this.props.text && <span className="bwl-button__title">{this.props.text}</span>}
        {this.renderIcon()}
      </div>
    );
  };

  renderProgress = e => {
    return (
      <div className="bwl-button__loading-status">
        <ProgressSpinner />
      </div>
    );
  };

  renderFinishedStatus = () => {
    return (
      <div className="bwl-button__loading-status">
        <div className="button_status_text">
          <Icon noMargin icon={"check"} />
        </div>
      </div>
    );
  };

  renderLoadingState = () => {
    if (this.props.variant !== "positive" && this.props.variant !== "negative") {
      return;
    }
    switch (this.state.status) {
      case LOADING_STATES.LOADING:
        return this.renderProgress();

      case LOADING_STATES.SUCCESS:
      case LOADING_STATES.ERROR:
        return this.renderFinishedStatus();

      case LOADING_STATES.NEUTRAL:
      default:
        return;
    }
  };

  getStatusMessage = () => {
    const { success, error } = this.props;
    if (success && success.message) return <div className="bwl-button__status-message bwl-button__status-message--success">{success.message}</div>;
    else if (error && error.message) return <div className="bwl-button__status-message bwl-button__status-message--error">{error.message}</div>;
    return null;
  };

  render() {
    const { className, iconOrder, withProgress, isFocused, customIcon, innerRef, success, error, disabled, loading, ...rest } = this.props;

    const iconOrderCls = this.props.text && `bwl-button--icon-${iconOrder}`;

    const variant = this.getVariant();

    const classes = classnames(className, "bwl-button", "button-progress", `bwl-button--${variant}`, iconOrderCls, {
      "bwl-button--focused": isFocused,
      "bwl-button--neutral": this.state.status === LOADING_STATES.NEUTRAL,
      "bwl-button--success": this.state.status === LOADING_STATES.SUCCESS,
      "bwl-button--loading": this.state.status === LOADING_STATES.LOADING,
      "bwl-button--error": this.state.status === LOADING_STATES.ERROR,
      "bwl-button--to-neutral": this.state.status === LOADING_STATES.TO_NEUTRAL,
      "bwl-button--icon-only": this.props.customIcon && !this.props.text,
    });

    return (
      <button
        ref={innerRef}
        className={classes}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onClick={this.handleButtonClick}
        onMouseDown={this.handleMouseDown}
        disabled={loading || disabled}
        {...rest}
      >
        {variant === "registerWithVipps" && this.renderRegisterWithVippsIcon()}
        {variant === "registerWithVipps210" && this.renderRegisterWithVippsIcon210()}
        {variant === "payWithVipps" && this.renderPayWithVippsIcon()}
        {variant === "payWithVipps210" && this.renderPayWithVippsIcon210()}
        {variant.toLowerCase().indexOf("vipps") === -1 && this.renderLoadingState()}
        {variant.toLowerCase().indexOf("vipps") === -1 && this.renderTitle()}
        {this.getStatusMessage()}
      </button>
    );
  }
}

Button.propTypes = {
  /** determines button variant */
  variant: PropTypes.oneOf([
    "primary",
    "secondary",
    "tertiary",
    "text",
    "text-light",
    "positive",
    "negative",
    "registerWithVipps",
    "registerWithVipps210",
    "payWithVipps",
    "payWithVipps210",
  ]),
  /** sets button state for displaying in storybook */
  className: PropTypes.string,
  /** Button action. */
  onClick: PropTypes.func.isRequired,
  /** Force focus state. */
  isFocused: PropTypes.bool,
  /** Disabled. */
  disabled: PropTypes.bool,
  /** Button label. */
  text: PropTypes.string.isRequired,
  /** progress */
  withProgress: PropTypes.bool,
  /** CustomIcon to inject instead of Arrow */
  customIcon: PropTypes.string,
  /** arrow is placed to the left for use in a "back" button */
  iconOrder: PropTypes.oneOf(["before", "after"]),
  /** show a spinner instead of arrow */
  loading: PropTypes.bool,
  /** display success color and optional message */
  success: PropTypes.oneOf([PropTypes.bool, PropTypes.shape({ message: PropTypes.string })]),
  /** display error color and optional message */
  error: PropTypes.oneOf([PropTypes.bool, PropTypes.shape({ message: PropTypes.string })]),
};

Button.defaultProps = {
  variant: "primary",
  iconOrder: "after",
};

const Comp = withFocusState(Button);

export default React.forwardRef((props, ref) => <Comp {...props} innerRef={ref} />);
