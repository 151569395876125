import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Text } from "../";
import "./quote.scss";

const Quote = ({ author, quote, className }) => {
  return (
    <div className={classnames(className, "bwl-quote")}>
      <Text variant="titleTag" className="bwl-quote__author">
        {author}
      </Text>
      <Text variant="lead" className="bwl-quote__text">
        «{quote}»
      </Text>
    </div>
  );
};

Quote.propTypes = {
  author: PropTypes.string.isRequired,
  quote: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default Quote;
