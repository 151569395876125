import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import Input from "../Input";
import "./search.scss";

const Search = props => {
  const { className, label, big = true, onChange, renderTransitionLine, keepLabelOnFocus, ...rest } = props;
  const classes = classnames("bwl-search", className, {
    "bwl-search--from-left": renderTransitionLine,
    "bwl-search--keep-label-on-focus": !!label && keepLabelOnFocus
  });

  return (
    <div className={classes}>
      <Input label={label} placeholder={keepLabelOnFocus ? label : ""} big={big} inputIcon="search" onChange={onChange} {...rest} />
    </div>
  );
};

Search.propTypes = {
  /** onChange function */
  onChange: PropTypes.func,
  /** Classname injector prop */
  className: PropTypes.string,
  /** label text which act as placeholder before typing. Do not use the placeholder attribute */
  label: PropTypes.string,
  /** determines whether to render a transition bottom-border on focus */
  renderTransitionLine: PropTypes.bool,
  /** determines whether fill size is that of search, defaults to true */
  big: PropTypes.bool,
  /** inject props like aria downwards */
  rest: PropTypes.object,
  /** determines whether to keep label on input focus before entering a value */
  keepLabelOnFocus: PropTypes.bool
};

export default Search;
