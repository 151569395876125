import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Button, Grid, GridColumn, GridRow, Icon, LinkCollection, LinkWrapper, Search } from "../";
import SwipeableDrawer from "../MaterialUi/SwipeableDrawer";
import NavBar from "../NavBar";
import "./drawer.scss";

const isDifferentRouteThanUrl = (link, currentLocation) => {
  return link !== currentLocation;
};

const makeItemsDisabled = (items, currentLocation) => {
  return Object.values(items).map(topLevel => {
    const isSameRoute = !isDifferentRouteThanUrl(topLevel.titleLink.url, currentLocation);
    return {
      ...topLevel,
      titleDisabled: topLevel.titleDisabled !== undefined ? topLevel.titleDisabled : isSameRoute,
      collectionItems: topLevel.collectionItems.map(linkCollectionItem => {
        const isSameRoute = !isDifferentRouteThanUrl(linkCollectionItem.link.url, currentLocation);
        return {
          ...linkCollectionItem,
          disabled: linkCollectionItem.disabled !== undefined ? linkCollectionItem.disabled : isSameRoute,
        };
      }),
    };
  });
};

class Drawer extends React.Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  componentDidUpdate(prevProps) {
    // Pre abstraction logic
    // const previousNavigationRoute = prevProps.location ? prevProps.location.pathname : null;
    // const navigatedRoute = this.props.location ? this.props.location.pathname : null;
    // const shouldMenuClose = isDifferentRouteThanUrl(previousNavigationRoute, navigatedRoute);
    // if (shouldMenuClose) this.props.handleMenuClick(NavBar.menuActions.CLOSE);
    if (prevProps.open && !this.props.open) this.props.handleMenuClick(NavBar.menuActions.CLOSE);
  }

  drawerButtons() {
    if (this.props.user) return null;
    return (
      <ul className="bwl-drawer-buttons">
        <li>
          <Button
            variant="primary"
            text="Min side"
            onClick={() => {
              this.props.onNavigate(NavBar.routes.LOG_IN);
            }}
          />
        </li>
        <li>
          <Button
            variant="secondary"
            text="Bli medlem"
            onClick={() => {
              this.props.onNavigate(NavBar.routes.REGISTER_MEMBER);
            }}
          />
        </li>
      </ul>
    );
  }

  renderDesktopMenu(items, open) {
    const { focusSearch, currentSearchQuery, searchCallbacks } = this.props;
    const { onKeyDown, handleFocusChange, onChange, onClickIcon } = searchCallbacks;
    const className = classnames(this.props.className);
    return (
      <SwipeableDrawer
        className={className}
        open={open}
        onClose={() => this.props.handleMenuClick(NavBar.menuActions.CLOSE)}
        onOpen={() => this.props.handleMenuClick(NavBar.menuActions.OPEN)}
        anchor="top"
        PaperProps={{ className: "paper bwl-drawer bwl-drawer-swipeable bwl-drawer-swipeable--desktop" }}
      >
        <div className="bwl-drawer-container">
          <div className="bwl-drawer-content">
            <Grid>
              <GridRow className="bwl-drawer__search-row">
                <GridColumn span={6} offset={4} className="bwl-drawer__search-column">
                  <Search
                    label="Søk..."
                    renderTransitionLine
                    onKeyDown={onKeyDown}
                    onFocus={handleFocusChange}
                    onBlur={handleFocusChange}
                    onChange={onChange}
                    keepLabelOnFocus
                    placeholder="Søk..."
                    autoFocus={focusSearch}
                    onClickIcon={onClickIcon}
                    value={currentSearchQuery}
                  />
                </GridColumn>
              </GridRow>
              <GridRow>
                {Object.keys(items).map((key, index) => (
                  <GridColumn noPadding key={`link-collection-${index}`} span={3} offset={index * 4 + 2}>
                    <LinkCollection
                      title={items[key].title}
                      titleLink={items[key].titleLink}
                      titleDisabled={items[key].titleDisabled}
                      items={items[key].collectionItems}
                    />
                  </GridColumn>
                ))}
                {!this.props.user && (
                  <>
                    <GridColumn noPadding span={3} offset={2}>
                      <Button
                        text="Logg inn"
                        onClick={() => {
                          this.props.onNavigate(NavBar.routes.LOG_IN);
                        }}
                      />
                    </GridColumn>
                    <GridColumn noPadding span={3} offset={2}>
                      <Button
                        variant="secondary"
                        text="Bli medlem"
                        onClick={() => {
                          this.props.onNavigate(NavBar.routes.REGISTER_MEMBER);
                        }}
                      />
                    </GridColumn>
                  </>
                )}
              </GridRow>
            </Grid>
          </div>
        </div>
      </SwipeableDrawer>
    );
  }

  renderMobileMenu(items, open) {
    const contact = { url: NavBar.routes.CONTACT, ...NavBar.LINK_INTERNAL };
    const className = classnames(this.props.className);
    return (
      <SwipeableDrawer
        className={className}
        open={open}
        onClose={() => this.props.handleMenuClick(NavBar.menuActions.CLOSE)}
        onOpen={() => this.props.handleMenuClick(NavBar.menuActions.OPEN)}
        anchor="left"
        PaperProps={{ className: "paper bwl-drawer bwl-drawer-swipeable bwl-drawer-swipeable--mobile" }}
      >
        <div className="bwl-drawer-container">
          <div className="bwl-drawer-content">
            <div className="bwl-mobile-top-level-menu">
              <ul className="bwl-mobile-menu-list-top">
                {Object.keys(items).map((key, index) => (
                  <MobileTopLevelLinkItem
                    key={`top-level-link-${index}`}
                    title={items[key].title}
                    linkCollection={items[key]}
                    onClick={this.props.handleOpenSubNav}
                  />
                ))}
                <MobileLinkListItem link={contact} text="Kontakt" />
              </ul>
              {this.drawerButtons()}
            </div>
          </div>
        </div>
      </SwipeableDrawer>
    );
  }

  renderSubMenu(items, selected) {
    const selectedItem = items[Object.keys(items).filter(key => items[key].title === selected)] || null;
    const className = classnames(this.props.className);
    return (
      <SwipeableDrawer
        className={className}
        open={!!selectedItem}
        onOpen={() => {}}
        onClose={() => this.props.handleMenuClick(NavBar.menuActions.BACK)}
        anchor={"right"}
        PaperProps={{ className: "paper bwl-drawer bwl-drawer-swipeable bwl-drawer-swipeable--mobile" }}
      >
        <div className="bwl-drawer-container">
          <div className="bwl-drawer-content">
            {selectedItem && (
              <React.Fragment>
                <MobileLinkCollection linkCollection={selectedItem} />
              </React.Fragment>
            )}
          </div>
        </div>
      </SwipeableDrawer>
    );
  }

  isMobile() {
    const { width } = this.state;
    return width <= 830;
  }

  render() {
    const { open, selectedLinkCollection, additionalItems = [], location = {} } = this.props;
    const currentLocation = location.pathname;
    const disabledAdditions = makeItemsDisabled(additionalItems, currentLocation);
    const desktopItems = makeItemsDisabled(this.props.items, currentLocation);
    const mobileItems = [...desktopItems, ...disabledAdditions];

    return (
      <React.Fragment>
        {this.isMobile() ? (
          <>
            {this.renderSubMenu(mobileItems, selectedLinkCollection)}
            {this.renderMobileMenu(mobileItems, open)}
          </>
        ) : (
          this.renderDesktopMenu(desktopItems, open)
        )}
      </React.Fragment>
    );
  }
}

const MobileLinkCollection = props => {
  const { linkCollection } = props;
  return (
    <ul className="bwl-mobile-menu-list">
      <MobileLinkListItem link={linkCollection.titleLink} text={linkCollection.title} disabled={linkCollection.titleDisabled} bold />
      {linkCollection.collectionItems.map((item, index) => (
        <MobileLinkListItem key={`mobile-link-item-${index}`} link={item.link} text={item.text} disabled={item.disabled} />
      ))}
    </ul>
  );
};

const MobileLinkListItem = props => {
  const { link, text, bold, disabled } = props;
  const className = classnames("bwl-mobile-link", {
    "bwl-mobile-link--bold": bold,
  });
  return (
    <li>
      <LinkWrapper link={link} disabled={disabled}>
        <div className={className}>
          <div>{text}</div>
          <Icon icon={"arrowNav"} />
        </div>
      </LinkWrapper>
    </li>
  );
};

const MobileTopLevelLinkItem = props => {
  const { title, linkCollection, onClick } = props;
  return (
    <li onClick={() => onClick(linkCollection)}>
      <div className="bwl-mobile-link bwl-mobile-link--bold">
        {title}
        <Icon icon={"arrowNav"} />
      </div>
    </li>
  );
};

Drawer.propTypes = {
  open: PropTypes.bool.isRequired,
  handleMenuClick: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  additionalItems: PropTypes.array,
  location: PropTypes.object.isRequired,
  selectedLinkCollection: PropTypes.string,
};

export default Drawer;
