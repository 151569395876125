import { CONTENT_FETCH_FAILED, CONTENT_FETCH_STARTED, CONTENT_FETCH_SUCCESS } from "../actions/content";
import { setFetchFailed, setFetchIsFetching, setFetchSuccess, setFetchUninitiated } from "../loadingStates";

const initialState = {
  rootId: null,
  contents: {},
  ...setFetchUninitiated()
};

const content = (state = initialState, action) => {
  switch (action.type) {
    case CONTENT_FETCH_STARTED:
      return {
        ...state,
        ...setFetchIsFetching(),
        contents: {
          ...state.contents,
          [action.id]: { ...setFetchIsFetching(), content: { id: action.id } }
        }
      };
    case CONTENT_FETCH_SUCCESS:
      return {
        ...state,
        ...setFetchSuccess(),
        contents: {
          ...state.contents,
          [action.id]: { ...setFetchSuccess(), content: action.content }
        }
      };
    case CONTENT_FETCH_FAILED:
      return {
        ...state,
        ...setFetchFailed(action.error),
        contents: {
          ...state.contents,
          [action.id]: { ...setFetchFailed(), content: { id: action.id } }
        }
      };
    default:
      return state;
  }
};

export default content;
