import React from "react";
import "./ProgressSpinner.scss";

const ProgressSpinner = props => {
  // const { } = props;

  return <div className="bwl-progress-spinner" />;
};

export default ProgressSpinner;
