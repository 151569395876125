import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import Arrow from "../Arrow";
import Card from "../Card";
import Grid, { GridColumn, GridRow } from "../Grid";
import Image from "../Image";
import LinkWrapper from "../LinkWrapper";
import { Heading, Text } from "../Typography";
import { withFocusState } from "../WithFocusState";
import "./featuredContentCard.scss";
import { variantProps } from "./utils/constants";

/** A variation of ContentCard with an Image.
 *  Either horizontal or vertical overlap of ContentCard and Image.
 */
const FeaturedContentCard = props => {
  const { title, tag, text, link, variant, image, smallerHeading, isFocused, ...rest } = props;

  const {
    useBackgroundImage,
    contentSpan,
    contentSpanMobile,
    contentOffset,
    contentOffsetMobile,
    imageSpan,
    imageSpanMobile,
    imageOffset,
    imageOffsetMobile,
    imageIsFirst,
    imageIsFirstOnMobile,
  } = variantProps[variant];

  const renderContent = () => (
    <GridColumn
      className="bwl-featured-content-card__content-card-column"
      span={contentSpan}
      spanMobile={contentSpanMobile}
      offset={contentOffset}
      offsetMobile={contentOffsetMobile}
    >
      <LinkWrapper link={link}>
        <div className="bwl-hover-listener" {...rest}>
          <Card animate>
            {tag && (
              <Text variant="titleTag" className="bwl-content-card__tag">
                {tag}
              </Text>
            )}
            {title && (
              <Heading variant={smallerHeading ? "h4" : "h3"} className="bwl-featured-content-card__title">
                {title}
              </Heading>
            )}
            {text && (
              <Text variant="paragraph" className="bwl-featured-content-card__text">
                {text}
              </Text>
            )}
            <div className="bwl-featured-content-card__link-container">
              <Arrow isFocused={isFocused} variant="long" />
            </div>
          </Card>
        </div>
      </LinkWrapper>
    </GridColumn>
  );

  const renderImage = () =>
    !useBackgroundImage &&
    image.fallback && (
      <GridColumn
        className="bwl-featured-content-card__image-column"
        span={imageSpan}
        spanMobile={imageSpanMobile}
        offset={imageOffset}
        offsetMobile={imageOffsetMobile}
        isFirst={imageIsFirst}
        isFirstOnMobile={imageIsFirstOnMobile}
      >
        <div className="bwl-featured-content-card__image-container">
          <Image image={image} className="bwl-featured-content-card__image" />
        </div>
      </GridColumn>
    );

  return (
    <div
      className={classnames("bwl-featured-content-card", {
        "bwl-featured-content-card--horizontal-overlap": variant === "horizontalOverlap",
        "bwl-featured-content-card--vertical-overlap": variant === "verticalOverlap",
      })}
    >
      <Grid>
        <GridRow backgroundSpan={7} backgroundImage={useBackgroundImage && image.fallback ? image : null}>
          {renderContent()}
          {renderImage()}
        </GridRow>
      </Grid>
    </div>
  );
};

FeaturedContentCard.propTypes = {
  /** Title for component */
  title: PropTypes.string,
  /** Tag for component */
  tag: PropTypes.string,
  /** Text for component */
  text: PropTypes.string.isRequired,
  /** Image for component */
  image: PropTypes.shape({
    fallback: PropTypes.string.isRequired,
    webp: PropTypes.string.isRequired,
  }),
  /** Link for component */
  link: PropTypes.shape({
    target: PropTypes.string,
    type: PropTypes.number,
    url: PropTypes.string.isRequired,
  }).isRequired,
  /** Link text for component */
  linkText: PropTypes.string,
  /** Renders an h4 instead of h3 */
  smallerHeading: PropTypes.bool,
  /** Variant of component */
  variant: PropTypes.oneOf(["horizontalOverlap", "verticalOverlap"]),
};

FeaturedContentCard.defaultProps = {
  variant: "horizontalOverlap",
};

export default withFocusState(FeaturedContentCard);
