import React from "react";
import { CROP_SIZE } from "../constants/imageSizeConstants";
import { contentTypeAliasAsCssClass, getImageCropUrl, getPropValue, getUniqueIdForContentTypeAlias } from "../logic/contentLogic";

const InfoCard = React.lazy(() => import("../bob-web-library/components/InfoCard"));

export const renderInfoCardNestedContent = (content, props = {}) => {
  const image = getPropValue(content, "image");
  const noCompression = getPropValue(content, "fullResImage") ?? false;
  const crop = noCompression ? CROP_SIZE.UNCOMPRESSED : CROP_SIZE.XLARGE;

  return (
    <InfoCard
      key={getUniqueIdForContentTypeAlias(content.contentTypeAlias)}
      className={contentTypeAliasAsCssClass(content.contentTypeAlias)}
      image={getImageCropUrl(image, crop)}
      tag={getPropValue(content, "tag")}
      title={getPropValue(content, "title")}
      text={getPropValue(content, "text")}
    />
  );
};
