import classnames from "classnames";
import React from "react";
import { CMS_MEDIA_ADDRESS } from "../constants/umbracoConstants";
import { contentTypeAliasAsCssClass, get, getUniqueIdForContentTypeAlias } from "../logic/contentLogic";
import "../styles/contentType/richTextEditor.scss";

export const renderRichText = content => {
  var html = get(content, "props.richTextEditor.value");
  const htmlWithMedia = html.replace('src="/media/', `src="${CMS_MEDIA_ADDRESS}`).replace('href="/media/', `href="${CMS_MEDIA_ADDRESS}`);
  return (
    <div
      key={getUniqueIdForContentTypeAlias(content.contentTypeAlias)}
      className={classnames("rich-text-editor", contentTypeAliasAsCssClass(content.contentTypeAlias))}
      dangerouslySetInnerHTML={{
        __html: htmlWithMedia
      }}
    />
  );
};
