import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import "./contentWrapper.scss";

const ContentWrapper = ({ children, className, noMaxWidth, highlighted, highlighted_covered }) => {
  return (
    <div
      className={classnames(className, "bwl-content-wrapper", {
        "bwl-content-wrapper--no-max-width": noMaxWidth,
        "bwl-content-wrapper--highlighted": highlighted,
        "bwl-content-wrapper--highlighted-covered": highlighted_covered
      })}
    >
      <div className="bwl-content-wrapper__content">{children}</div>
    </div>
  );
};

ContentWrapper.propTypes = {
  /** Children for the component */
  children: PropTypes.node.isRequired,
  /** Class name for root node */
  className: PropTypes.string,
  /** Disable max width constraint */
  noMaxWidth: PropTypes.bool,
  /** Draws a colored box behind content */
  highlighted: PropTypes.bool,
  highlighted_covered: PropTypes.bool,
};

export default ContentWrapper;
