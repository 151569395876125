import _get from "lodash/get";
import { IMPORTANT_MESSAGE_FETCH_FAILED, IMPORTANT_MESSAGE_FETCH_STARTED, IMPORTANT_MESSAGE_FETCH_SUCCESS } from "../actions/importantMessage";
import { setFetchFailed, setFetchIsFetching, setFetchSuccess, setFetchUninitiated } from "../loadingStates";
const initialState = {
  message: "",
  link: null,
  showOnUrls: [],
  ...setFetchUninitiated(),
};

const importantMessage = (state = initialState, action) => {
  switch (action.type) {
    case IMPORTANT_MESSAGE_FETCH_STARTED:
      return {
        ...state,
        ...setFetchIsFetching(),
      };
    case IMPORTANT_MESSAGE_FETCH_SUCCESS:
      return {
        ...state,
        ...setFetchSuccess(),
        message: _get(action, "importantMessage.props.message.value", ""),
        link: _get(action, "importantMessage.props.link.value"),
        showOnUrls: _get(action, "importantMessage.props.showOnPages.value", []).map(x => x.url),
      };
    case IMPORTANT_MESSAGE_FETCH_FAILED:
      return {
        ...state,
        ...setFetchFailed(action.error),
      };
    default:
      return state;
  }
};

export default importantMessage;
