import React from "react";
import {Redirect, Route, Router, Switch} from 'react-router-dom';
import App from "./App";
import dynamicContentRoute from "./higherOrderedComponents/dynamicContentRoute";
import dynamicPreviewContentRoute from "./higherOrderedComponents/dynamicPreviewContentRoute";
import lazyRoute from "./higherOrderedComponents/lazyRoute";
import history from "./history";
import routes from "./constants/routeConstants";

// Pages
const UserProfilePage = React.lazy(() => import("./pages/UserProfilePage"));
const ContentPage = React.lazy(() => import("./pages/ContentPage"));
const LoginPage = React.lazy(() => import("./pages/LoginPage"));
const PageNotFound = React.lazy(() => import("./pages/PageNotFound"));
const SearchPage = React.lazy(() => import("./pages/SearchPage"));
const PreviewPage = React.lazy(() => import("./pages/PreviewPage"));

export const Routes = () => {
  return (
    <Router history={history}>
      <App>
        <Switch>{renderRoutes(allRoutes)}</Switch>
      </App>
    </Router>
  );
};

const renderRoutes = routes => {
  return routes.map((route, index) => <Route key={index} {...route} />);
};

const allRoutes = [
  {
    path: routes.USER,
    component: lazyRoute(UserProfilePage),
    exact: true
  },
  {
    path: routes.LOGIN,
    component: lazyRoute(LoginPage),
    exact: true
  },
  {
    path: routes.SEARCH,
    component: lazyRoute(SearchPage),
    exact: true
  },
  {
    path: routes.PREVIEW,
    component: dynamicPreviewContentRoute(PreviewPage, PageNotFound),
    exact: true
  },
  {
    path: '/authcb',
    component: () =>  null,
    exact: true
  },
  {
    path: "*",
    component: dynamicContentRoute(ContentPage, PageNotFound)
  }
];
