import React from "react";
import PropTypes from "prop-types";

const UserProfile = props => {
  const { userProperties } = props;
  return (
    <React.Fragment>
      <div>
        <span>Last Name: </span>
        {userProperties.lastName}
      </div>
      <div>
        <span>Email: </span>
        {userProperties.email}
      </div>
    </React.Fragment>
  );
};

UserProfile.propTypes = {
  userProperties: PropTypes.shape({
    lastName: PropTypes.string,
    email: PropTypes.string
  }).isRequired
};

export default UserProfile;
