import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import "./card.scss";

const Card = React.forwardRef((props, ref) => {
  const { children, className, animate, backgroundColor, isTextCentered, style, onClick } = props;

  return (
    <div
      ref={ref}
      className={classnames(className, "bwl-card", `bwl-card--${backgroundColor}`, {
        "bwl-card--animate": animate,
        "bwl-card--centered-text": isTextCentered
      })}
      style={style}
      onClick={onClick}
    >
      {children}
    </div>
  );
});

Card.defaultProps = {
  backgroundColor: "white"
};

Card.propTypes = {
  /** Children for the component */
  children: PropTypes.node.isRequired,
  /** Class name for root node */
  className: PropTypes.string,
  /** Activation of animation for card */
  animate: PropTypes.bool,
  /** Background color of choice */
  backgroundColor: PropTypes.oneOf(["white", "purple", "transparent"]),
  /** Centered text */
  isTextCentered: PropTypes.bool
};

export default Card;
