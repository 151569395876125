import React from "react";
import { getContentPageProps } from "../logic/contentLogic";

const Topic = React.lazy(() => import("../components/Topic"));

export const renderTopicContent = (content, props = {}) => {
  const Helmet = props.helmet;
  return (
    <>
      <Helmet />
      <Topic key={content.id} {...getContentPageProps(content)} />
    </>
  );
};
