import classnames from "classnames";
import throttle from "lodash/throttle";
import PropTypes from "prop-types";
import React from "react";
import placeholderSrc from "../assets/image-load.svg";
import "./image.scss";
import { canUseWebP, isInViewport, isSvgSupported, registerListener } from "./utils";

class Image extends React.Component {
  constructor(props) {
    super(props);
    this.throttledFunction = throttle(this.lazyLoad, 50);
    const { webp, fallback, alt } = this.props.image;
    this.state = {
      loaded: false,
      retryDelay: this.props.retry.delay,
      retryCount: 1,
      srcWebp: webp,
      srcFallback: fallback,
      placeholder: placeholderSrc,
      alt
    };
    this.placeholderImageRef = React.createRef();
  }

  loadImage() {
    const image = document.createElement("img");

    image.onload = () => {
      this.setState({ loaded: true });
    };

    image.onerror = () => {
      this.handleImageRetries(image);
    };

    image.src = this.state.srcWebp && canUseWebP() ? this.state.srcWebp : this.state.srcFallback;
  }

  lazyLoad = () => {
    if (isInViewport(this.placeholderImageRef)) {
      this.clearEventListeners();
      this.loadImage();
    }
  };

  componentDidMount() {
    if (!this.props.noLazyLoad && isSvgSupported) {
      if (isInViewport(this.placeholderImageRef)) {
        this.loadImage();
      } else {
        registerListener("load", this.throttledFunction);
        registerListener("scroll", this.throttledFunction);
        registerListener("resize", this.throttledFunction);
        registerListener("gestureend", this.throttledFunction);
      }
    } else {
      this.loadImage();
    }
  }

  clearEventListeners() {
    window.removeEventListener("load", this.throttledFunction);
    window.removeEventListener("scroll", this.throttledFunction);
    window.removeEventListener("resize", this.throttledFunction);
    window.removeEventListener("gestureend", this.throttledFunction);
  }

  componentWillUnmount() {
    if (this.timeout) {
      window.clearTimeout(this.timeout);
    }

    this.clearEventListeners();
  }

  handleImageRetries(image) {
    this.setState({ loaded: false }, () => {
      if (this.state.retryCount <= this.props.retry.count) {
        this.timeout = setTimeout(() => {
          image.src = this.state.srcFallback;

          this.setState(prevState => {
            let updateDelay;
            if (this.props.retry.accumulate === "multiply") {
              updateDelay = prevState.retryDelay * this.props.retry.delay;
            } else if (this.props.retry.accumulate === "add") {
              updateDelay = prevState.retryDelay + this.props.retry.delay;
            } else if (this.props.retry.accumulate === "noop") {
              updateDelay = this.props.retry.delay;
            } else {
              updateDelay = "multiply";
            }

            return {
              retryDelay: updateDelay,
              retryCount: prevState.retryCount + 1
            };
          });
        }, this.state.retryDelay * 1000);
      }
    });
  }

  render() {
    if (!this.state.loaded && this.props.noPlaceholder) return null;

    const src = this.state.loaded ? this.state.srcFallback : this.state.placeholder;
    const srcWebp = this.state.loaded ? this.state.srcWebp : this.state.placeholder;

    const style = {};

    return (
      <picture>
        <source type="image/webp" srcSet={srcWebp} />
        <source type="image/jpeg" srcSet={src} />
        <img
          src={src}
          className={classnames("bwl-image", this.props.className, {
            "bwl-image--loading": !this.state.loaded
          })}
          style={{
            ...style,
            ...this.props.style
          }}
          alt={this.state.alt}
          ref={this.state.loaded ? null : this.placeholderImageRef}
        />
      </picture>
    );
  }
}

Image.defaultProps = {
  image: {
    fallback: null,
    webp: null,
    alt: ""
  },
  className: null,
  style: {},
  retry: {
    count: 8,
    delay: 2,
    accumulate: "multiply"
  },
  noRetry: false,
  noPlaceholder: false,
  noLazyLoad: false
};

Image.propTypes = {
  image: PropTypes.shape({
    webp: PropTypes.string.isRequired,
    fallback: PropTypes.string.isRequired,
    alt: PropTypes.string
  }),
  className: PropTypes.string,
  style: PropTypes.object,
  retry: PropTypes.shape({
    count: PropTypes.number,
    delay: PropTypes.number,
    accumulate: PropTypes.string
  }),
  noRetry: PropTypes.bool,
  noPlaceholder: PropTypes.bool,
  noLazyLoad: PropTypes.bool
};

export default Image;
