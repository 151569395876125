import React from "react";
import PropTypes from "prop-types";

const SuspenseContent = ({ children, fallback }) => {
  if (!children) {
    return null;
  }

  return <React.Suspense fallback={fallback || <div>Is loading...</div>}>{children}</React.Suspense>;
};

SuspenseContent.propTypes = {
  /** Children for the component */
  children: PropTypes.node.isRequired,
  /** Custom fallback option */
  fallback: PropTypes.node
};

export default SuspenseContent;
