import React, { ComponentPropsWithoutRef, forwardRef, ReactNode, useContext, useMemo } from "react";
import { ThemeContext, ThemeProvider } from "styled-components";
import { createTheme, THEME_KEY } from "./theme/helpers";
import { IBOBCCThemeProvider } from "./theme/types";
const BOBCCThemeProvider = ({ children, theme }: IBOBCCThemeProvider) => {
  const memoizedTheme = useMemo(() => createTheme(theme), [theme]);
  return <ThemeProvider theme={memoizedTheme}>{children}</ThemeProvider>;
};

export const withDefaultTheme = (Component: React.ComponentType<any>) =>
  forwardRef<HTMLElement | ReactNode, ComponentPropsWithoutRef<any>>((props: {}, ref) => {
    const theme = useContext(ThemeContext);
    if (!theme || !theme[THEME_KEY]) {
      console.warn("Theme does not exist or not using bob-core-components ThemeProvider. Injecting ThemeProvider");
      return (
        <BOBCCThemeProvider>
          <Component ref={ref} {...props} />
        </BOBCCThemeProvider>
      );
    }
    return <Component ref={ref} {...props} />;
  });

export default BOBCCThemeProvider;
