import PropTypes from "prop-types";
import React from "react";
import { Text } from "../";
import Grid, { GridColumn, GridRow } from "../Grid";
import Image from "../Image";
import "./caption-image.scss";

const CaptionImage = ({ className = '', caption, image }) => {
  const span = 8;
  const offest = 3;
  return (
    <Grid>
      <GridRow>
        <GridColumn span={span} offset={offest}>
          <figure className={`${className} bwl-caption-image`}>
            <Image image={image} className="bwl-caption-image__image" />
            {caption && (
              <figcaption>
                <Text variant="description" className="bwl-caption-image__caption">
                  {caption}
                </Text>
              </figcaption>
            )}
          </figure>
        </GridColumn>
      </GridRow>
    </Grid>
  );
};

CaptionImage.propTypes = {
  /** The CaptionImage url */
  image: PropTypes.shape({
    fallback: PropTypes.string.isRequired,
    webp: PropTypes.string.isRequired
  }).isRequired,
  /** The caption for the image image */
  caption: PropTypes.string
};

export default CaptionImage;
