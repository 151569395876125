import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';
import './link-wrapper.scss';

const parseLink = (link, isExternal) => {
  const relProps = link.target === '_blank' ? {rel: 'noreferrer noopener'} : null;
  return {[isExternal ? 'href' : 'to']: link.url, target: link.target, ...relProps};
};

const LinkWrapper = props => {
  const {link, disabled} = props;
  const classes = classnames('bwl-link-wrapper', {
    'bwl-link-wrapper--disabled': disabled
  });
  /** Check the type of linksupplied from Umbraco.
   * {internal: 0, media: 1, external: 2}
   * Defaults to external - 2 - if type breaks or is omitted, as we cannot safely render a <Link> unless we know url is
   * internal. */
  const {type = 2} = link;
  const isExternal = type !== 0;

  const Wrapper = disabled ? 'div' : isExternal ? 'a' : Link;
  const WrapperProps = disabled ? '' : parseLink(link, isExternal);

  return (
    <Wrapper className={classes} {...WrapperProps}>
      {props.children}
    </Wrapper>
  );
};

LinkWrapper.propTypes = {
  /** The children to wrap */
  children: PropTypes.node,
  /** Boolean prop, disables the link */
  disabled: PropTypes.bool,
  /** The link to be rendered */
  link: PropTypes.shape({
    target: PropTypes.string,
    type: PropTypes.number,
    url: PropTypes.string.isRequired
  }).isRequired
};

export default LinkWrapper;
