import React from "react";
import { SuspenseContent } from "../bob-web-library/components";
import { getPropValue, getUniqueIdForContentTypeAlias } from "../logic/contentLogic";

const Vimeo = React.lazy(() => import("../components/Vimeo"));

export const renderVideoNestedContent = (content, props) => {
  return (
    <SuspenseContent>
      <Vimeo key={getUniqueIdForContentTypeAlias(content.contentTypeAlias)} isWide={getPropValue(content, "isWide")} id={getPropValue(content, "vimeoId")} />
    </SuspenseContent>
  );
};
