//https://webman.pro/blog/how-to-detect-and-test-click-outside-in-react/
import React, { useEffect, useRef, cloneElement, useState } from 'react';
export default ({ children, onClick }) => {
  const ref = useRef();
  useEffect(() => {
    if (!ref?.current) {
      return;
    }
    const handleClickOutside = (e) => {
      if (onClick && !ref.current.contains(e.target)) {
        onClick(e);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [onClick]);
  return <>{cloneElement(children, { ref })}</>;
};