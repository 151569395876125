import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Heading, Image, Text } from "../../../";
import Grid, { GridColumn, GridRow } from "../../../Grid";
import LinkWrapper from "../../../LinkWrapper";
import VimeoPlayerModal from "./../../../VimeoPlayerModal";
import "./homeHeader.scss";

class HomeHeader extends React.PureComponent {
  constructor(props) {
    super(props);
    this.videoRef = React.createRef();
    this.state = { isPaused: false, isMobile: window.innerWidth <= 830 };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }
  handleWindowSizeChange = () => {
    this.setState({ isMobile: window.innerWidth <= 830 });
  };

  renderCardLinks = () => {
    const { cardLinks } = this.props;

    return (
      cardLinks &&
      cardLinks.length > 0 && (
        <div className="bwl-header__logos-with-links">
          <Grid>
            <GridRow>
              {cardLinks.map((cardLink, index) => (
                <GridColumn span={12 / cardLinks.length} key={index}>
                  {cardLink}
                </GridColumn>
              ))}
            </GridRow>
          </Grid>
        </div>
      )
    );
  };

  playPauseVideo() {
    if (this.state.isPaused) {
      this.setState({ isPaused: false }, () => this.videoRef.current.play());
    } else {
      this.setState({ isPaused: true }, () => this.videoRef.current.pause());
    }
  }

  getVideoSource(mobile) {
    const { video } = this.props;
    if (!video) {
      return undefined;
    }
    if (mobile) {
      return video.mobileSrc ? video.mobileSrc : video.desktopSrc; // fallback to desktop if mobile not defined
    }
    return video.desktopSrc;
  }

  renderImageVideo(mobile) {
    const { image, video } = this.props;
    const src = this.getVideoSource(mobile);
    return video && src ? (
      video.link ? (
        <LinkWrapper link={video.link}>
          <video autoPlay muted loop ref={this.videoRef} key={src} poster={video.posterSrc}>
            <source src={src} type="video/mp4" />
          </video>
        </LinkWrapper>
      ) : video.videoId ? (
        <button className="bwl-button-wrapper" onClick={() => this.playPauseVideo()}>
          <video autoPlay muted loop ref={this.videoRef} key={src} poster={video.posterSrc}>
            <source src={src} type="video/mp4" />
          </video>
        </button>
      ) : (
        <video autoPlay muted loop ref={this.videoRef} key={src} poster={video.posterSrc}>
          <source src={src} type="video/mp4" />
        </video>
      )
    ) : (
      <Image image={image} />
    );
  }

  render() {
    const { className, title, text, bottomContent, video } = this.props;
    const headerClasses = classnames(className, "bwl-header", "bwl-base-header", "bwl-home-header");
    return (
      <header className={headerClasses}>
        <div className="bwl-header__top-container">
          {video && video.videoId && this.state.isPaused && <VimeoPlayerModal videoId={video.videoId} closeVideo={() => this.playPauseVideo()} />}
          <Grid>
            <GridRow>
              <GridColumn className="bwl-header__content-column" span={this.state.isMobile ? 12 : 6}>
                <div>
                  {title && (
                    <div className="bwl-header__title-container">
                      <Heading className="bwl-header__title" variant="frontPage">
                        {title}
                      </Heading>
                    </div>
                  )}

                  {text && (
                    <div className="bwl-header__text-container">
                      <Text variant="lead" className="bwl-header__text">
                        {text}
                      </Text>
                    </div>
                  )}

                  {bottomContent && <div className="bwl-header__bottom-content">{bottomContent}</div>}
                </div>
              </GridColumn>
              <GridColumn className="bwl-header__image-column" span={this.state.isMobile ? 12 : 6}>
                <div className={"bwl-header__image-container"}>{this.renderImageVideo(this.state.isMobile)}</div>
              </GridColumn>
            </GridRow>
          </Grid>
        </div>
        {this.renderCardLinks()}
      </header>
    );
  }
}

HomeHeader.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  bottomText: PropTypes.string,
  imageCaption: PropTypes.string,
  image: PropTypes.shape({
    fallback: PropTypes.string,
    webp: PropTypes.string.isRequired,
  }),
  video: PropTypes.shape({
    desktopSrc: PropTypes.string,
    mobileSrc: PropTypes.string,
    posterSrc: PropTypes.string,
    link: PropTypes.shape({
      target: PropTypes.string,
      type: PropTypes.number,
      url: PropTypes.string.isRequired,
    }),
    videoId: PropTypes.string,
  }),
  bottomContent: PropTypes.array,
  cardLinks: PropTypes.array,
  className: PropTypes.string,
};

export default HomeHeader;
