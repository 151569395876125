import _get from "lodash/get";
import { SETTINGS_FETCH_FAILED, SETTINGS_FETCH_STARTED, SETTINGS_FETCH_SUCCESS } from "../actions/settings";
import { setFetchFailed, setFetchIsFetching, setFetchSuccess, setFetchUninitiated } from "../loadingStates";
const initialState = {
  footerLinks: [],
  cookiePolicyPage: null,
  mapEndpoint: null,
  googleApiKey: null,
  ...setFetchUninitiated(),
};

const settings = (state = initialState, action) => {
  switch (action.type) {
    case SETTINGS_FETCH_STARTED:
      return {
        ...state,
        ...setFetchIsFetching(),
      };
    case SETTINGS_FETCH_SUCCESS:
      return {
        ...state,
        ...setFetchSuccess(),
        footerLinks: _get(action, "settings.props.footerLinkCollection.value", []),
        cookiePolicyPage: _get(action, "settings.props.settingsCookiePolicy.value", null),
        mapEndpoint: _get(action, "settings.props.mapEndpoint.value", null),
        googleApiKey: _get(action, "settings.props.googleApiKey.value", null),
      };
    case SETTINGS_FETCH_FAILED:
      return {
        ...state,
        ...setFetchFailed(action.error),
      };
    default:
      return state;
  }
};

export default settings;
