const LAND_COLOR = "#e0eef3";
const WATER_COLOR = "#d0e4e6";
const ROAD_COLOR = "#ffffff";
const ROAD_STROKE_COLOR = "#f0f7f9";
const HIGHWAY_COLOR = "#fdf4b6";
const HIGHWAY_STROKE_COLOR = "#f3da7e";

export const styles = [
  { elementType: "geometry", stylers: [{ color: LAND_COLOR }] },
  // { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
  // { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
  // {
  //   featureType: "administrative.locality",
  //   elementType: "labels.text.fill",
  //   stylers: [{ color: "#d59563" }]
  // },
  // {
  //   featureType: "poi",
  //   elementType: "labels.text.fill",
  //   stylers: [{ color: "#d59563" }]
  // },
  // {
  //   featureType: "poi.park",
  //   elementType: "geometry",
  //   stylers: [{ color: "#263c3f" }]
  // },
  // {
  //   featureType: "poi.park",
  //   elementType: "labels.text.fill",
  //   stylers: [{ color: "#6b9a76" }]
  // },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [{ color: ROAD_COLOR }],
  },
  {
    featureType: "road",
    elementType: "labels",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "road",
    elementType: "geometry.stroke",
    stylers: [{ color: ROAD_STROKE_COLOR }],
  },
  // {
  //   featureType: "road",
  //   elementType: "labels.text.fill",
  //   stylers: [{ color: "#9ca5b3" }]
  // },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [{ color: HIGHWAY_COLOR }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [{ color: HIGHWAY_STROKE_COLOR }],
  },
  // {
  //   featureType: "road.highway",
  //   elementType: "labels.text.fill",
  //   stylers: [{ color: "#f3d19c" }]
  // },
  // {
  //   featureType: "transit",
  //   elementType: "geometry",
  //   stylers: [{ color: "#2f3948" }]
  // },
  // {
  //   featureType: "transit.station",
  //   elementType: "labels.text.fill",
  //   stylers: [{ color: "#d59563" }]
  // },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [{ color: WATER_COLOR }],
  },
  // {
  //   featureType: "water",
  //   elementType: "labels.text.fill",
  //   stylers: [{ color: "#515c6d" }]
  // },
  // {
  //   featureType: "water",
  //   elementType: "labels.text.stroke",
  //   stylers: [{ color: "#17263c" }]
  // }
];
