import PropTypes from "prop-types";
import React from "react";

const ThickArrowLeft = ({ className, fill = "000", width = 12, height = 21 }) => {
    return (
        <svg
            className={className}
            width={width}
            height={height}
            viewBox="0 0 13 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path fillRule="evenodd" clipRule="evenodd" d="M12.2385 20.2312C11.2231 21.2563 9.57689 21.2563 8.56152 20.2312L0.761523 12.3562C-0.25384 11.331 -0.253839 9.66897 0.761523 8.64384L8.56152 0.768844C9.57689 -0.256283 11.2231 -0.256283 12.2385 0.768845C13.2538 1.79397 13.2538 3.45603 12.2385 4.48116L6.27696 10.5L12.2385 16.5188C13.2538 17.544 13.2538 19.206 12.2385 20.2312Z" fill={`${fill}`} />
        </svg>
    );
}

// ************************************
// Validation
// ***********************************

ThickArrowLeft.propTypes = {
    className: PropTypes.string,
    fill: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number
}

ThickArrowLeft.defaultProps = {
    fill: "000",
    width: 12,
    height: 21
}

export default ThickArrowLeft;