export const CROP_SIZE = {
  TINY: [150, 150],
  SMALL: [350, 250],
  MEDIUM: [500, 325],
  LARGE: [500, 450],
  XLARGE: [1500, 1125],
  WIDE: [850, 425],
  PORTRAIT: [290, 330],
  HOME: [920, 690],
  THUMBNAIL: [1200, 630],
  UNCOMPRESSED: [0, 0]
};
