import PropTypes from "prop-types";
import Icon from "./Icon";
import { Button, Container, GreenDot, IconContainer, Label, LowerRightIconContainer, Number } from "./shortcutButton/shortcutButton.styles";
import { IProps } from "./shortcutButton/shortcutButton.types";

const ShortcutButton = ({ iconName, label, lowerRightIcon, number, showGreenDot, disabled, iconProps, onClick, ...rest }: IProps) => {
  const buttonId = `shortcut_button${btoa(label)}`;
  const icon = <Icon name={iconName} size="x-large-1" {...iconProps} />;
  return (
    <Container>
      <Button disabled={disabled} id={buttonId} onClick={onClick} {...rest}>
        {(number || number === 0) && <Number>{number > 999 ? "> 999" : number}</Number>}
        {showGreenDot && <GreenDot />}
        {icon && <IconContainer>{icon}</IconContainer>}
        {lowerRightIcon && <LowerRightIconContainer>{lowerRightIcon}</LowerRightIconContainer>}
        <Label htmlFor={buttonId}>{label}</Label>
      </Button>
    </Container>
  );
};

ShortcutButton.propTypes = {
  disabled: PropTypes.bool,
  iconName: PropTypes.string,
  label: PropTypes.string.isRequired,
  lowerRightIcon: PropTypes.node,
  number: PropTypes.number,
  showGreenDot: PropTypes.bool,
  iconProps: PropTypes.shape({
    name: PropTypes.string,
    outlined: PropTypes.bool,
    white: PropTypes.bool,
    violet: PropTypes.bool,
  }),
};

ShortcutButton.defaultProps = {
  disabled: false,
  iconName: undefined,
  lowerRightIcon: undefined,
  number: undefined,
  showGreenDot: false,
  iconProps: {},
};

export default ShortcutButton;
