import { UMBRACO_API_BASE_ADDRESS } from "../constants/umbracoConstants";
import { ApiClient } from "../utils/apiClient";

const client = new ApiClient();

const endpoints = {
  publishedcontent: "publishedcontent",
  internalContent: "internalcontent",
  internalSearch: "internalsearch",
  sitemap: "sitemap",
  search: "search",
  form: "forms",
  newsletter: "newsletter",
};

const defaultDepth = 5;

export default {
  getPublishedContent: () => {
    return client.get(UMBRACO_API_BASE_ADDRESS + endpoints.publishedcontent);
  },
  getPublishedContentById: (id, depth = defaultDepth) => {
    return client.get(`${UMBRACO_API_BASE_ADDRESS}${endpoints.publishedcontent}/${id}`, {
      depth: depth,
    });
  },
  getPublishedContentByUrl: (url, depth = defaultDepth) => {
    return client.get(`${UMBRACO_API_BASE_ADDRESS}${endpoints.publishedcontent}/url`, {
      url,
      depth: depth,
    });
  },
  getPublishedPreviewContentById: (id, depth = defaultDepth) => {
    return client.get(`${UMBRACO_API_BASE_ADDRESS}${endpoints.publishedcontent}/${id}/preview`, {
      depth: depth,
    });
  },
  getPublishedContentAncestorsById: id => {
    return client.get(`${UMBRACO_API_BASE_ADDRESS}${endpoints.publishedcontent}/${id}/ancestors`);
  },
  getPublishedContentChildrenById: id => {
    return client.get(`${UMBRACO_API_BASE_ADDRESS}${endpoints.publishedcontent}/${id}/children`);
  },
  getPublishedContentDescendantsById: id => {
    return client.get(`${UMBRACO_API_BASE_ADDRESS}${endpoints.publishedcontent}/${id}/descendants`);
  },
  getSitemapById: id => {
    return client.get(`${UMBRACO_API_BASE_ADDRESS}${endpoints.sitemap}/${id}`);
  },
  search: pagedQuery => {
    return client.get(`${UMBRACO_API_BASE_ADDRESS}${endpoints.publishedcontent}/${endpoints.search}`, pagedQuery);
  },
  searchExact: pagedQuery => {
    return client.get(`${UMBRACO_API_BASE_ADDRESS}${endpoints.internalContent}/${endpoints.internalSearch}/exact`, pagedQuery);
  },
  getForm: guid => {
    return client.get(`${UMBRACO_API_BASE_ADDRESS}${endpoints.form}/${guid}`);
  },
  postForm: (guid, form) => {
    // @todo: Create and use ApiClient::post()
    return fetch(`${UMBRACO_API_BASE_ADDRESS}${endpoints.form}/${guid}/answers`, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(form),
    })
      .then(response => {
        if (!response.ok) throw response;
        return response.json();
      })
      .then(json => {
        return json;
      })
      .catch(error => {
        throw error;
      });
  },
  signupForNewsletter: (formId, email) => {
    return fetch(`${UMBRACO_API_BASE_ADDRESS}${endpoints.newsletter}`, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ formId: formId, email: email }),
    })
      .then(response => {
        if (!response.ok) throw response;
        return response.json();
      })
      .then(json => {
        return json;
      })
      .catch(error => {
        throw error;
      });
  },
};

export const getRedirectUrlByUrl = url => {
  return client.get(`${UMBRACO_API_BASE_ADDRESS}seo?url=${url}`);
};
