import React from "react";
import { CROP_SIZE } from "../constants/imageSizeConstants";
import { contentTypeAliasAsCssClass, getImageCropUrl, getPropValue, getUniqueIdForContentTypeAlias, tryGetLinkPropValue } from "../logic/contentLogic";

const LinkCard = React.lazy(() => import("../bob-web-library/components/LinkCard"));

export const renderLinkCardNestedContent = (content, props = {}) => {
  const link = tryGetLinkPropValue(content, "link", content.contentTypeAlias);
  const imageRaw = getPropValue(content, "image", "");
  const noCompression = getPropValue(content, "fullResImage") ?? false;
  const crop = noCompression ? CROP_SIZE.UNCOMPRESSED : CROP_SIZE.SMALL;
  const image = imageRaw ? { image: getImageCropUrl(imageRaw, crop) } : {};

  return (
    link && (
      <LinkCard
        key={getUniqueIdForContentTypeAlias(content.contentTypeAlias)}
        className={contentTypeAliasAsCssClass(content.contentTypeAlias)}
        link={link}
        title={link.name}
        {...image}
        highlighted={getPropValue(content, "highlighted")}
        tag={getPropValue(content, "tag")}
      />
    )
  );
};
