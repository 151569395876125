import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { LinkWrapper, Text } from '../';
import ErrorBoundary from '../ErrorBoundary';
import './breadcrumb.scss';

const Breadcrumb = ({crumbs = [], allPathsIncluded = true, color, className, topic}) => {
  if (crumbs.length <= 0) {
    return null;
  }
  const links = crumbs.map(crumb => {
    if (!crumb.link) {
      return {...crumb, link: {url: crumb.url}};
    }
    return crumb;
  });

  const breadcrumbStyle = classnames(className,
    'bwl-breadcrumb',
    {'bwl-breadcrumb--topic': topic},
    {[`bwl-breadcrumb--${color}`]: color}
  );
  return (
    <ErrorBoundary>
      <div className={breadcrumbStyle}>
        {links.map((e, index) => (
          <React.Fragment key={`bwl-breadcrumb-${index}`}>
            {links.length === index + 1 && allPathsIncluded 
              && <Text variant="description">{e.name}</Text>}
            {links.length === index + 1 && !allPathsIncluded 
              &&
              <LinkWrapper link={e.link}>
                <Text variant="description">{e.name}</Text>
              </LinkWrapper>
            }
            {links.length > index + 1 
              && 
              <>
                <LinkWrapper link={e.link}>
                  <Text variant="description">{e.name}</Text>
                </LinkWrapper>
                <div className="bwl-breadcrumb__separator">/</div>
              </>
            }
          </React.Fragment>
        ))}
      </div>
    </ErrorBoundary>

  );
};

Breadcrumb.propTypes = {
  crumbs: PropTypes.object.isRequired,
};

export default Breadcrumb;
