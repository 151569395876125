import umbracoService from "../services/umbracoService";

export const SETTINGS_FETCH_STARTED = "SETTINGS_FETCH_STARTED";
export const SETTINGS_FETCH_SUCCESS = "SETTINGS_FETCH_SUCCESS";
export const SETTINGS_FETCH_FAILED = "SETTINGS_FETCH_FAILED";

export function settingsFetchStarted(id) {
  return {
    type: SETTINGS_FETCH_STARTED,
    id
  };
}

export function settingsFetchSuccess(id, settings) {
  return {
    type: SETTINGS_FETCH_SUCCESS,
    id,
    settings
  };
}

export function settingsFetchFailed(id, error) {
  return {
    type: SETTINGS_FETCH_FAILED,
    id,
    error: error
  };
}

export const getSettingsById = id => {
  return async dispatch => {
    dispatch(settingsFetchStarted(id));
    try {
      const settings = await umbracoService.getPublishedContentById(id);
      dispatch(settingsFetchSuccess(id, settings));
    } catch (e) {
      dispatch(settingsFetchFailed(id, e));
    }
  };
};
