import React from "react";
import Input from "../Input";
import Button from "../Button";
import MessageBox from "../MessageBox";
import PropTypes from "prop-types";

class Login extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { onClick, handleUsernameChange, handlePasswordChange, state } = this.props;
    return (
      <React.Fragment>
        <Input placeholder={"Username"} onChange={handleUsernameChange} />
        <Input placeholder={"Password"} onChange={handlePasswordChange} type={"password"} />
        <Button text={"OK"} onClick={onClick} />
        <MessageBox state={state} />
      </React.Fragment>
    );
  }
}

Login.propTypes = {
  onClick: PropTypes.func.isRequired,
  handleUsernameChange: PropTypes.func.isRequired,
  handlePasswordChange: PropTypes.func.isRequired,
  state: PropTypes.shape({ status: PropTypes.string, message: PropTypes.string })
};

export default Login;
