import { PureComponent } from "react";
import { connect } from "react-redux";
import { getPublishedContentByUrl } from "../actions/content";
import { searchExact } from "../actions/searchAction";
import { isFetchFailed, isFetching, isFetchUninitiated } from "../loadingStates";
import { getContent } from "../logic/contentLogic";
import { navigateTo } from "../logic/navigationLogic";
import { getRedirectUrlByUrl } from "../services/umbracoService";

const loadContent = WrappedComponent => {
  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    class loadContent extends PureComponent {
      shouldFetchContent = props => {
        const content = getContent(props.content, props.match.url);
        return isFetchUninitiated(props.content) || !content || isFetchUninitiated(content);
      };

      shouldFetchLastNews = props => {
        return isFetchUninitiated(props.news);
      };

      componentDidMount() {
        this.fetchContent();
      }

      componentDidUpdate() {
        this.fetchContent();
      }

      fetchContent() {
        const { match } = this.props;
        if (this.shouldFetchContent(this.props)) {
          this.props.getPublishedContentByUrl(match.url);
        }
        if (this.shouldFetchLastNews(this.props)) {
          this.props.searchExact({
            query: "__NodeTypeAlias:news OR __NodeTypeAlias:magazine",
            size: 1000,
          });
        }

        const { content } = this.props;
        // TODO: The scope of loadContent should be to loadContent, so we must consider other options
        if (isFetchFailed(content)) {
          getRedirectUrlByUrl(match.url).then(seo => {
            if (seo.httpStatusCode === 301) {
              navigateTo(seo.url);
            }
          });
        }
      }

      render() {
        const { match, content } = this.props;

        const contentEntity = getContent(content, match.url);

        if (!contentEntity || isFetchUninitiated(contentEntity) || isFetching(contentEntity)) {
          return null;
        }

        return <WrappedComponent {...this.props} contentEntity={contentEntity} />;
      }
    }
  );
};

const mapDispatchToProps = dispatch => {
  return {
    getPublishedContentByUrl: id => dispatch(getPublishedContentByUrl(id)),
    searchExact: pagedQuery => dispatch(searchExact(pagedQuery, "news")),
  };
};

const mapStateToProps = state => {
  return {
    content: state.content,
    news: state.search.news,
  };
};

export default loadContent;
