import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { iconMap } from "../Icon";
import "./Arrow.scss";

const Arrow = props => {
  const { className, colorScheme, isDisabled, isFixedWidth, isFocused, orientation, variant } = props;

  let icon;
  switch (colorScheme) {
    case "mist":
      icon = "arrowHeadBoldMist";
      break;
    case "light":
      icon = "arrowHeadBoldWhite";
      break;
    default:
      icon = "arrowHeadBold";
      break;
  }
  const iconSvg = Object.keys(iconMap).includes(icon) ? iconMap[icon] : null;
  return (
    <div
      className={classnames(className, "bwl-arrow-container", `bwl-arrow-container--${variant}`, {
        "bwl-arrow-container--fixed-width": isFixedWidth
      })}
    >
      <div
        className={classnames("bwl-arrow", `bwl-arrow--${variant}`, `bwl-arrow--${colorScheme}`, `bwl-arrow--${orientation}`, {
          "bwl-arrow--disabled": isDisabled,
          "bwl-arrow--fixed-width": isFixedWidth,
          "bwl-arrow--focused": isFocused
        })}
      >
        <span className="bwl-arrow__line" />
        <img src={iconSvg} />
      </div>
    </div>
  );
};

Arrow.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  /**color of the arrow*/
  colorScheme: PropTypes.oneOf(["light", "mist", "dark"]),
  isDisabled: PropTypes.bool,
  /**arrow container does not grow on hover if true*/
  isFixedWidth: PropTypes.bool,
  /**renders hover-effect if true*/
  isFocused: PropTypes.bool,
  /**Determines which way the arrow is pointing*/
  orientation: PropTypes.oneOf(["left", "right"]),
  /**Length of the arrow*/
  variant: PropTypes.oneOf(["short", "medium", "long"])
};

Arrow.defaultProps = {
  colorScheme: "dark",
  orientation: "right",
  variant: "medium"
};

export default Arrow;
