import { ContactCard } from "../bob-web-library/components";
import { CROP_SIZE } from "../constants/imageSizeConstants";
import { getImageCropUrl, getPropValue, getUniqueIdForContentTypeAlias } from "../logic/contentLogic";

export const renderContactRow = (content, props = {}) => {
  const contactCard = getPropValue(content, "contactCard", "");
  const image = getPropValue(contactCard, "image", "");

  return (
    <ContactCard
      key={getUniqueIdForContentTypeAlias(content.contentTypeAlias)}
      title={getPropValue(content, "title")}
      text={getPropValue(content, "text")}
      job={getPropValue(contactCard, "jobTitle")}
      name={getPropValue(contactCard, "fullName")}
      phoneNumber={getPropValue(contactCard, "phoneNumber")}
      email={getPropValue(contactCard, "email")}
      image={getImageCropUrl(image, CROP_SIZE.PORTRAIT, "jpg")}
    />
  );
};
