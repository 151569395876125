import FacebookIcon from "../assets/social/facebook.svg";
import InstagramIcon from "../assets/social/instagram.svg";
import LinkedinIcon from "../assets/social/linkedin.svg";
import TwitterIcon from "../assets/social/twitter.svg";
import BOB from "./bobConstants";
import { LINK_EXTERNAL, LINK_NEW_WINDOW } from "./umbracoConstants";

export const SOME = [
  { src: TwitterIcon, link: { url: BOB.SOCIAL.TWITTER, ...LINK_EXTERNAL, ...LINK_NEW_WINDOW }, alt: "Twitter" },
  { src: LinkedinIcon, link: { url: BOB.SOCIAL.LINKEDIN, ...LINK_EXTERNAL, ...LINK_NEW_WINDOW }, alt: "LinkedIn" },
  { src: FacebookIcon, link: { url: BOB.SOCIAL.FACEBOOK, ...LINK_EXTERNAL, ...LINK_NEW_WINDOW }, alt: "Facebook" },
  { src: InstagramIcon, link: { url: BOB.SOCIAL.INSTAGRAM, ...LINK_EXTERNAL, ...LINK_NEW_WINDOW }, alt: "Instagram" },
];

export const LOGO_FOOTER = "LOGO_FOOTER";
export const LOGO_FOOTER_MOBILE = "LOGO_FOOTER_MOBILE";
