import React from "react";
export const exclamation = (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17 15V9M17 1.00003C9.27867 0.989362 3.01067 7.2387 3 14.96C2.996 17.5774 3.728 20.144 5.11067 22.3667L1 31L9.632 26.888C16.1973 30.972 24.8307 28.96 28.9147 22.3947C32.9987 15.8294 30.9867 7.19603 24.4213 3.11203C22.1933 1.7267 19.6227 0.994696 17 1.00003ZM17 19C16.724 19 16.5 19.224 16.5 19.5C16.5 19.776 16.724 20 17 20C17.276 20 17.5 19.776 17.5 19.5C17.5 19.224 17.276 19 17 19Z"
      stroke="#320E3B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const exclamationSmall = (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13 11.5V7M13 1.00002C7.209 0.992022 2.508 5.67902 2.5 11.47C2.497 13.433 3.046 15.358 4.083 17.025L1 23.5L7.474 20.416C12.398 23.479 18.873 21.97 21.936 17.046C24.999 12.122 23.49 5.64702 18.566 2.58402C16.895 1.54502 14.967 0.996022 13 1.00002ZM13 14.5C12.793 14.5 12.625 14.668 12.625 14.875C12.625 15.082 12.793 15.25 13 15.25C13.207 15.25 13.375 15.082 13.375 14.875C13.375 14.668 13.207 14.5 13 14.5Z"
      stroke="#320E3B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const arrowUp = (
  <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.5 8.33337L7.64667 1.18671C7.84133 0.991375 8.158 0.991374 8.35267 1.18604L8.35333 1.18671L15.5 8.33337"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <title>Skjul</title>
    </path>
  </svg>
);

export const arrowUpSmall = (
  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.25 5.16675L4.82333 1.59341C4.92067 1.49575 5.079 1.49575 5.17633 1.59308L5.17667 1.59341L8.75 5.16675"
      stroke="#320E3B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <title>Skjul</title>
    </path>
  </svg>
);
