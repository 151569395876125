import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import "./DynamicImage.scss";

const DynamicImage = (props) => {

    // ************************************
    // Properties
    // ************************************


    const classPrefix = "bwl-DynamicImage";

    const scalingTypes = {
        automatic: 'automatic',
        full: 'full'
    }

    const {
        theme,
        url,
        caption,
        scaling
    } = props;

    // ************************************
    // Render
    // ************************************

    return (
        <div className={`${classPrefix}`}>
            <img src={url} className={classnames(`${classPrefix}__image`, {
                [`${classPrefix}__image__${scaling}`]: scaling === scalingTypes.automatic || scaling === scalingTypes.full
            })} />
            {caption && (
                <div className={classnames(`${classPrefix}__caption`, {
                    [`${classPrefix}__background__${theme}`]: theme
                })}>
                    <label className={classnames(`${classPrefix}__caption__label`, {
                        [`${classPrefix}__text__${theme}`]: theme
                    })}>{caption}</label>
                </div>
            )}
        </div>
    );
};

// ***********************************
// Validation
// ***********************************

DynamicImage.propTypes = {
    theme: PropTypes.oneOf(["light", "dark"]),
    url: PropTypes.string.isRequired,
    caption: PropTypes.string,
    scaling: PropTypes.oneOf(["automatic", "full"]),
}

DynamicImage.defaultProps = {
    theme: "light",
    scaling: "automatic"
}

export default DynamicImage;