import vimeoService from "../services/vimeoService";

export const VIMEO_FETCH_STARTED = "VIMEO_FETCH_STARTED";
export const VIMEO_FETCH_SUCCESS = "VIMEO_FETCH_SUCCESS";
export const VIMEO_FETCH_FAILED = "VIMEO_FETCH_FAILED";

export function vimeoFetchStarted(id) {
  return {
    type: VIMEO_FETCH_STARTED,
    id
  };
}

export function vimeoFetchSuccess(id, video) {
  return {
    type: VIMEO_FETCH_SUCCESS,
    id,
    video
  };
}

export function vimeoFetchFailed(id, error) {
  return {
    type: VIMEO_FETCH_FAILED,
    id,
    error
  };
}

export const getVideoByIdWidthHeight = (id, width, height) => {
  return async dispatch => {
    dispatch(vimeoFetchStarted(id));

    try {
      const video = await vimeoService.getVideoByIdWidthHeight(id, width, height);
      dispatch(vimeoFetchSuccess(id, video));
    } catch (e) {
      console.warn(e);
      dispatch(vimeoFetchFailed(id, e));
    }
  };
};
