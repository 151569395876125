import React from 'react';
import './simpleSpinner.scss'
import classnames from 'classnames';
export default ({ large, xl, className}) => {
  const clsx = classnames(className, "bwl-simple-spinner", {
    "bwl-simple-spinner--large": large,
    "bwl-simple-spinner--x-large": xl,
  })
  return <div className={clsx}/>;
}
