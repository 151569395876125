const loadingState = (status, error) => ({
  loadingState: { status, error: error }
});

const FETCH_FETCHING = "FETCH_FETCHING";
const FETCH_SUCCESS = "FETCH_SUCCESS";
const FETCH_FAILED = "FETCH_FAILED";
const FETCH_UNINITIATED = "FETCH_UNINITIATED";
const IS_UPDATING = "IS_UPDATING";

export const setFetchUninitiated = () => loadingState(FETCH_UNINITIATED, null);
export const setFetchSuccess = () => loadingState(FETCH_SUCCESS, null);
export const setFetchFailed = error => loadingState(FETCH_FAILED, error);
export const setFetchIsFetching = () => loadingState(FETCH_FETCHING, null);
export const setIsUpdating = () => loadingState(IS_UPDATING, null);

export const hasFetchState = entity => !!entity.loadingState;
export const isFetching = entity => entity.loadingState.status === FETCH_FETCHING;
export const isFetchSuccess = entity => entity.loadingState.status === FETCH_SUCCESS;
export const isFetchFailed = entity => entity.loadingState.status === FETCH_FAILED;
export const isFetchUninitiated = entity => entity.loadingState.status === FETCH_UNINITIATED;
export const isUpdating = entity => entity.loadingState.status === IS_UPDATING;
