import { LinkButton } from "../bob-web-library/components";
import { getPropValue, getUniqueIdForContentTypeAlias, tryGetLinkPropValue } from "../logic/contentLogic";

export const renderButtonNestedContent = (content, props = {}) => {
  const text = getPropValue(content, "buttonNestedContentText");
  const variant = getPropValue(content, "buttonNestedContentType") || "primary";
  const link = tryGetLinkPropValue(content, "buttonNestedContentUrl", content.contentTypeAlias);

  /** All buttons should redirect the user somewhere.
   *  When SUBMIT-buttons are required, we should (probably) package them with the schemas and not as a standalone button */
  return link && <LinkButton key={getUniqueIdForContentTypeAlias(content.contentTypeAlias)} link={link} variant={variant} text={text} />;
};
