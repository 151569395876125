import clx from "classnames";
import { useState } from "react";
import { usePopper } from "react-popper";
import Card from "../Card";
import "./popupMenu.scss";

const PopupMenu = ({ anchorEl, open, children, className, style = {}, modifiers: modifiersProp = [], arrow, skidding = 0, distance = 0 }) => {
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  let modifiers = modifiersProp;
  let offsetModifier = modifiersProp.find(m => m.name === "offset");
  let arrowModifier = modifiersProp.find(m => m.name === "arrow");
  if (arrow && !arrowModifier) modifiers.push({ name: "arrow", options: { element: arrowElement, padding: 5 } });
  if (!offsetModifier) modifiers.push({ name: "offset", options: { offset: [0, distance] } });

  const { styles, attributes } = usePopper(anchorEl, popperElement, {
    placement: "bottom",
    modifiers,
  });
  const classNames = clx(className, "bwl-popupmenu-container");
  if (!open || !anchorEl) return null;
  return (
    <div className={classNames} ref={setPopperElement} style={{ ...style, ...styles.popper }} {...attributes.popper}>
      <Card className="bwl-popupmenu-container__card">{children}</Card>
      {arrow && <div className="bwl-popupmenu-container__arrow" ref={setArrowElement} style={styles.arrow} />}
    </div>
  );
};

export default PopupMenu;
