import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import "./heading.scss";

export const headingMapping = {
  frontPage: "front-page",
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4"
};

const Heading = ({ children, className, animate, variant }) => {
  const Component = variant === "frontPage" ? "h1" : headingMapping[variant];

  return (
    <Component
      className={classnames(className, "bwl-heading", [`bwl-heading--${headingMapping[variant]}`], {
        "bwl-heading--animate": animate
      })}
    >
      {children}
    </Component>
  );
};

Heading.defaultProps = {
  variant: "h1"
};

Heading.propTypes = {
  /** Children for the component */
  children: PropTypes.node.isRequired,
  /** Class name for root node */
  className: PropTypes.string,
  /** Activation of animation for Title */
  animate: PropTypes.bool,
  /** Title of choice */
  variant: PropTypes.oneOf(["frontPage", "h1", "h2", "h3", "h4"])
};

export default Heading;
