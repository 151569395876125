import React from "react";
import { getContentPageProps } from "../logic/contentLogic";

const Info = React.lazy(() => import("../components/Info"));

export const renderInfoContent = (content, props = {}) => {
  const Helmet = props.helmet;

  return (
    <>
      <Helmet />
      <Info key={content.id} {...getContentPageProps(content)} />
    </>
  );
};
