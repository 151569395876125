export const offsetMap = {
  "Large-small": [2, 9],
  "Small-large": [1, 6],
  "Small-small": [3, 7],
  "Single large": [3]
};

export const spanMap = {
  "Large-small": [6, 4],
  "Small-large": [4, 6],
  "Small-small": [4, 4],
  "Single large": [8]
};
