import { USER_PROPERTIES_SUCCESS, USER_PROPERTIES_FAILED, USER_PROPERTIES_STARTED } from "../actions/propertiesAction";
import { setFetchSuccess, setFetchFailed, setFetchIsFetching, setFetchUninitiated } from "../loadingStates";

const initialState = {
  ...setFetchUninitiated()
};

const userProperties = (state = initialState, action) => {
  switch (action.type) {
    case USER_PROPERTIES_STARTED:
      return {
        ...state,
        ...setFetchIsFetching()
      };
    case USER_PROPERTIES_SUCCESS:
      console.log("properties success", action);
      return {
        ...state,
        ...setFetchSuccess(),
        lastName: action.json.lastName,
        email: action.json.email,
        receivedAt: action.receivedAt
      };
    case USER_PROPERTIES_FAILED:
      return {
        ...state,
        ...setFetchFailed(action.error)
      };
    default:
      return state;
  }
};

export default userProperties;
