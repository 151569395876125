import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import Card from "../Card";
import Grid, { GridColumn, GridRow } from "../Grid";
import Image from "../Image";
import LinkButton from "../LinkButton";
import LinkWrapper from "../LinkWrapper";
import { Heading, Text } from "../Typography";
import "./featuredItem.scss";
import { variantProps } from "./utils/constants";

/** A FeaturedItem supporting sideContent/Image on left (default) or right side.
 */
const FeaturedItem = props => {
  const { title, text, image, link, linkText, variant, sideContent, bottomContent, smallerHeading, imageOverflow } = props;

  const {
    contentSpan,
    contentSpanMobile,
    contentOffset,
    contentOffsetMobile,
    imageSpan,
    imageSpanMobile,
    imageOffset,
    imageOffsetMobile,
    imageIsFirst,
    imageIsFirstOnMobile
  } = variantProps[variant];

  const renderContent = () => (
    <GridColumn className="bwl-featured-item__column" span={contentSpan} spanMobile={contentSpanMobile} offset={contentOffset} offsetMobile={contentOffsetMobile}>
      <Card className="bwl-featured-item-card" backgroundColor="transparent">
        <LinkWrapper link={link}>
          <Heading variant={smallerHeading ? "h3" : "h2"} className="bwl-featured-item-card__title">
            {title}
          </Heading>
        </LinkWrapper>
        {text && (
          <Text variant="lead" className="bwl-featured-item-card__text">
            {text}
          </Text>
        )}
        <div className="bwl-featured-item-card__link-container">
          <LinkButton variant="secondary" text={linkText || "Fortell meg mer"} link={link} />
        </div>
      </Card>
    </GridColumn>
  );
  const renderContentWithoutLink = () => (
    <GridColumn className="bwl-featured-item__column" span={contentSpan} spanMobile={contentSpanMobile} offset={contentOffset} offsetMobile={contentOffsetMobile}>
      <Card className="bwl-featured-item-card" backgroundColor="transparent">
        {title && (
          <Heading variant={smallerHeading ? "h3" : "h2"} className="bwl-featured-item-card__title">
            {title}
          </Heading>
        )}
        {text && (
          <Text variant="lead" className="bwl-featured-item-card__text">
            {text}
          </Text>
        )}
        {bottomContent && <div className="bwl-featured-item-card__link-container">{bottomContent}</div>}
      </Card>
    </GridColumn>
  );

  // Determines what to render as sideContent
  const getSideContentToRender = (image, sideContent) => (!!sideContent ? "content" : !!(image && image.fallback) ? "image" : null);
  const renderSideContent = () => {
    const content = getSideContentToRender(image, sideContent);
    return (
      content && (
        <GridColumn
          className={`bwl-featured-item__${content}-column`}
          span={imageSpan}
          spanMobile={imageSpanMobile}
          offset={imageOffset}
          offsetMobile={imageOffsetMobile}
          isFirst={imageIsFirst}
          isFirstOnMobile={imageIsFirstOnMobile}>
          <div className={`bwl-featured-item__${content}-container`}>
            {content === "content" ? sideContent : <Image image={image} className="bwl-featured-item__image" />}
          </div>
        </GridColumn>
      )
    );
  };

  return (
    <div
      className={classnames("bwl-featured-item", {
        "bwl-featured-item--horizontal-image-left": variant === "horizontalImageLeft",
        "bwl-featured-item--horizontal-image-right": variant === "horizontalImageRight",
        "bwl-featured-item--image-overflow": !!imageOverflow
      })}>
      <Grid>
        <GridRow backgroundSpan={7}>
          {/* If either the link supplied is null or bottomContent exists, render the variation allowing bottomContent */}
          {link === null || !!bottomContent ? renderContentWithoutLink() : renderContent()}
          {renderSideContent()}
        </GridRow>
      </Grid>
    </div>
  );
};

FeaturedItem.propTypes = {
  /** Title for component */
  title: PropTypes.string,
  /** Text for component */
  text: PropTypes.string,
  /** Image for component */
  image: PropTypes.shape({
    fallback: PropTypes.string.isRequired,
    webp: PropTypes.string.isRequired
  }),
  /** Link for component */
  link: PropTypes.shape({
    target: PropTypes.string,
    type: PropTypes.number,
    url: PropTypes.string.isRequired
  }),
  /** Link text for component */
  linkText: PropTypes.string,
  /** Variant of component */
  variant: PropTypes.oneOf(["horizontalImageLeft", "horizontalImageRight"]),
  /** Side content to render, should be something like ContentCard, LinkCard, InfoCard, etc... */
  sideContent: PropTypes.node,
  /** Renders an h3 instead of h2 */
  smallerHeading: PropTypes.bool,
  /** image overflows beyond the grid */
  imageOverflow: PropTypes.bool
};

FeaturedItem.defaultProps = {
  variant: "horizontalImageLeft"
};

export default FeaturedItem;
