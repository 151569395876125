import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import Button from "../Button";
import Input from "../Input";
import LinkWrapper from "../LinkWrapper";
import { Text } from "../Typography";
import "./form.scss";

const initialState = {
  validationState: {
    errorMessage: "Skriv inn en gyldig epost",
    showError: false,
    showSuccess: false
  },
  value: ""
};

/** Requires a formSubmission-function with a post to function. */
class Form extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  labelRaised(input) {
    return input && input !== null && input !== "";
  }

  validate() {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const result = re.test(this.state.value);
    return result;
  }

  onInputChange = event => {
    this.setState({ value: event.target.value }, this.clearError());
  };

  onKeyDown(event) {
    switch (event.key) {
      case "Enter":
        const isValid = this.validate();
        this.setState({ validationState: { showError: !isValid, showSuccess: isValid } }, this.postFormIfValid(isValid));
        break;
      case "Escape":
        this.clearError();
      default:
        break;
    }
  }

  clearError() {
    this.setState(initialState);
  }

  postFormIfValid(isValid) {
    if (isValid) this.props.postForm(this.state.value);
  }

  handleButtonClick = event => {
    const isValid = this.validate();
    this.setState({ validationState: { showError: !isValid, showSuccess: isValid } }, this.postFormIfValid(isValid));
  };

  render() {
    const { label, variant, ctaText, link, linkText } = this.props;
    const inputClasses = classnames("bwl-form-container-input", {
      "bwl-form-container-input--focused": this.labelRaised(this.state.value)
    });
    return (
      <div className="bwl-form-container">
        <Input
          className={inputClasses}
          label={label}
          validationState={this.state.validationState}
          type="email"
          onChange={this.onInputChange}
          onKeyDown={this.onKeyDown}
          value={this.state.value}
        />
        <Button className="bwl-form-container-button" variant={variant} text={ctaText} onClick={this.handleButtonClick} />
        {link && linkText && (
          <div className="bwl-form-container-link-container">
            <LinkWrapper link={link}>
              <Text variant="description" className="bwl-form-container-link">
                {linkText}
              </Text>
            </LinkWrapper>
          </div>
        )}
      </div>
    );
  }
}

Form.defaultProps = {
  label: "Din e-postadresse",
  ctaText: "Abonnér",
  variant: "secondary"
};

Form.propTypes = {
  label: PropTypes.string,
  variant: PropTypes.oneOf(["primary", "secondary", "text", "text-light", "positive", "negative"]),
  ctaText: PropTypes.string,
  postForm: PropTypes.func.isRequired,
  link: PropTypes.shape({
    target: PropTypes.string,
    type: PropTypes.number,
    url: PropTypes.string.isRequired
  }),
  linkText: PropTypes.string
};

export default Form;
