import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import "./iconButton.scss";

const IconButton = (props) => {

    // ************************************
    // Properties
    // ************************************

    const classPrefix = "bwl-IconButton";

    const {
        theme,
        className,
        tooltip,
        onClick,
        onMouseEnter,
        onMouseLeave,
        isFocused,
        active
    } = props;

    // ************************************
    // Render Template
    // ************************************

    return (
        <div
            className={classnames(`${classPrefix} ${className}`, {
                [`${classPrefix}__active`]: active,
                [`${classPrefix}__background__${theme}`]: theme,
                [`${classPrefix}__focused`]: isFocused
            })}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            title={tooltip}
        >
            {props.children && props.children}
        </div>
    );
}

// ************************************
// Validation
// ***********************************

IconButton.propTypes = {
    theme: PropTypes.oneOf(["light", "dark"]),
    tooltip: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    active: PropTypes.bool,
    isFocused: PropTypes.bool
}

IconButton.defaultProps = {
    theme: "light",
    className: '',
    tooltip: '',
    active: true,
    isFocused: false
}

export default IconButton;