import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import Card from "../Card";
import Image from "../Image";
import { Heading, Text } from "../Typography";
import "./info-card.scss";

const InfoCard = ({ image, tag, title, text, className }) => {
  const classNames = classnames("bwl-info-card", className);
  return (
    <div className={classNames}>
      <Card backgroundColor="white" className="bwl-info-card-item">
        {image && image.fallback && <Image image={image} className="bwl-info-card__image" />}
        {tag && (
          <Text variant="titleTag" className="bwl-info-card-item__tag">
            {tag}
          </Text>
        )}
        {title && (
          <Heading variant="h4" className="bwl-info-card-item__title">
            {title}
          </Heading>
        )}
        {text && (
          <Text variant="paragraph" className="bwl-info-card-item__text">
            {text}
          </Text>
        )}
      </Card>
    </div>
  );
};

InfoCard.propTypes = {
  /** the InfoCard image  */
  image: PropTypes.shape({
    fallback: PropTypes.string.isRequired,
    webp: PropTypes.string.isRequired
  }),
  /** the InfoCard optional tag */
  tag: PropTypes.string,
  /** the InfoCard title */
  title: PropTypes.string,
  /**the InfoCard text */
  text: PropTypes.string,
  /**additional top level class names  */
  className: PropTypes.string
};

InfoCard.defaultProps = {
  tag: " "
};

export default InfoCard;
