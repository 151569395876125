import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import Grid from "../../../Grid/components/Grid";
import GridColumn from "../../../Grid/components/GridColumn";
import GridRow from "../../../Grid/components/GridRow";
import "./fullscreenText.scss";

const FullscreenText = (props) => {

    // ************************************
    // Properties
    // ************************************

    const classPrefix = "bwl-FullscreenText";

    const {
        theme,
        title,
        text,
        layout
    } = props;

    // ************************************
    // Render Functionality
    // ************************************

    const getTitleEle = () => {
        return (<h1 className={classnames(`${classPrefix}__grid__header`, {
            [`${classPrefix}__text__${theme}`]: theme
        })}>{title}</h1>);
    }

    const getTextEle = () => {
        return (
            <div
                className={classnames(`${classPrefix}__grid__text`, {
                    [`${classPrefix}__text__${theme}`]: theme
                })}
                dangerouslySetInnerHTML={{ __html: text }}
            ></div>
        );
    }

    // ************************************
    // Render template
    // ************************************

    return (
        <div className={classnames(`${classPrefix}`, {
            [`${classPrefix}__background__${theme}`]: theme
        })}>
            <Grid className={`${classPrefix}__grid`}>
                {layout === "vertical" &&
                    <GridRow className={`${classPrefix}__grid__container`}>
                        {title && <GridColumn offset={4} offsetMobile={2} span={6} spanMobile={10}>{getTitleEle()}</GridColumn>}
                        {text && <GridColumn offset={4} offsetMobile={2} span={6} spanMobile={10}>{getTextEle()}</GridColumn>}
                    </GridRow>
                }
                {layout === "horizontal" &&
                    <GridRow className={`${classPrefix}__grid__container`}>
                        {title && <GridColumn offset={2} offsetMobile={2} span={4} spanMobile={10}>{getTitleEle()}</GridColumn>}
                        {text && <GridColumn offset={6} offsetMobile={2} span={6} spanMobile={10}>{getTextEle()}</GridColumn>}
                    </GridRow>
                }
            </Grid>
        </div>
    );
};

// ************************************
// Validation
// ***********************************

FullscreenText.propTypes = {
    theme: PropTypes.oneOf(["light", "dark"]),
    title: PropTypes.string,
    text: PropTypes.string,
    layout: PropTypes.oneOf(["vertical", "horizontal"]),
}

FullscreenText.defaultProps = {
    theme: "light",
    layout: "vertical"
}

export default FullscreenText;