import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { ReactSVG } from 'react-svg';
import alarmIcon from '../assets/icons/alarm.svg';
import arrowNavIcon from '../assets/icons/arrow-right-16x16.svg';
import arrow from '../assets/icons/arrow.svg';
import back from '../assets/icons/back.svg';
import buildingsNavIcon from '../assets/icons/buildings-nav56.svg';
import buildingsIcon from '../assets/icons/buildings.svg';
import check from '../assets/icons/check-1.svg';
import arrowHead from '../assets/icons/chevron-right-4x16-1-5px-stroke.svg';
import arrowHeadBold from '../assets/icons/chevron-right-4x16-2px-stroke.svg';
import arrowHeadBoldMist from '../assets/icons/chevron-right-mist-4x16-2px-stroke.svg';
import arrowHeadBoldWhite from '../assets/icons/chevron-right-white-4x16-2px-stroke.svg';
import closeSmall from '../assets/icons/close-2.svg';
import closeNavIcon from '../assets/icons/close-nav24.svg';
import documentIcon from '../assets/icons/document.svg';
import exclaimIcon from '../assets/icons/exclaim.svg';
import fallback from '../assets/icons/fallback.svg';
import heartIcon from '../assets/icons/heart24.svg';
import homeIcon from '../assets/icons/house-chimney-2.svg';
import houseIcon from '../assets/icons/house.svg';
import locationIcon from '../assets/icons/location.svg';
import loginIcon from '../assets/icons/login_24_heroicon.svg';
import navBurgerIcon from '../assets/icons/navigation-menu.svg';
import navProfileIcon from '../assets/icons/navigation-profile.svg';
import passwordIcon from '../assets/icons/password-cursor-1.svg';
import passwordWrongIcon from '../assets/icons/password-denied.svg';
import homeGroupNavIcon from '../assets/icons/person-group-nav56.svg';
import homePersonNavIcon from '../assets/icons/person-in-house-nav56.svg';
import piggybankIcon from '../assets/icons/piggybank.svg';
import presentIcon from '../assets/icons/present.svg';
import searchIcon from '../assets/icons/search.svg';
import signatureIcon from '../assets/icons/signature.svg';
import slidersIcon from '../assets/icons/sliders.svg';
import thickArrowRight from '../assets/icons/thick-arrow-right.svg';
import './icon.scss';

export const iconMap = {
  alarm: alarmIcon,
  heart: heartIcon,
  house: houseIcon,
  location: locationIcon,
  login: loginIcon,
  password: passwordIcon,
  passwordWrong: passwordWrongIcon,
  home: homeIcon,
  homePersonLarge: homePersonNavIcon,
  homeGroupLarge: homeGroupNavIcon,
  search: searchIcon,
  navBurger: navBurgerIcon,
  navProfile: navProfileIcon,
  buildingsLarge: buildingsNavIcon,
  close: closeNavIcon,
  arrowHead: arrowHead,
  arrowHeadBold: arrowHeadBold,
  arrowHeadBoldMist: arrowHeadBoldMist,
  arrowHeadBoldWhite: arrowHeadBoldWhite,
  arrowNav: arrowNavIcon,
  arrow: arrow,
  back: back,
  closeSmall: closeSmall,
  check: check,
  thickArrowRight: thickArrowRight,
  signature: signatureIcon,
  present: presentIcon,
  document: documentIcon,
  sliders: slidersIcon,
  piggybank: piggybankIcon,
  exclaim: exclaimIcon,
  buildings: buildingsIcon,
};

const Icon = (props) => {
  const { className, small, large, xlarge, noMargin, reactSVGProps = {} } = props;
  const classes = classnames(className, 'bwl-icon', {
    'bwl-icon--no-margin': noMargin,
    'bwl-icon--small': small,
    'bwl-icon--large': large,
    'bwl-icon--x-large': xlarge,
  });

  const getIcon = () => {
    if (Object.keys(iconMap).includes(props.icon)) {
      return iconMap[props.icon];
    }
    console.error(`${props.icon} not found, using fallback`);
    return fallback;
  };
  return (
    <div className={classes}>
      <ReactSVG src={getIcon()} {...reactSVGProps} />
    </div>
  );
};

Icon.propTypes = {
  /** The icon */
  icon: PropTypes.oneOf(Object.keys(iconMap)).isRequired,
  /** Bool prop to set no margin on Icon */
  noMargin: PropTypes.bool,
  small: PropTypes.bool,
  large: PropTypes.bool,
  xlarge: PropTypes.bool,
};

export default Icon;
