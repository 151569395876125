export const variantProps = {
  horizontalImageLeft: {
    contentSpan: 6,
    contentSpanMobile: 12,
    contentOffset: 6,
    contentOffsetMobile: 1,
    imageSpan: 4,
    imageSpanMobile: 12,
    imageOffset: 1,
    imageOffsetMobile: 1,
    imageIsFirst: true,
    imageIsFirstOnMobile: true
  },
  horizontalImageRight: {
    contentSpan: 6,
    contentSpanMobile: 12,
    contentOffset: 2,
    contentOffsetMobile: 1,
    imageSpan: 4,
    imageSpanMobile: 12,
    imageOffset: 9,
    imageOffsetMobile: 1,
    imageIsFirst: false,
    imageIsFirstOnMobile: true
  }
};
