import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { LinkWrapper } from "../../bob-web-library/components";
import Grid, { GridColumn, GridRow } from "../../bob-web-library/components/Grid";
import routes from "../../constants/routeConstants";
import { ensureTrailingSlash } from "../../logic/navigationLogic";
import "./banner.scss";
import { arrowUp, exclamation, exclamationSmall } from "./icons";

class Banner extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      hideBanner: false,
    };
    this.toggleBanner = this.toggleBanner.bind(this);
  }
  toggleBanner() {
    this.setState({ hideBanner: !this.state.hideBanner });
  }
  bannerIsTransparant(page) {
    if (!page) return false;
    return page.type === "topic" || page.type === "home";
  }
  renderLink(link) {
    return (
      !!link && (
        <span onClick={e => e.stopPropagation()}>
          <LinkWrapper className="banner__link" link={link}>
            {link.name}
          </LinkWrapper>
        </span>
      )
    );
  }
  render() {
    const { message, link, showOnUrls, sitemapUrls, currentPath } = this.props;
    const largeBannerClass = "banner__message-container" + (this.state.hideBanner ? " banner__message-container-hidden" : "");
    const smallBannerClass = "banner__mini-banner" + (this.state.hideBanner ? "" : " banner__mini-banner-hidden");
    const currentPathWithTrailingSlash = ensureTrailingSlash(currentPath);
    const sitemapEntry = sitemapUrls[currentPathWithTrailingSlash];
    if (!sitemapEntry && currentPath !== routes.SEARCH) {
      return null;
    }
    if (showOnUrls.length > 0 && !showOnUrls.includes(currentPathWithTrailingSlash)) {
      return null;
    }
    const bannerStyle = classnames("banner", { "banner--transparant": this.bannerIsTransparant(sitemapEntry) });
    return (
      message && (
        <div className={bannerStyle}>
          <div className="banner__content">
            <Grid className="banner__grid">
              <GridRow>
                <GridColumn span={10} offset={2} className=" banner__message-container--desktop">
                  <div className={largeBannerClass + " banner__message-container--desktop"} onClick={this.toggleBanner}>
                    <div className="banner__icon">{exclamation}</div>
                    <div className="banner__message">
                      {message} {this.renderLink(link)}
                    </div>
                    <div className="banner__hide-arrow">{arrowUp}</div>
                  </div>
                </GridColumn>
                <GridColumn span={12} offset={1} className=" banner__message-container--mobile">
                  <div className={largeBannerClass + " banner__message-container--mobile"} onClick={this.toggleBanner}>
                    <div className="banner__mobile-top">
                      <div className="banner__icon">{exclamationSmall}</div>
                      <div className="banner__hide-arrow">{arrowUp}</div>
                    </div>
                    <div className="banner__message">
                      {message} {this.renderLink(link)}
                    </div>
                  </div>
                </GridColumn>
              </GridRow>
            </Grid>
            <div className={smallBannerClass} onClick={this.toggleBanner}>
              <div className="banner__mini-banner-icon banner__mini-banner-icon--desktop">{exclamation}</div>
              <div className="banner__mini-banner-icon banner__mini-banner-icon--mobile">{exclamationSmall}</div>
            </div>
          </div>
        </div>
      )
    );
  }
}

Banner.propTypes = {
  message: PropTypes.string,
  link: PropTypes.shape({
    name: PropTypes.string,
    target: PropTypes.string,
    type: PropTypes.number,
    url: PropTypes.string,
  }),
};

export default Banner;
