import React from "react";
import PropTypes from "prop-types";
import { capitalize } from "../utils/helpers";
import { isHorizontal } from "../Drawer/Drawer";

const styles = {
  anchorLeft: {
    right: "auto"
  },
  anchorRight: {
    left: "auto",
    right: 0
  },
  anchorTop: {
    bottom: "auto",
    right: 0
  },
  anchorBottom: {
    top: "auto",
    bottom: 0,
    right: 0
  }
};

/**
 * @ignore - internal component.
 */
function SwipeArea(props) {
  const { anchor, className, width, ...other } = props;

  return (
    <div
      className="menu-swipe-area"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        bottom: 0,
        zIndex: 4,
        // zIndex: theme.zIndex.drawer - 1,
        // currently hard coded to drawers zIndex - 1
        [isHorizontal(props) ? "width" : "height"]: width,
        ...styles[`anchor${capitalize(anchor)}`]
      }}
      {...other}
    />
  );
}

SwipeArea.propTypes = {
  /**
   * Side on which to attach the discovery area.
   */
  anchor: PropTypes.oneOf(["left", "top", "right", "bottom"]).isRequired,
  /**
   * @ignore
   */
  className: PropTypes.string,
  /**
   * The width of the left most (or right most) area in pixels where the
   * drawer can be swiped open from.
   */
  width: PropTypes.number.isRequired
};

// export default withStyles(styles, { name: 'MuiPrivateSwipeArea' })(SwipeArea);
export default SwipeArea;
