import React from "react";
import { withRouter } from "react-router-dom";
import { scroll } from "../logic/domElementLogic";

class AnchorContainer extends React.PureComponent {
  // If the anchortag in the url matches the one instanced here, scroll to its domElement.
  // Handles scrolling on page refresh, otherwise scrolling will be handled in App::componentDidUpdate()

  constructor() {
    super();
    this.listener = null;
  }

  componentDidMount() {
    const { refProp, location } = this.props;

    const anchor = scroll.getAnchor(location);
    const anchorElement = scroll.getAnchorElement(location);
    if (anchor === refProp) {
      if (scroll.shouldScrollToAnchor(anchorElement, true, true)) {
        // Wondering why we use two RAFs? Check this video out:
        // https://www.youtube.com/watch?v=cCOL7MC4Pl0
        //
        // The componentDidMount() method is called after the DOM nodes are inserted.
        // The UI might not have rendering the changes. We request a frame.
        requestAnimationFrame(() => {
          // The browser should be about to render the DOM nodes
          // that React committed at this point.
          // We don't want to interrupt. Let's wait the next frame.
          requestAnimationFrame(() => {
            // The UI is up-to-date at this point.
            scroll.scrollTo(anchorElement);
          });
        });
      }
    }
  }

  componentWillUnmount() {
    this.listener = null;
  }

  render() {
    const { className, refProp } = this.props;
    return (
      <div className={className} id={refProp}>
        {this.props.children}
      </div>
    );
  }
}

export default withRouter(AnchorContainer);
