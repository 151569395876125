import classnames from "classnames";
import { Grid, GridColumn, GridRow } from "../bob-web-library/components";
import { CMS_MEDIA_ADDRESS } from "../constants/umbracoConstants";
import { get, getUniqueIdForContentTypeAlias } from "../logic/contentLogic";
import { contentTypeAliasAsCssClass } from "./../logic/contentLogic";

const renderInfo = content => {
  var html = get(content, "props.text.value", "");
  const title = get(content, "props.title.value", "");
  const htmlWithMedia = html.replace('src="/media/', `src="${CMS_MEDIA_ADDRESS}`).replace('href="/media/', `href="${CMS_MEDIA_ADDRESS}`);
  const key = getUniqueIdForContentTypeAlias(content.contentTypeAlias);
  const cssClass = contentTypeAliasAsCssClass(content.contentTypeAlias);

  return (
    <Grid key={key}>
      <GridRow key={key} className={classnames("row-content", cssClass)}>
        <GridColumn key={key} className={classnames("column-content", cssClass)} span={6} offset={4}>
          <h2>{title}</h2>
          <br />
          <div
            key={key}
            className={classnames("rich-text-editor", cssClass)}
            dangerouslySetInnerHTML={{
              __html: htmlWithMedia,
            }}
          />
          <br />
          <br />
        </GridColumn>
      </GridRow>
    </Grid>
  );
};

export const renderInformationContent = (content, props = {}) => {
  const nestedContent = get(content, "props.info.value", []);
  return nestedContent.map(content => renderInfo(content));
};
