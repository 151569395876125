import {authFailed, authStarted, authSuccess} from './authAction';
import {GET_MEMBER_START, GET_MEMBER_SUCCESS, GET_MEMBER_FAILED} from '../constants/memberConstants';
import {bobApiUrl} from '../constants/auth0';

const getMemberStart = () => ({type: GET_MEMBER_START});
const getMemberSuccess = ({member}) => ({type: GET_MEMBER_SUCCESS, payload: {member}});
const getMemberFailed = ({message}) => ({type: GET_MEMBER_FAILED, payload: {message}});


export const getMember = ({bobPersonId, getAccessTokenSilently}) => async (dispatch) => {
  try {
    dispatch(getMemberStart());
    const at = await getAccessTokenSilently();
    if (!at) {
      dispatch(getMemberFailed({message: 'No access token present.'}));
    }

    const response = await fetch(`${bobApiUrl}/member/v1/Member/person/${bobPersonId}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${at}`
      }
    });
    if (!response.ok) {
      dispatch(getMemberFailed({message: `Status: ${response.status} - ${response.statusText}`}));
    }
    else if (!response.headers.get('content-type').includes('application/json')) {
      dispatch(getMemberFailed({message: `Response was not json. Status: ${response.status} - ${response.statusText}`}));

    } else {
      const member = await response.json();
      dispatch(getMemberSuccess({member}))
    }
  } catch (e) {
    dispatch(getMemberFailed({message: e.toString()}))
  }


};