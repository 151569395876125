import PropTypes from "prop-types";
import React from "react";
import { Arrow, Card, Heading, LinkWrapper, Text } from "../";
import { withFocusState } from "../WithFocusState";
import "./searchResultCard.scss";

const SearchResultCard = ({ title, description, link, isFocused, ...rest }) => {
  return (
    <div className="bwl-search-result">
      <LinkWrapper link={link}>
        <div className="bwl-hover-listener" {...rest}>
          <Card animate>
            {title && (
              <Heading className="bwl-search-result__title" variant="h4">
                {title}
              </Heading>
            )}
            {description && (
              <Text variant="paragraph" className="bwl-search-result__description">
                {description}
              </Text>
            )}
            {link.url && (
              <Text variant="description" className="bwl-search-result__url">
                {link.url}
              </Text>
            )}
            <Arrow isFocused={isFocused} variant="long" />
          </Card>
        </div>
      </LinkWrapper>
    </div>
  );
};

SearchResultCard.propTypes = {
  /** Title of search result page */
  title: PropTypes.string.isRequired,
  /** Description of search result page (should be cropped to max 100 chars) */
  description: PropTypes.string,
  /** Link to search result page */
  link: PropTypes.shape({
    url: PropTypes.string.isRequired,
    type: PropTypes.number
  }).isRequired
};

export default withFocusState(SearchResultCard);
