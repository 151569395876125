import { Auth0Context, Auth0Provider } from "@auth0/auth0-react";
import classnames from "classnames";
import { format } from "date-fns";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { authService } from "./actions/authAction";
import { getImportantMessageById } from "./actions/importantMessage";
import { getMember } from "./actions/memberActions";
import { getPerson } from "./actions/personActions";
import { userPropertiesService } from "./actions/propertiesAction";
import { getSettingsById } from "./actions/settings";
import { getSitemapById } from "./actions/sitemap";
import { Nav, NavBar } from "./bob-web-library/components";
import Banner from "./components/Banner";
import Breadcrumb from "./components/Breadcrumb";
import Footer from "./components/Footer";
import { boardPortalUrl } from "./constants/appConstants";
import { auth0Audience, auth0ClientId, auth0Domain, auth0Enabled, auth0RedirectUri } from "./constants/auth0";
import ROUTES from "./constants/routeConstants";
import { POLL_SITEMAP_INTERVAL_IN_SECONDS, ROOT_CONTENT_ID, ROOT_IMPORTANT_MESSAGE_ID, ROOT_SETTINGS_ID } from "./constants/umbracoConstants";
import { isBrowserInternetExplorer } from "./logic/compatibilityLogic";
import { scroll } from "./logic/domElementLogic";
import { navigateTo } from "./logic/navigationLogic";
import "./styles/app.scss";
import "./styles/ie11.scss";

class App extends React.Component {
  state = {
    menuOpen: false,
  };
  static contextType = Auth0Context;
  static propTypes = {
    children: PropTypes.node,
  };

  onLogout = () => {
    this.context.logout({
      returnTo: `${window.location.origin}/api/auth/logout`,
    });
  };

  startSitemapPolling() {
    this.pollSitemapTimer = setInterval(() => this.props.getSitemapById(ROOT_CONTENT_ID), POLL_SITEMAP_INTERVAL_IN_SECONDS * 1000);
  }

  stopSitemapPolling() {
    clearInterval(this.pollSitemapTimer);
    this.pollSitemapTimer = null;
  }

  componentDidMount() {
    this.props.getSitemapById(ROOT_CONTENT_ID);
    this.props.getSettingsById(ROOT_SETTINGS_ID);
    this.props.getImportantMessageById(ROOT_IMPORTANT_MESSAGE_ID);
    this.startSitemapPolling();
    if (isBrowserInternetExplorer()) {
      document.body.classList.add("ie-11");
    }
    this.props.history.listen(() => {
      if (this.state.menuOpen) {
        this.setState({ menuOpen: false });
      }
    });
  }

  componentWillUnmount() {
    this.stopSitemapPolling();
  }

  onOpenMenu = isOpen => {
    this.setState({
      menuOpen: isOpen,
    });
  };

  getSnapshotBeforeUpdate(prevProps, prevState) {
    return { menuOpen: prevState.menuOpen };
  }

  componentDidUpdate(snapshot) {
    const anchorElement = scroll.getAnchorElement(this.props.location);
    if (scroll.shouldScrollToAnchor(anchorElement, snapshot.menuOpen, this.state.menuOpen)) {
      scroll.scrollTo(anchorElement);
    }
    this.fetchFromBobApi();
  }

  fetchFromBobApi = () => {
    if (this.context.isAuthenticated && this.context.user) {
      const { user } = this.context;
      const bobPersonId = user["http://docs.bob.no/bobPersonId"];
      const roles = user && user["http://docs.bob.no/roles"] ? user["http://docs.bob.no/roles"] : [];
      const isMember = roles.includes("Member");
      if (isMember && !this.props.memberState.member && !this.props.memberState.loading && !this.props.memberState.errorMessage) {
        this.props.getMember({
          bobPersonId,
          getAccessTokenSilently: this.context.getAccessTokenSilently,
        });
      }
      if (!this.props.personState.person && !this.props.personState.loading && !this.props.personState.errorMessage) {
        this.props.getPerson({
          bobPersonId,
          getAccessTokenSilently: this.context.getAccessTokenSilently,
        });
      }
    }
  };

  shouldRenderBreadcrumbs(sitemapUrls) {
    return Object.keys(sitemapUrls).length > 0;
  }

  handleRouteNavigate = (navBarRoute, qs = "") => {
    switch (navBarRoute) {
      case NavBar.routes.SEARCH:
        navigateTo(`${ROUTES.SEARCH}${qs}`);
        break;
      case NavBar.routes.LOG_IN:
        window.location.href = "/meg";
        break;
      case NavBar.routes.REGISTER_MEMBER:
        navigateTo(ROUTES.REGISTER_MEMBER);
        break;
      case NavBar.routes.CONTACT:
        navigateTo(ROUTES.CONTACT);
        break;
      case NavBar.routes.ROOT:
        navigateTo("/");
        break;
      default:
        console.log("No matching navBarRoute", qs);
        break;
    }
  };

  getBoardPortalLinkProps = () => {
    const { user } = this.context;
    const roles = user && user["http://docs.bob.no/roles"] ? user["http://docs.bob.no/roles"] : [];
    return roles.includes("BoardMember") || roles.includes("Chairman") || roles.includes("DeputyMember") || roles.includes("DeputyHead")
      ? { onClick: () => (window.location.href = boardPortalUrl) }
      : undefined;
  };

  render() {
    const { location, settings, sitemap, importantMessage, memberState, personState } = this.props;
    const user = personState.person && { name: personState.person.firstName };
    const classNamesContent = classnames("app__content", {
      "visually-hidden": this.state.menuOpen,
    });
    const classNamesHidden = classnames({
      "visually-hidden": this.state.menuOpen,
    });
    const footerLinks = settings && settings.footerLinks;
    const message = importantMessage && importantMessage.message;
    const seniorityDate =
      memberState.member && memberState.member.status === "Active" && memberState.member.seniorityDate
        ? format(new Date(memberState.member.seniorityDate), "dd.MM.yyy")
        : "";
    const hideNavBar = (() => {
      const s = new URLSearchParams(location && location.search);
      return s.get("hideNavBar") === "true";
    })();
    return (
      <div className="app">
        {!hideNavBar && (
          <Nav
            fixed={this.state.menuOpen}
            shadow={!!message}
            user={user}
            userMenuProps={{
              logout: () => {
                this.context.logout({
                  returnTo: `${window.location.origin}/api/auth/logout`,
                });
              },
              buttonLinks: [
                { text: "Min side", onClick: () => (window.location.href = "/meg") },
                { text: "Preferanser", onClick: () => (window.location.href = "/meg/preferanser") },
                { text: "Finn bolig", onClick: () => (window.location.href = "/finnbolig") },
                { text: "Faktura", onClick: () => (window.location.href = "/meg/faktura") },
                { text: "Ligningsoppgaver", onClick: () => (window.location.href = "/meg/ligningsoppgaver") },
              ],
              seniorityDate: seniorityDate,
              memberNumber: memberState.member?.status === "Active" ? memberState.member.memberNumber : "",
              boardPortalLinkProps: this.getBoardPortalLinkProps(),
            }}
            onLogout={() => this.onLogout}
            onOpenMenu={this.onOpenMenu}
            onRouteNavigate={this.handleRouteNavigate}
            menuOpen={this.state.menuOpen}
            megaMenu={sitemap.menu}
          />
        )}

        {!!message && (
          <Banner
            message={message}
            link={importantMessage.link}
            showOnUrls={importantMessage.showOnUrls}
            sitemapUrls={sitemap.url}
            currentPath={location.pathname}
          ></Banner>
        )}
        {this.shouldRenderBreadcrumbs(sitemap.url) && <Breadcrumb className={classNamesHidden} sitemapUrls={sitemap.url} currentPath={location.pathname} />}
        <div className={classNamesContent}>{this.props.children}</div>
        <Footer className={classNamesHidden} links={footerLinks} menuOpen={this.state.menuOpen} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    userProperties: state.userProperties,
    sitemap: state.sitemap,
    settings: state.settings,
    importantMessage: state.importantMessage,
    memberState: state.member,
    personState: state.person,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    authService: (user, pass) => dispatch(authService(user, pass)),
    userPropertiesService: token => dispatch(userPropertiesService(token)),
    getSitemapById: id => dispatch(getSitemapById(id)),
    getSettingsById: id => dispatch(getSettingsById(id)),
    getImportantMessageById: id => dispatch(getImportantMessageById(id)),
    getMember: options => dispatch(getMember(options)),
    getPerson: options => dispatch(getPerson(options)),
  };
};

App.propTypes = {
  children: PropTypes.node,
};

const Application = withRouter(connect(mapStateToProps, mapDispatchToProps)(App));

const index = props => {
  if (auth0Enabled) {
    console.log("Using auth0 and ON login.");
    return (
      <Auth0Provider
        domain={auth0Domain}
        clientId={auth0ClientId}
        audience={auth0Audience}
        redirectUri={auth0RedirectUri}
        onRedirectCallback={() => (window.location.href = "/meg")}
      >
        <Application {...props} />
      </Auth0Provider>
    );
  } else {
    return <Application {...props} />;
  }
};

export default index;
