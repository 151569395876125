import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import Icon from "../Icon";
import "./input.scss";

const Input = React.forwardRef((props, ref) => {
  const renderError = () => {
    return !!validationState.showError && validationState.errorMessage;
  };

  const renderIcon = onClickIcon => {
    const iconBaseClass = "bwl-input-text__icon";
    if (type === "password") {
      const iconClass = iconBaseClass + (validationState.showError ? "--error" : "");
      const icon = validationState.showError ? "passwordWrong" : "password";
      return (
        <div className={iconClass}>
          <Icon icon={icon} />
        </div>
      );
    }
    if (inputIcon != null) {
      return (
        <div className={iconBaseClass}>
          {onClickIcon ? (
            <button className={`${iconBaseClass}--clickable`} onClick={onClickIcon}>
              <Icon noMargin icon={inputIcon} />
            </button>
          ) : (
            <Icon noMargin icon={inputIcon} />
          )}
        </div>
      );
    }
  };

  const defaultValidation = {
    showError: false,
    showSuccess: false,
    showWarning: false,
    errorMessage: "An unknown error has occured",
  };
  const {
    className,
    classNameInput,
    type,
    onChange,
    inputIcon,
    iconLeft,
    whiteLabel,
    validationState = defaultValidation,
    label,
    onClickIcon,
    big,
    ...rest
  } = props;
  const inputClasses = classnames("bwl-input-text__input", classNameInput, {
    "bwl-input-text__input--error-state": !!validationState.showError,
    "bwl-input-text__input--success-state": !!validationState.showSuccess,
    "bwl-input-text__input--warning-state": !!validationState.showWarning,
    "bwl-input-text__input--icon-left": !!iconLeft,
    "bwl-input-text__input--big": !!big,
  });
  const labelClasses = classnames("bwl-input-text__container-label", {
    "bwl-input-text__container-label--white-label": whiteLabel,
    "bwl-input-text__container-label--big": big,
  });

  const inputId = `form-${label}-${Math.floor(Math.random() * 1000000)}`;
  return (
    <div className={classnames("bwl-input-text", className)}>
      <div className="bwl-input-text__container">
        <input 
          required 
          className={inputClasses} 
          onChange={onChange} 
          type={type} 
          {...rest} 
          id={inputId}
          ref={ref} 
        />
        <label className={labelClasses} htmlFor={inputId}>
          {label}
        </label>
        {renderIcon(onClickIcon)}
      </div>
      <div className="bwl-input-text__error-message">{renderError()}</div>
    </div>
  );
});

Input.propTypes = {
  /** onChange function */
  onChange: PropTypes.func,
  /** callback when the icon is clicked, will render the icon as a button if anything is passed */
  onClickIcon: PropTypes.func,
  /** Classname injector prop for container*/
  className: PropTypes.string,
  /** Classname injector prop for input*/
  classNameInput: PropTypes.string,
  /** label text which act as placeholder before typing. Do not use the placeholder attribute*/
  label: PropTypes.string,
  /** default html input type */
  type: PropTypes.string,
  /** name of icon to display. ignored for type="password" */
  inputIcon: PropTypes.string,
  /** icon is rendered to the left of the input field */
  iconLeft: PropTypes.bool,
  /** if white background is needed for the label */
  whiteLabel: PropTypes.bool,
  /** determines whether fill size is that of search */
  big: PropTypes.bool,
  /** content of this determines state of validation and an error message if any */
  validationState: PropTypes.shape({
    /** indicating the input is invalid */
    showError: PropTypes.bool,
    /** indicating the input is validated */
    showSuccess: PropTypes.bool,
    /** indicating the input should be changed */
    showWarning: PropTypes.bool,
    /** the message that will be displayed if showError is true */
    errorMessage: PropTypes.string,
  }),
};

export default Input;
