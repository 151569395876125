import { combineReducers } from "redux";
import auth from "./authReducer";
import content from "./content";
import importantMessage from "./importantMessage";
import userProperties from "./propertiesReducer";
import search from "./search";
import settings from "./settings";
import sitemap from "./sitemap";
import vimeo from "./vimeo";
import member from "./memberReducer";
import person from "./personReducer";
export default combineReducers({
  auth,
  content,
  sitemap,
  search,
  settings,
  vimeo,
  userProperties,
  importantMessage,
  member,
  person
});
