import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import NavBar from "../NavBar";
import "./nav.scss";

export default class Nav extends React.Component {
  static menuActions = NavBar.menuActions;
  static LINK_INTERNAL = NavBar.LINK_INTERNAL;

  render() {
    const { onLogout, onOpenMenu, menuOpen, megaMenu, mobileMenuAdditions, className, fixed, shadow, location, onRouteNavigate, routes, user, ...other } =
      this.props;
    const classNames = classnames("bwl-nav", { "bwl-nav--fixed": fixed, "bwl-nav--shadow": shadow }, className);
    return (
      <nav className={classNames}>
        <NavBar
          {...other}
          location={location}
          items={megaMenu}
          additionalItems={mobileMenuAdditions}
          onLogout={onLogout}
          onOpenMenu={onOpenMenu}
          menuOpen={menuOpen}
          onRouteNavigate={onRouteNavigate}
          routes={routes}
          user={user}
        />
      </nav>
    );
  }
}

Nav.propTypes = {
  /** Callback to pass to NavBar */
  onOpenMenu: PropTypes.func.isRequired,
  /** Displays the menu if true */
  menuOpen: PropTypes.bool.isRequired,
  /** Array of "LinkCollection data type" */
  megaMenu: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      titleLink: PropTypes.shape({
        url: PropTypes.string,
        type: PropTypes.number,
      }),
      collectionItems: PropTypes.arrayOf(
        PropTypes.shape({
          link: PropTypes.shape({ url: PropTypes.string, type: PropTypes.number }),
          text: PropTypes.string,
        })
      ),
    })
  ),
  location: PropTypes.shape({ pathname: PropTypes.string }),
  /** For adding aditional classname(s) */
  className: PropTypes.string,
  /** Callback to pass to NavBar */
  onLogout: PropTypes.func.isRequired,
  /** Positioning is fixed (to prevent rubberband effect when menu is open) */
  fixed: PropTypes.bool,
  /** Display box shadow under nav */
  shadow: PropTypes.bool,
};
