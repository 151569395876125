import React from "react";
import { get, getPropValue, getUniqueIdForContentTypeAlias } from "../logic/contentLogic";

const LinkCollectionCard = React.lazy(() => import("../bob-web-library/components/LinkCollectionCard"));

export const renderLinkCollectionNestedContent = (content, props = {}) => {
  const linksWithDefaultIcon = getPropValue(content, "links").map(link => ({
    link,
    icon: "arrowNav",
    text: link.name,
  }));

  return (
    <LinkCollectionCard
      key={getUniqueIdForContentTypeAlias(get(content, "contentTypeAlias", "simple-link-collection"))}
      title={getPropValue(content, "title")}
      items={linksWithDefaultIcon}
    />
  );
};
