import React, { PureComponent } from "react";
import lazyRoute from "./lazyRoute";
import loadPreviewContent from "./loadPreviewContent";

const dynamicPreviewContentRoute = (PreviewPage, PageNotFound) => {
  return lazyRoute(
    loadPreviewContent(
      class dynamicPreviewContentRoute extends PureComponent {
        render() {
          const { previewContent } = this.props;
          return previewContent === null ? <PageNotFound /> : <PreviewPage {...this.props} />;
        }
      }
    )
  );
};

export default dynamicPreviewContentRoute;
