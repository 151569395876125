import { createOutlined, createStyledIcon } from "./icon.styles";
import AddSVG from "./icons/add.svg";
import AltHomeSVG from "./icons/althome.svg";
import BellSVG from "./icons/bell.svg";
import BinderSVG from "./icons/binder.svg";
import BoardPortalSVG from "./icons/boardportal.svg";
import CalendarSVG from "./icons/calendar.svg";
import CheckSVG from "./icons/check.svg";
import ChevronSVG from "./icons/chevron.svg";
import CloseSVG from "./icons/close.svg";
import CoffeeSVG from "./icons/coffee.svg";
import DocumentSVG from "./icons/document.svg";
import ExclaimSVG from "./icons/exclaim.svg";
import EyeSVG from "./icons/eye.svg";
import EyeClosedSVG from "./icons/eyeClosed.svg";
import FindHomeSVG from "./icons/findhome.svg";
import GratzSVG from "./icons/gratz.svg";
import HeartFilledSVG from "./icons/heart-filled.svg";
import HeartOutlinedSVG from "./icons/heart-outline.svg";
import HomeSVG from "./icons/home.svg";
import HomeNavSVG from "./icons/homenav.svg";
import HouseSVG from "./icons/house.svg";
import LocationSVG from "./icons/location.svg";
import BobHorizontalLogoSVG from "./icons/logoBobHorizontal.svg";
import BobSquaresLogoSVG from "./icons/logoBobSquares.svg";
import MegaphoneSVG from "./icons/megaphone.svg";
import MemberCardSVG from "./icons/membercard.svg";
import PiggyBankSVG from "./icons/piggybank.svg";
import PrebuySVG from "./icons/prebuy.svg";
import ProfileSVG from "./icons/profile.svg";
import QuestionSVG from "./icons/question.svg";
import RecentSVG from "./icons/recent.svg";
import SaleHouseSVG from "./icons/salehouse.svg";
import SaleSignSVG from "./icons/salesign.svg";
import SearchSVG from "./icons/search.svg";
import SignatureSVG from "./icons/signature.svg";
import SlidersSVG from "./icons/sliders.svg";
import SpeechBubbleSVG from "./icons/speechbubble.svg";
import TrashSVG from "./icons/trash.svg";
import WarningSVG from "./icons/warning.svg";

const TestIng = () => <ChevronSVG />;
const Add = createStyledIcon(AddSVG);
const AltHome = createStyledIcon(AltHomeSVG);
const Bell = createStyledIcon(BellSVG);
const Binder = createStyledIcon(BinderSVG);
const BellOutlined = createOutlined(Bell);
const BoardPortal = createStyledIcon(BoardPortalSVG);
const BoardPortalOutlined = createOutlined(BoardPortal);
const BobHorizontalLogo = createStyledIcon(BobHorizontalLogoSVG);
const BobSquaresLogo = createStyledIcon(BobSquaresLogoSVG);
const Calendar = createStyledIcon(CalendarSVG);
const CalendarOutlined = createOutlined(Calendar);
const Check = createStyledIcon(CheckSVG);
const Chevron = createStyledIcon(ChevronSVG);
const Close = createStyledIcon(CloseSVG);
const CloseOutlined = createOutlined(Close);
const Coffee = createStyledIcon(CoffeeSVG);
const Document = createStyledIcon(DocumentSVG);
const DocumentOutlined = createOutlined(Document);
const Exclaim = createStyledIcon(ExclaimSVG);
const Eye = createStyledIcon(EyeSVG);
const EyeClosedOutlined = createStyledIcon(EyeClosedSVG);
const EyeOutlined = createOutlined(Eye);
const FindHome = createStyledIcon(FindHomeSVG);
const FindHomeOutlined = createOutlined(FindHome);
const Gratz = createStyledIcon(GratzSVG);
const GratzOutlined = createOutlined(Gratz);
const Heart = createStyledIcon(HeartFilledSVG);
const HeartOutlined = createStyledIcon(HeartOutlinedSVG);
const Home = createStyledIcon(HomeSVG);
const HomeNav = createStyledIcon(HomeNavSVG);
const HomeOutlined = createOutlined(Home);
const House = createStyledIcon(HouseSVG);
const HouseOutlined = createOutlined(House);
const Location = createStyledIcon(LocationSVG);
const LocationOutlined = createOutlined(Location);
const Megaphone = createStyledIcon(MegaphoneSVG);
const MegaphoneOutlined = createOutlined(Megaphone);
const MemberCard = createStyledIcon(MemberCardSVG);
const MemberCardOutlined = createOutlined(MemberCard);
const PiggyBank = createStyledIcon(PiggyBankSVG);
const PiggyBankOutlined = createOutlined(PiggyBank);
const Prebuy = createStyledIcon(PrebuySVG);
const PrebuyOutlined = createOutlined(Prebuy);
const Profile = createStyledIcon(ProfileSVG);
const ProfileOutlined = createOutlined(Profile);
const Question = createStyledIcon(QuestionSVG);
const Recent = createStyledIcon(RecentSVG);
const RecentOutlined = createOutlined(Recent);
const SaleHouse = createStyledIcon(SaleHouseSVG);
const SaleHouseOutlined = createOutlined(SaleHouse);
const SaleSign = createStyledIcon(SaleSignSVG);
const SaleSignOutlined = createOutlined(SaleSign);
const Search = createStyledIcon(SearchSVG);
const SearchOutlined = createOutlined(Search);
const Signature = createStyledIcon(SignatureSVG);
const SignatureOutlined = createOutlined(Signature);
const Sliders = createStyledIcon(SlidersSVG);
const SlidersOutlined = createOutlined(Sliders);
const SpeechBubble = createStyledIcon(SpeechBubbleSVG);
const Trash = createStyledIcon(TrashSVG);
const TrashOutlined = createOutlined(Trash);
const Warning = createStyledIcon(WarningSVG);

export default {
  Add,
  AltHome,
  Bell,
  Binder,
  BellOutlined,
  BoardPortal,
  BoardPortalOutlined,
  BobHorizontalLogo,
  BobSquaresLogo,
  Calendar,
  CalendarOutlined,
  Check,
  Chevron,
  Close,
  CloseOutlined,
  Coffee,
  Document,
  DocumentOutlined,
  Exclaim,
  Eye,
  EyeClosedOutlined,
  EyeOutlined,
  FindHome,
  FindHomeOutlined,
  Gratz,
  GratzOutlined,
  Heart,
  HeartOutlined,
  Home,
  HomeNav,
  HomeOutlined,
  House,
  HouseOutlined,
  Location,
  LocationOutlined,
  Megaphone,
  MegaphoneOutlined,
  MemberCard,
  MemberCardOutlined,
  PiggyBank,
  PiggyBankOutlined,
  Prebuy,
  PrebuyOutlined,
  Profile,
  ProfileOutlined,
  Question,
  Recent,
  RecentOutlined,
  SaleHouse,
  SaleHouseOutlined,
  SaleSign,
  SaleSignOutlined,
  Search,
  SearchOutlined,
  Signature,
  SignatureOutlined,
  Sliders,
  SlidersOutlined,
  SpeechBubble,
  Trash,
  TrashOutlined,
  Warning,
} as const;
