import ContentCard from "../bob-web-library/components/ContentCard";
import { contentTypeAliasAsCssClass, get, getUniqueIdForContentTypeAlias, tryGetLinkPropValue } from "../logic/contentLogic";

export const renderCardNestedContent = (content, props = {}) => {
  const link = tryGetLinkPropValue(content, "link", content.contentTypeAlias);

  return (
    link && (
      <ContentCard
        key={getUniqueIdForContentTypeAlias(content.contentTypeAlias)}
        className={contentTypeAliasAsCssClass(content.contentTypeAlias)}
        tag={get(content, "props.tag.value")}
        title={get(content, "props.title.value")}
        text={get(content, "props.text.value")}
        link={link}
        linkText={link.name}
        highlighted={get(content, "props.highlighted.value", false)}
      />
    )
  );
};
