import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Arrow, Card, Heading, LinkWrapper } from "../";
import Image from "../Image";
import { Text } from "../Typography";
import { withFocusState } from "../WithFocusState";
import "./link-card.scss";

/** Simple card containing title and arrow. Can be highlighted and opened in a new window. */
const LinkCard = ({ title, className, link, highlighted, tag, isFocused, image, ...rest }) => {
  const classNames = classnames("bwl-link-card", className, {
    "bwl-link-card--highlighted": highlighted
  });
  return (
    <div className="bwl-link-card__wrapper">
      <LinkWrapper link={link}>
        <div className={classNames} {...rest}>
          <Card animate backgroundColor="white" className="bwl-link-card__card-item">
            <div className="bwl-link-card__content">
              {image && image.fallback && (
                <div className="bwl-link-card__image">
                  <Image image={image} className="bwl-link-card__image"/>
                </div>
              )}
              {tag && (
                <Text variant="titleTag" className="bwl-link-card__tag">
                  {tag}
                </Text>
              )}
              {title && (
                <Heading variant="h4" className="bwl-link-card__title">
                  {title}
                </Heading>
              )}
              <div className="bwl-link-card__icon">
                <Arrow isFocused={isFocused} isFixedWidth variant="long" />
              </div>
            </div>
          </Card>
        </div>
      </LinkWrapper>
    </div>
  );
};

LinkCard.propTypes = {
  /**the LinkCard title */
  title: PropTypes.string.isRequired,
  /** The link to be rendered */
  link: PropTypes.shape({
    target: PropTypes.string,
    type: PropTypes.number,
    url: PropTypes.string.isRequired
  }).isRequired,
  /**the LinkCard title */
  tag: PropTypes.string,
  /**if the LinkCard should be highlighted */
  highlighted: PropTypes.bool,
  /** The LinkCard image  */
  image: PropTypes.shape({
    fallback: PropTypes.string.isRequired,
    webp: PropTypes.string.isRequired
  })
};

export default withFocusState(LinkCard);
