import Featured from "../bob-web-library/components/Featured";
import { getPropValue } from "../logic/contentLogic";
import { renderContent } from "./contentFactory";

export const renderFeaturedContent = (content, props = {}) => {
  const items = getPropValue(content, "featuredContentItems");
  const url = getPropValue(content, "featuredContentUrl");
  const linkText = getPropValue(content, "featuredContentUrl", "name");

  return (
    items &&
    items.length > 0 && (
      <Featured
        key={content.id}
        title={getPropValue(content, "featuredContentTitle")}
        text={getPropValue(content, "featuredContentText")}
        link={url}
        linkText={linkText}
        items={items.map(item => renderContent(item))}
        imageVerticalOverlap
      />
    )
  );
};
