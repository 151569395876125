import { renderAnchorWrapperContent } from "./anchorWrapperContentFactory";
import { renderAnimationNestedContent } from "./animationNestedContentFactory";
import { renderArticleContent } from "./articleContentFactory";
import { renderArticleSectionContent } from "./articleSectionContentFactory";
import { renderButtonNestedContent } from "./buttonNestedContentFactory";
import { renderCampaignContent } from "./campaignContentFactory";
import { renderCaptionImage } from "./captionImageFactory";
import { renderCardNestedContent } from "./cardNestedContentFactory";
import { renderContactRow } from "./contactRowFactory";
import { renderFeaturedContentCardNestedContent } from "./featuredContentCardNestedContentFactory";
import { renderFeaturedContent } from "./featuredContentFactory";
import { renderFeaturedItemNestedContent } from "./featuredItemNestedContentFactory";
import { renderFormNestedContent } from "./formNestedContentFactory";
import { helmetFactory } from "./helmetFactory";
import { renderHomeContent } from "./homeContentFactory";
import { renderInfoCardNestedContent } from "./infoCardNestedContentFactory";
import { renderInfoContent } from "./infoContentFactory";
import { renderInformationContent } from "./informationContentFactory";
import { renderLinkCardNestedContent } from "./linkCardNestedContentFactory";
import { renderLinkCollectionNestedContent } from "./linkCollectionNestedContentFactory";
import { renderMagazineContent } from "./magazineContentFactory";
import { renderFullscreenHeader } from "./magazineFactories/fullscreenHeaderFactory";
import { renderFullscreenImage } from "./magazineFactories/fullscreenImageFactory";
import { renderFullscreenText } from "./magazineFactories/fullscreenTextFactory";
import { renderFullscreenVideo } from "./magazineFactories/fullscreenVideoFactory";
import { renderHorizontalImageScroller } from "./magazineFactories/horizontalImageScrollerFactory";
import { renderMapNestedContent } from "./mapNestedContentFactory";
import { renderNestedIFrame } from "./nestedIFrameContentFactory";
import { renderNewMemberIFrame } from "./newMemberIFrameContentFactory";
import { renderNewsArchiveContent } from "./newsArchiveContentFactory";
import { renderNewsContent } from "./newsContentFactory";
import { renderQuoteNestedContent } from "./quoteNestedContentFactory";
import { renderSingleFeaturedItemContent } from "./renderSingleFeaturedItemContent";
import { renderReusableNestedContent } from "./reusableNestedContentFactory";
import { renderRichTextEditorNestedContent } from "./richTextEditorNestedContentFactory";
import { renderRowNestedContent } from "./rowNestedContentFactory";
import { renderTopicContent } from "./topicContentFactory";
import { renderVideoNestedContent } from "./videoNestedContentFactory";
import { renderYouTubeVideoNestedContent } from "./youTubeVideoNestedContent";

const factory = {
  anchorContainer: (content, props) => renderAnchorWrapperContent(content, props),
  article: (content, props) => renderArticleContent(content, props),
  articleSection: (content, props) => renderArticleSectionContent(content, props),
  buttonNestedContent: (content, props) => renderButtonNestedContent(content, props),
  cardNestedContent: (content, props) => renderCardNestedContent(content, props),
  contactRow: (content, props) => renderContactRow(content, props),
  featuredContentCardNestedContent: (content, props) => renderFeaturedContentCardNestedContent(content, props),
  featuredContentComponent: (content, props) => renderFeaturedContent(content, props),
  featuredItemNestedContent: (content, props) => renderFeaturedItemNestedContent(content, props),
  formNestedContent: (content, props) => renderFormNestedContent(content, props),
  singleFeaturedItem: (content, props) => renderSingleFeaturedItemContent(content, props),
  home: (content, props) => renderHomeContent(content, props),
  info: (content, props) => renderInfoContent(content, props),
  informationBlockNestedContent: (content, props) => renderInfoCardNestedContent(content, props),
  linkCardNestedContent: (content, props) => renderLinkCardNestedContent(content, props),
  linkCollectionNestedContent: (content, props) => renderLinkCollectionNestedContent(content, props),
  newMemberIFrame: (content, props) => renderNewMemberIFrame(content, props),
  news: (content, props) => renderNewsContent(content, props),
  newsArchive: (content, props) => renderNewsArchiveContent(content, props),
  quoteNestedContent: (content, props) => renderQuoteNestedContent(content, props),
  reusableNestedContent: (content, props) => renderReusableNestedContent(content, props),
  richTextEditorNestedContent: (content, props) => renderRichTextEditorNestedContent(content, props),
  iFrameNestedContent: (content, props) => renderNestedIFrame(content, props),
  rowNestedContent: (content, props) => renderRowNestedContent(content, props),
  topic: (content, props) => renderTopicContent(content, props),
  videoNestedComponent: (content, props) => renderVideoNestedContent(content, props),
  youTubeVideoNestedContent: (content, props) => renderYouTubeVideoNestedContent(content, props),
  animationNestedContent: (content, props) => renderAnimationNestedContent(content, props),
  imageNestedContent: (content, props) => renderCaptionImage(content, props),
  information: (content, props) => renderInformationContent(content, props),
  campaign: (content, props) => renderCampaignContent(content, props),
  map: (content, props) => renderMapNestedContent(content, props),
  magazine: (content, props) => renderMagazineContent(content, props),
  fullScreenHeader: (content, props) => renderFullscreenHeader(content, props),
  fullScreenText: (content, props) => renderFullscreenText(content, props),
  horizontalScrollImages: (content, props) => renderHorizontalImageScroller(content, props),
  fullScreenImage: (content, props) => renderFullscreenImage(content, props),
  fullScreenVideo: (content, props) => renderFullscreenVideo(content, props)
};

export const hasFactoryFor = contentTypeAlias => contentTypeAlias in factory;
export const renderContent = (content, props = {}) => {
  let propsWithHelmet = props;
  if (["home", "info", "news", "newsArchive", "topic", "campaign", "magazine"].includes(content && content.contentTypeAlias)) {
    propsWithHelmet = { ...props, helmet: () => helmetFactory(content, props) };
  }
  if (hasFactoryFor(content.contentTypeAlias)) {
    return factory[content.contentTypeAlias](content, propsWithHelmet);
  }

  console.warn(`No factory found for content type alias ${content.contentTypeAlias}`, content);
  return null;
};
