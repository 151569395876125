import { AUTH_PROXY_URL } from "../constants/forgeRockConstants";

export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAILED = "AUTH_FAILED";
export const AUTH_STARTED = "AUTH_STARTED";
export function authStarted() {
  return {
    type: AUTH_STARTED
  };
}

export function authSuccess(json) {
  return {
    type: AUTH_SUCCESS,
    json,
    receivedAt: Date.now()
  };
}

export function authFailed(error) {
  return {
    type: AUTH_FAILED,
    error: error
  };
}

// also:
// POST /api/auth/user/properties
// POST /api/auth/user/logout

export const authService = (user, pass) => {
  const devUrl = `${AUTH_PROXY_URL}user`;
  return dispatch => {
    dispatch(authStarted());
    return fetch(devUrl, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        username: user,
        password: pass
      })
    })
      .then(response => {
        if (!response.ok) throw response;
        return response.json();
      })
      .then(json => dispatch(authSuccess(json)))
      .catch(error => dispatch(authFailed(error)));
  };
};
