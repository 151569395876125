import React, { PureComponent } from "react";
import umbracoService from "../services/umbracoService";
import { decode } from "../utils/queryStringHelper";

const loadPreviewContent = WrappedComponent => {
  return class loadPreviewContent extends PureComponent {
    constructor(props) {
      super(props);
      this.state = {
        previewContent: null
      };
    }

    shouldFetchContent = () => {
      const { location } = this.props;
      const queryParams = location && location.search && decode(location.search.substr(1));
      return queryParams && queryParams.id && this.state.previewContent === null;
    };

    componentDidMount() {
      this.fetchContent();
    }

    componentDidUpdate() {
      this.fetchContent();
    }

    async fetchContent() {
      const { location } = this.props;
      const queryParams = decode(location.search.substr(1));
      const id = queryParams.id;
      if (this.shouldFetchContent()) {
        this.setState({
          previewContent: await umbracoService.getPublishedPreviewContentById(id)
        });
      }
    }

    render() {
      const { previewContent } = this.state;

      return <WrappedComponent {...this.props} previewContent={previewContent} />;
    }
  };
};

export default loadPreviewContent;
