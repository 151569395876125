import React from "react";
import { getContentPageProps } from "../logic/contentLogic";

const Home = React.lazy(() => import("../components/Home"));

export const renderHomeContent = (content, props = {}) => {
  const Helmet = props.helmet;

  return (
    <>
      <Helmet />
      <Home key={content.id} content={content} {...getContentPageProps(content)} />
    </>
  );
};
