import PropTypes from "prop-types";
import React from "react";
import Button from "../Button";
import LinkWrapper from "../LinkWrapper";
import { withFocusState } from "../WithFocusState";

const LinkButton = ({ link, withProgress, onClick, ...rest }) => {
  return (
    <LinkWrapper link={link} disabled={rest.disabled}>
      <Button
        onClick={() => {
          /** onClick is required, but we dont want it */
        }}
        {...rest}
      />
    </LinkWrapper>
  );
};

LinkButton.propTypes = {
  /** The link to be rendered */
  link: PropTypes.shape({
    target: PropTypes.string,
    type: PropTypes.number,
    url: PropTypes.string.isRequired
  }).isRequired,
  /** Ignored */
  onClick: PropTypes.func
};

export default withFocusState(LinkButton);
