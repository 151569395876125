import { SEARCH_FETCH_FAILED, SEARCH_FETCH_STARTED, SEARCH_FETCH_SUCCESS, SEARCH_RESET } from "../actions/searchAction";
import { setFetchFailed, setFetchIsFetching, setFetchSuccess, setFetchUninitiated } from "../loadingStates";
import { get } from "../logic/contentLogic";

const getDate = (article) => {
  const aDate = new Date(article.props.dateOverride?.value ?? null);
  return (aDate.getFullYear() <= 1970) ? new Date(Date.parse(article.createDate)) : aDate;
}

const initialState = {
  search: {
    pagedQuery: {},
    result: [],
    ...setFetchUninitiated()
  },
  news: {
    result: [],
    ...setFetchUninitiated()
  },
  // append more type searches as they come, theme, person ++
  ...setFetchUninitiated()
};
const emptyResult = {
  page: 0,
  pageSize: 0,
  totalPages: 0,
  totalResults: 0
};
const search = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_FETCH_STARTED:
      return {
        ...state,
        ...setFetchIsFetching(),
        [action.referer]: {
          ...state[action.referer],
          ...setFetchIsFetching()
        }
      };
    case SEARCH_FETCH_SUCCESS:
      return {
        ...state,
        ...setFetchSuccess(),
        [action.referer]: {
          ...setFetchSuccess(),
          // If we are receiving news, parse and sort the list on date newest first
          result:
            action.referer === "news"
              ? get(action.result, "_embedded.content", []).sort((a, b) => {
                return getDate(b) - getDate(a);
              })
              : action.result,
          pagedQuery: {
            ...state[action.referer].pagedQuery,
            ...action.pagedQuery
          }
        }
      };
    case SEARCH_FETCH_FAILED:
      return {
        ...state,
        ...setFetchFailed(action.error),
        [action.referer]: {
          ...setFetchFailed(action.error),
          result: emptyResult,
          pagedQuery: {
            ...state[action.referer].pagedQuery,
            ...action.pagedQuery
          }
        }
      };
    case SEARCH_RESET:
      return {
        ...state,
        [action.referer]: {
          ...initialState
        }
      };
    default:
      return state;
  }
};

export default search;
