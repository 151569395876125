import { CaptionImage } from "../bob-web-library/components";
import { CROP_SIZE } from "../constants/imageSizeConstants";
import { get, getImageCropUrl, getPropValue, getUniqueIdForContentTypeAlias } from "../logic/contentLogic";

export const renderCaptionImage = (content, props = {}) => {
  const image = getPropValue(content, "imageLink");
  const noCompression = getPropValue(content, "fullResImage") ?? false;
  const crop = noCompression ? CROP_SIZE.UNCOMPRESSED : CROP_SIZE.XLARGE;
  const caption = getPropValue(content, "caption");

  return (
    <CaptionImage
      key={getUniqueIdForContentTypeAlias(get(content, "contentTypeAlias", "caption-image"))}
      image={getImageCropUrl(image, crop, "jpg")}
      caption={caption}
    />
  );
};
