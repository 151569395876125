import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import Arrow from "../Arrow";
import Button from "../Button";
import Card from "../Card";
import LinkWrapper from "../LinkWrapper";
import { Heading, Text } from "../Typography";
import { withFocusState } from "../WithFocusState";
import "./contentCard.scss";

/** A card using content.
 * See storybook for variants.
 */
const ContentCard = props => {
  const { tag, title, text, link, linkText, highlighted, className, isFocused, smallerHeading, ...rest } = props;

  const renderLink = () => {
    return (
      <div className="bwl-content-card__link-container">
        {highlighted && link && linkText ? (
          <Button variant="text-light" isFocused={isFocused} text={linkText} onClick={() => {}} />
        ) : (
          link && <Arrow isFocused={isFocused} variant="long" />
        )}
      </div>
    );
  };

  return (
    <div
      className={classnames(className, "bwl-content-card", {
        "bwl-content-card--highlight": highlighted,
      })}
      {...rest}
    >
      <LinkWrapper link={link}>
        <Card animate backgroundColor={highlighted ? "purple" : "white"}>
          {tag && (
            <Text variant="titleTag" className="bwl-content-card__tag">
              {tag}
            </Text>
          )}
          {title && (
            <Heading variant={smallerHeading ? "h4" : "h3"} className="bwl-content-card__title">
              {title}
            </Heading>
          )}
          {text && (
            <Text variant="paragraph" className="bwl-content-card__text">
              {text}
            </Text>
          )}
          {renderLink()}
        </Card>
      </LinkWrapper>
    </div>
  );
};

ContentCard.propTypes = {
  /** Tag for component */
  tag: PropTypes.string,
  /** Title for component */
  title: PropTypes.string.isRequired,
  /** Text for component */
  text: PropTypes.string.isRequired,
  /** Link for component */
  link: PropTypes.shape({
    target: PropTypes.string,
    type: PropTypes.number,
    url: PropTypes.string.isRequired,
  }),
  /** Link text for component */
  linkText: PropTypes.string,
  /** Should component render as highlighted */
  highlighted: PropTypes.bool,
  /** Background color for component */
  className: PropTypes.string,
};

export default withFocusState(ContentCard);
