import { forwardRef, useMemo } from "react";
import Icon from "./Icon";
import { withDefaultTheme } from "./ThemeProvider";
import { LIBRARY_NAME } from "./constants";
import Checkbox from "./input/Checkbox";
import PredefinedMask from "./input/PredefinedMask";
import Radio from "./input/Radio";
import { getInputComponent, getMaskProps } from "./input/helpers";
import { Adornment, Container, InputContainer, InputError, InputLabel } from "./input/input.styled";
import { IProps } from "./input/input.types";

const NAME = `${LIBRARY_NAME}__input`;

const Input = forwardRef<HTMLInputElement, IProps>((props, ref) => {
  const { error, label, className, id, disabled, type, mask, iconName, stickLabel, ...rest } = props;

  const inputId = id || `${NAME}--id-${btoa(JSON.stringify(props))}`;
  const inputProps = {
    ref,
    type,
    iconName,
    id: inputId,
    disabled,
    required: true,
    error,
    ...rest,
  };
  const InputComponent = useMemo(() => getInputComponent({ mask }), [mask]);
  if (type === "radio") return <Radio className={className} label={label} {...inputProps} />;
  if (type === "checkbox") return <Checkbox className={className} label={label} {...inputProps} />;
  const maskProps = mask ? getMaskProps({ mask }) : {};
  return (
    <Container className={className}>
      <InputContainer>
        <InputComponent {...inputProps} {...maskProps} />
        <InputLabel stickLabel={stickLabel} color="coal" component="label" size="medium" fontWeight="regular" htmlFor={inputId}>
          {label || ""}
        </InputLabel>
        {iconName && (
          <Adornment>
            <Icon name={iconName} size="medium" />
          </Adornment>
        )}
      </InputContainer>
      {error && <InputError>{error}</InputError>}
    </Container>
  );
});

export const PREDEFINED_MASK = Object.keys(PredefinedMask).reduce((acc, key) => ({ ...acc, [key]: key }), {
  emailPhone: undefined,
});

export default withDefaultTheme(Input);
