import { Helmet } from "react-helmet";
import { CROP_SIZE } from "../constants/imageSizeConstants";
import { getContentPageProps, getImageCropUrl } from "../logic/contentLogic";

export const helmetFactory = (content, props) => {
  const contentPageProps = getContentPageProps(content);
  const { pathname } = props.location;

  const defaults = {
    openGraphType: content.contentTypeAlias === "home" ? "website" : "article",
    facebookAppId: "484944185032644",
    locale: "nb_NO",
    openGraphImage: contentPageProps.headerImage,
    siteName: "BOB",
  };

  const attr = {
    defaultTitle: defaults.siteName,
  };

  const meta = {};
  meta.title = contentPageProps.metaTitle || contentPageProps.headerTitle;
  meta.description = contentPageProps.metaDescription || contentPageProps.headerText;
  meta.canonicalUrl = contentPageProps.canonicalUrl || `https://bob.no${pathname}`;

  //detect if title is in arabic
  const isArabic = meta.title.match(/[\u0600-\u06FF]/);

  const htmlAttr = {
    lang: isArabic ? "ar" : "no",
    prefix: "og: http://ogp.me/ns#",
    dir: isArabic ? "rtl" : "ltr",
  };

  const fb = {};
  fb.appID = defaults.facebookAppId;

  const og = {};
  og.title = contentPageProps.openGraphTitle || meta.title;
  og.description = contentPageProps.openGraphDescription || meta.description;
  og.type = contentPageProps.openGraphType || defaults.openGraphType;
  og.image = contentPageProps.openGraphImage || defaults.openGraphImage;
  og.url = contentPageProps.openGraphUrl || meta.canonicalUrl;
  const hostName = window.location.hostname;
  const noIndex = hostName.includes("dev");

  return (
    <Helmet defer={false} {...attr} key={content.id} content={content} {...contentPageProps}>
      <html {...htmlAttr} />
      <title lang={isArabic ? "ar" : "no"}>{meta.title}</title>
      <meta name="description" content={meta.description} />
      {!noIndex && <link rel="canonical" href={meta.canonicalUrl} />}
      <meta property="fb:app_id" content={fb.appID} />
      <meta property="og:title" content={og.title} />
      <meta property="og:description" content={og.description} />
      <meta property="og:url" content={og.url} />
      <meta property="og:type" content={og.type} />
      <meta property="og:image" content={getImageCropUrl(og.image, CROP_SIZE.THUMBNAIL).fallback} />
      <meta property="og:locale" content={defaults.locale} />
      <meta property="og:site_name" content={defaults.siteName} />
      {noIndex && <meta name="robots" content="noindex"></meta>}
    </Helmet>
  );
};
