import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Heading, Text } from "../../../";
import { GridColumn, GridRow } from "../../../Grid";
import { MaxWidthWrapper } from "../../util/MaxWidthWrapper";
import "./infoHeader.scss";

class InfoHeader extends React.PureComponent {
  render() {
    const { className, title, text } = this.props;
    const headerClasses = classnames(className, "bwl-info-header");

    return (
      <>
        <div className={headerClasses}>
          <header className="bwl-info-header__header">
            <MaxWidthWrapper>
              <GridRow>
                <GridColumn span={12}>
                  {title && (
                    <div className="bwl-info-header__title-wrapper">
                      <Heading className="bwl-info-header__title" variant="h1">
                        {title}
                      </Heading>
                    </div>
                  )}
                </GridColumn>
              </GridRow>
            </MaxWidthWrapper>
          </header>
        </div>
        {text && (
          <MaxWidthWrapper>
            <GridRow>
              <GridColumn className="bwl-info-header__lead-column" span={8} offset={3}>
                <Text variant="lead" className="bwl-info-header__text">
                  {text}
                </Text>
              </GridColumn>
            </GridRow>
          </MaxWidthWrapper>
        )}
      </>
    );
  }
}

InfoHeader.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string
};

export default InfoHeader;
