import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import Image from "../../../Image";
import "./gridRow.scss";

const GridRow = props => {
  const {
    children,
    backgroundPosition,
    backgroundSpan,
    backgroundSpanMobile,
    backgroundImage,
    noMargin,
    noRowGap,
    noColumnGap,
    className,
    alignItem,
    ...rest
  } = props;

  const alignItemVariants = {
    start: "start",
    end: "end",
    center: "center"
  };

  return (
    <div
      className={classnames(className, "bwl-grid-row", {
        "bwl-grid-row--no-margin": noMargin,
        "bwl-grid-row--no-row-gap": noRowGap,
        "bwl-grid-row--no-column-gap": noColumnGap,
        "bwl-grid-row--with-background-image": !!(backgroundImage && backgroundImage.fallback),
        [`bwl-grid-row--background-span-${backgroundSpan}`]: !!(backgroundImage && backgroundImage.fallback),
        [`bwl-grid-row--align-item-${alignItemVariants[alignItem]}`]: !!alignItem
      })}
      {...rest}>
      {!!backgroundImage && (
        <div
          className={classnames("bwl-grid-row__background", [
            `bwl-grid-row__background--span-${backgroundSpan}`,
            `bwl-grid-row__background--span-mobile-${backgroundSpanMobile}`
          ])}>
          <Image image={backgroundImage} />
        </div>
      )}
      {children}
    </div>
  );
};

GridRow.propTypes = {
  /** A number of "GridColumn"s */
  children: PropTypes.node.isRequired,
  /** Injected className */
  className: PropTypes.string,
  /** Background image  */
  backgroundPosition: PropTypes.oneOf(["left", "right"]),
  backgroundSpan: PropTypes.number,
  backgroundSpanMobile: PropTypes.number,
  backgroundImage: PropTypes.shape({
    fallback: PropTypes.string,
    webp: PropTypes.string.isRequired
  }),
  noMargin: PropTypes.bool,
  noRowGap: PropTypes.bool,
  noColumnGap: PropTypes.bool,
  /** Vertically align row items */
  alignItem: PropTypes.oneOf(["start", "end", "center"])
};

GridRow.defaultProps = {
  backgroundPosition: "right",
  backgroundSpan: 8,
  backgroundSpanMobile: 12
};

export default GridRow;
