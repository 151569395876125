import classnames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { v4 as uuid4 } from "uuid";
import IconButton from "../../../IconButton";
import { ThickArrowLeft, ThickArrowRight } from "../../../Icons";
import ScrollerPositionMarker from "../../../ScrollerPositionMarker";
import "./horizontalImageScroller.scss";

const HorizontalImageScroller = (props) => {

    // ************************************
    // Properties
    // ************************************

    const classPrefix = "bwl-HorizontalImageScroller";
    const desktopMinWidth = 720;
    const smallScreenWidth = 1024;
    const gridItemOffset = 20;

    const {
        theme,
        images,
        initialIndex
    } = props;

    let viewportId = `${classPrefix}__content__grid__viewport__${uuid4()}`;
    let containerId = `${classPrefix}__content__grid__viewport__container__${uuid4()}`;

    // ************************************
    // Events
    // ************************************

    const sizeChangeListeners = () => {
        window.removeEventListener('resize', updateSize);
        window.addEventListener('resize', updateSize);
    }

    // ************************************
    // Lifecycle
    // ************************************

    const [isDesktop, setIsDesktop] = useState(false);
    const [currentPosition, setCurrentPosition] = useState(initialIndex);
    const [gridItems, setGridItems] = useState([]);
    const [containerOffset, setContainerOffset] = useState(window.innerWidth < smallScreenWidth ? 100 : 200);

    useEffect(() => {
        setIsDesktop(window.innerWidth > desktopMinWidth);
        sizeChangeListeners();
    }, [initialIndex, images]);

    useEffect(() => {
        if (!isDesktop) {
            document.getElementById(containerId).style.transform = `translate(0, 0)`;
        } else {
            MoveCarousel(currentPosition);
        }
    }, [isDesktop])

    const updateSize = () => {
        if (window.innerWidth < smallScreenWidth) {
            setContainerOffset(100);
        } else {
            setContainerOffset(200);
        }

        setIsDesktop(window.innerWidth > desktopMinWidth);
        UpdateCarousel(currentPosition);
    }

    // ************************************
    // Carousel Functionality
    // ************************************

    const MoveCarousel = (index) => {
        if (index < 0) {
            index = images.length - 1;
        } else if (index > images.length - 1) {
            index = 0;
        }

        UpdatePositionMarker({ index });
        UpdateCarousel(index);
    }

    const UpdatePositionMarker = ({ target, index = 0 }) => {
        if (isDesktop) {
            setCurrentPosition(index);
        } else {
            if (target) {
                let xPos = target.scrollLeft;
                let children = target.childNodes;

                if (xPos && children) {
                    let newPos = 0;

                    for (let i = 0; i < children.length; i++) {
                        let childData = children[i].getBoundingClientRect();
                        let cWidth = childData.width;

                        // If at start
                        if (xPos < (cWidth / 2)) {
                            newPos = 0;
                            // If at end
                        } else if (xPos < (cWidth * children.length - 1) && xPos > ((cWidth * children.length - 1) - cWidth)) {
                            newPos = children.length - 1;
                            // If at center
                        } else {
                            if (xPos < cWidth * (i + 1) && xPos > ((cWidth * (i + 1)) - cWidth)) {
                                newPos = i;
                            }
                        }
                    }

                    if (currentPosition !== newPos) {
                        setCurrentPosition(newPos);
                    }
                }
            }
        }
    }

    const UpdateCarousel = (index) => {
        let container = document.getElementById(containerId);
        let viewport = document.getElementById(viewportId);

        if (isDesktop) {
            if (gridItems && container && viewport) {
                let focusedComponent;
                let focusedElement;
                let totalWidth = 0;

                gridItems.forEach((item) => {
                    if (item.element) {
                        // Need to calibrate the total width here because the container does not
                        // give the correct information
                        // gridItemOffset is margin offset
                        totalWidth += (document.getElementById(item.id).getBoundingClientRect().width + gridItemOffset);

                        if (item.index === index) {
                            focusedComponent = item;
                            focusedElement = document.getElementById(item.id);
                        }
                    }
                })

                if (focusedComponent && focusedElement) {
                    let x = 0;

                    let viewportData = viewport.getBoundingClientRect();
                    let childData = focusedElement.getBoundingClientRect();
                    let parentData = container.getBoundingClientRect();

                    let relativePos = {
                        top: childData.top - parentData.top,
                        right: childData.right - parentData.right,
                        bottom: childData.bottom - parentData.bottom,
                        left: childData.left - parentData.left
                    }

                    x = (viewportData.width / 2) - (relativePos.left + (childData.width / 2));
                    x = isNaN(x) ? 0 : x;

                    if (index === 0) {
                        x = containerOffset;
                    } else if (index === gridItems.length - 1) {
                        x = -(totalWidth - viewportData.width) - containerOffset;
                    }

                    container.style.transform = `translate(${x}px, 0)`;
                }
            }
        } else {
            if (container) {
                container.style.transform = `translate(${0}px, 0)`;
            }
        }
    }

    // ************************************
    // Render Functionality
    // ************************************

    const gridElements = useMemo(() => {
        let gridElems = [];
        let gridItems = [];

        images.forEach((item, index) => {
            let uuid = uuid4();
            let id = `${classPrefix}__grid__item__${uuid}`;

            let gridElem = <div
                id={id}
                className={`${classPrefix}__grid__item`}
                key={id}
            >
                <img
                    src={item.url}
                    className={`${classPrefix}__grid__item__image`}
                    onLoad={() => {
                        if (currentPosition === index) {
                            UpdateCarousel(currentPosition, !isDesktop);
                        }
                    }}
                />
            </div>

            gridElems.push(gridElem);
            gridItems.push({
                id: id,
                index: index,
                uuid: uuid,
                element: gridElem
            });
        });

        setGridItems(gridItems);

        return gridElems;
    }, [images]);

    // ************************************
    // Render Template
    // ************************************

    return (
        <div className={classnames(`${classPrefix}`, {
            [`${classPrefix}__background__${theme}`]: theme
        })}>
            <div className={`${classPrefix}__content`}>
                <div
                    id={viewportId}
                    className={`${classPrefix}__content__grid__viewport`}
                >
                    <div
                        id={containerId}
                        className={`${classPrefix}__content__grid__viewport__container`}
                        onScroll={(e) => {
                            UpdatePositionMarker({ target: e.target });
                        }}
                    >
                        {images && gridElements}
                    </div>
                </div>
                {isDesktop &&
                    <>
                        <div className={`${classPrefix}__content__arrow__left`}>
                            <IconButton
                                theme={theme}
                                onClick={() => {
                                    MoveCarousel(currentPosition - 1);
                                }}
                            >
                                <ThickArrowLeft
                                    className={classnames(`${classPrefix}__arrow__icon`, {
                                        [`${classPrefix}__icon__${theme}`]: theme
                                    })}
                                    width={25}
                                    height={25}
                                />
                            </IconButton>
                        </div>
                        <div className={`${classPrefix}__content__arrow__right`}>
                            <IconButton
                                theme={theme}
                                onClick={() => {
                                    MoveCarousel(currentPosition + 1);
                                }}
                            >
                                <ThickArrowRight
                                    className={classnames(`${classPrefix}__arrow__icon`, {
                                        [`${classPrefix}__icon__${theme}`]: theme
                                    })}
                                    width={25}
                                    height={25}
                                />
                            </IconButton>
                        </div>
                    </>
                }
            </div>
            <div className={`${classPrefix}__position-marker__container`}>
                <ScrollerPositionMarker
                    theme={theme}
                    alignment={'horizontal'}
                    current={currentPosition}
                    total={images.length}
                />
            </div>
        </div>
    );
}

// ************************************
// Validation
// ***********************************

HorizontalImageScroller.propTypes = {
    theme: PropTypes.oneOf(["light", "dark"]),
    images: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string
        })
    ).isRequired,
    initialIndex: PropTypes.number
}

HorizontalImageScroller.defaultProps = {
    theme: "light",
    initialIndex: 0
}

export default HorizontalImageScroller;