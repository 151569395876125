import Image from '../Image';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';
import Logo from '../assets/bob_logo_desktop.svg';
import LogoFooter from '../assets/bob_logo_footer_desktop.svg';
import LogoFooterMobile from '../assets/bob_logo_footer_mobile.svg';
import './logoContainer.scss';

export const LOGO_FOOTER = 'LOGO_FOOTER';
export const LOGO_FOOTER_MOBILE = 'LOGO_FOOTER_MOBILE';
export const getLogo = logoType => {
  switch (logoType) {
    case LOGO_FOOTER:
      return LogoFooter;
    case LOGO_FOOTER_MOBILE:
      return LogoFooterMobile;
    default:
      return Logo;
  }
};

class LogoContainer extends React.Component {
  render() {
    const {className, onClick, logoType, ...rest} = this.props;
    const logo = getLogo(logoType);
    const image = {webp: logo, fallback: logo, alt: 'BOB hjemmeside'};

    return (
      <div className={classnames('bwl-logo-container', className)} onClick={onClick} {...rest}>
          <Image image={image}/>
      </div>
    );
  }
}

LogoContainer.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  logoType: PropTypes.string
};

export default LogoContainer;
