import { useState } from "react";
import Form from "../../bob-web-library/components/Form";
import umbracoService from "../../services/umbracoService";

const NewsLetterContainer = props => {
  const { formGuid, ...rest } = props;
  const [visible, setVisible] = useState(true);
  const [error, setError] = useState(false);

  const signup = (formId, email) => {
    return umbracoService.signupForNewsletter(formId, email);
  };

  const postForm = value => {
    signup(formGuid, value).then(
      result => {
        setVisible(false);
      },
      error => {
        // console.log(error.text().then(r => console.warn(r)));
        setVisible(false);
        setError(true);
      }
    );
  };

  return visible ? (
    <Form postForm={value => postForm(value)} {...rest} />
  ) : error ? (
    <p style={{ color: "red" }}>Noe gikk galt</p>
  ) : (
    <p>Takk for at du meldte din interesse!</p>
  );
};

NewsLetterContainer.propTypes = {};

export default NewsLetterContainer;
