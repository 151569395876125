import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import LinkWrapper from "../../../LinkWrapper";
import "./fullscreenHeader.scss";

const FullscreenHeader = (props) => {

    // ************************************
    // Properties
    // ************************************

    const classPrefix = "bwl-FullscreenHeader";

    const {
        theme,
        title,
        description,
        dateText,
        link,
        backgroundUrl
    } = props;

    // ************************************
    // Render
    // ************************************

    return (
        <div
            className={`${classPrefix}`}
            style={{
                backgroundImage: `url(${backgroundUrl})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
            }}
        >
            <div className={classnames(`${classPrefix}-wrapper`, {
                [`${classPrefix}__background__${theme}__faded`]: backgroundUrl,
                [`${classPrefix}__background__${theme}`]: !backgroundUrl
            })}>
                <div className={`${classPrefix}-wrapper__main`}>
                    <h1 className={`${classPrefix}-wrapper__main__header ${classPrefix}__text__${theme}`}>{title ? title : ''}</h1>
                    <label className={`${classPrefix}-wrapper__main__description ${classPrefix}__text__${theme}`}>{description ? description : ''}</label>
                    <div className={`${classPrefix}-wrapper__main__date`}>
                        {dateText &&
                            <label className={classnames(`${classPrefix}-wrapper__main__date__text`, {
                                [`${classPrefix}__text__${theme}`]: theme
                            })}>{dateText}</label>
                        }
                        {dateText && link &&
                            <span className={classnames(`${classPrefix}-wrapper__main__date__deco`, {
                                [`${classPrefix}__deco__${theme}`]: theme
                            })}> / </span>
                        }
                        {link &&
                            <LinkWrapper link={link}>
                                <label
                                    className={classnames(`${classPrefix}-wrapper__main__date__link`, {
                                        [`${classPrefix}__link__${theme} ${classPrefix}__text__${theme}`]: theme
                                    })}
                                >
                                    {link.text}
                                </label>
                            </LinkWrapper>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

// ***********************************
// Validation
// ***********************************

FullscreenHeader.propTypes = {
    theme: PropTypes.oneOf(["light", "dark"]),
    title: PropTypes.string,
    description: PropTypes.string,
    dateText: PropTypes.string,
    linkText: PropTypes.string,
    link: PropTypes.shape({
        target: PropTypes.string,
        type: PropTypes.number,
        url: PropTypes.string.isRequired
    }),
    backgroundUrl: PropTypes.string
}

FullscreenHeader.defaultProps = {
    theme: "light"
}

export default FullscreenHeader;