import React from "react";
import { get, getPropValue, getUniqueIdForContentTypeAlias } from "../logic/contentLogic";
import NewsLetterContainer from "./../components/NewsLetterContainer/index";

export const renderFormNestedContent = (content, props = {}) => {
  const formGuid = getPropValue(content, "formGuid");
  // Hard coded untill sketch shows other variants.
  const buttonVariant = "secondary" || getPropValue(content, "formNestedContentButtonType");
  const ctaText = "Abonnér";
  const label = "Din e-postadresse";
  const link = { type: 0, url: "/personvern-og-cookies" };
  const linkText = "Les vår personvernerklæring";

  return (
    <NewsLetterContainer
      key={getUniqueIdForContentTypeAlias(get(content, "contentTypeAlias", "form-container"))}
      variant={buttonVariant}
      ctaText={ctaText}
      label={label}
      formGuid={formGuid}
      link={link}
      linkText={linkText}
    />
  );
};
