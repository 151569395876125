import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import Grid, { GridColumn, GridRow } from "../Grid";
import LinkButton from "../LinkButton";
import { Heading } from "../Typography";
import "./featured.scss";

/** Used for grids of items, e.g. 3 FeaturedContentCards.
 * Receives a collection of items that are fed out. If three items, use smaller headings than default.
 */
const Featured = ({ title, link, linkText, items, imageVerticalOverlap, imageHorizontalOverlap, horizontal }) => {
  const itemsRequireSmallerHeading = items.length === 3;
  let extendedItems = [];
  // Extend items props with smallerHeading
  if (itemsRequireSmallerHeading) {
    extendedItems = items.map(e => ({ ...e, props: { ...e.props, ...{ smallerHeading: true } } }));
  } else {
    extendedItems = items;
  }
  return (
    <div
      className={classnames("bwl-featured", {
        "bwl-featured--image-vertical-overlap": imageVerticalOverlap,
        "bwl-featured--image-horizontal-overlap": imageHorizontalOverlap,
        "bwl-featured--horizontal": horizontal
      })}
    >
      <div className="bwl-featured__information">
        <Heading className="bwl-featured__title" variant="h2">
          {title}
        </Heading>
        {link && linkText && (
          <div className="bwl-featured__link-container">
            <LinkButton variant="text" link={link} text={linkText} />
          </div>
        )}
      </div>
      <div className="bwl-featured__items">
        {items && items.length && (
          <Grid>
            <GridRow>
              {extendedItems.map((item, index) => (
                <GridColumn span={12 / items.length} key={index}>
                  {item}
                </GridColumn>
              ))}
            </GridRow>
          </Grid>
        )}
      </div>
    </div>
  );
};

Featured.propTypes = {
  /** Title for component */
  title: PropTypes.string,
  /** Link for component */
  link: PropTypes.shape({
    target: PropTypes.string,
    type: PropTypes.number,
    url: PropTypes.string.isRequired
  }),
  /** Link text for component */
  linkText: PropTypes.string,
  /** Items rendered in grid for component */
  items: PropTypes.arrayOf(PropTypes.element),
  /** Should items overlap vertically */
  imageVerticalOverlap: PropTypes.bool,
  /** Should items overlap horizontally */
  imageHorizontalOverlap: PropTypes.bool,
  /** Should component bread horizontally */
  horizontal: PropTypes.bool
};

export default Featured;
