import { GET_MEMBER_FAILED, GET_MEMBER_START, GET_MEMBER_SUCCESS } from '../constants/memberConstants';

export default (state = { loading: false }, action) => {
  const { payload = {} } = action;
  switch (action.type) {
    case GET_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        member: payload.member,
      };
    case GET_MEMBER_FAILED:
      return { ...state, loading: false, errorMessage: payload.message };
    case GET_MEMBER_START:
      return { ...state, loading: true };
    default:
      return state;
  }
};
