import classnames from "classnames";
import { Grid, GridColumn, GridRow } from "../bob-web-library/components";
import { contentTypeAliasAsCssClass, get, getPropVariant, getUniqueIdForContentTypeAlias } from "../logic/contentLogic";
import { renderRichText } from "./internalRichTextFactory";
export const renderRichTextEditorNestedContent = (content, props = {}) => {
  const alignItem = get(content, "props.alignItem.value", undefined);
  return (
    <Grid key={getUniqueIdForContentTypeAlias(content.contentTypeAlias)}>
      <GridRow
        key={getUniqueIdForContentTypeAlias(content.contentTypeAlias)}
        className={classnames("row-content", contentTypeAliasAsCssClass(content.contentTypeAlias))}
        alignItem={getPropVariant(alignItem)}
      >
        <GridColumn
          key={getUniqueIdForContentTypeAlias(content.contentTypeAlias)}
          className={classnames("column-content", contentTypeAliasAsCssClass(content.contentTypeAlias))}
          span={6}
          offset={4}
        >
          {renderRichText(content)}
        </GridColumn>
      </GridRow>
    </Grid>
  );
};
