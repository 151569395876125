import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Anchor, Button, CollectionItem, LinkButton, LinkWrapper, MenuItem, PopupMenu, Search, Text } from "../";
import ROUTES from "../../../constants/routeConstants";
import ClickOutsideListener from "../ClickOutsideListener";
import Drawer from "../Drawer";
import LogoContainer from "../LogoContainer";
import "./navBar.scss";

class NavBar extends React.Component {
  static LINK_INTERNAL = { type: 0 };
  static menuActions = {
    OPEN: "open",
    OPEN_SEARCH: "open_search",
    CLOSE: "close",
    BACK: "back",
  };

  static routes = {
    SEARCH: "search",
    LOG_IN: "log_in",
    REGISTER_MEMBER: "register_member",
    CONTACT: "kontakt",
    ROOT: "root",
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedLinkCollection: null,
      focusSearch: false,
      isSearchFocused: false,
      searchQuery: "",
      userMenuOpen: false,
    };
    this.userMenuRef = React.createRef();
    this.userMenuMobileRef = React.createRef();
  }

  componentDidUpdate() {
    if (this.props.menuOpen) {
      window.addEventListener("keydown", this.handleMenuKeyDown);
    } else {
      window.removeEventListener("keydown", this.handleMenuKeyDown);
    }
  }

  /** Not sure what edge case this would cover, but keeping it for safety */
  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleMenuKeyDown);
  }

  handleOpenSubNav = ({ title = null }) => {
    this.setState({ selectedLinkCollection: title });
  };

  handleMenuClick = action => {
    switch (action) {
      case NavBar.menuActions.OPEN:
        this.props.onOpenMenu(true);
        break;
      case NavBar.menuActions.OPEN_SEARCH:
        this.props.onOpenMenu(true);
        this.setState({
          focusSearch: true,
        });
        break;
      case NavBar.menuActions.CLOSE:
        this.props.onOpenMenu(false);
        this.setState({
          selectedLinkCollection: null,
          focusSearch: false,
          isSearchFocused: false,
        });
        break;
      case NavBar.menuActions.BACK:
        this.setState({
          selectedLinkCollection: null,
        });
        break;
      default:
        console.error("handleMenuClicked should take propTypes.oneOf(menuActions) as argument");
    }
  };

  /** If the menu is open, listen for Escape key presses. If Search is focused while Escape is pressed, defer to the handleSearchKeyDown's handling */
  handleMenuKeyDown = event => {
    if (event.key !== "Escape" || this.state.isSearchFocused) return;
    if (this.state.selectedLinkCollection !== null) {
      this.handleMenuClick(NavBar.menuActions.BACK);
    } else {
      this.handleMenuClick(NavBar.menuActions.CLOSE);
    }
  };

  handleSearchKeyDown = event => {
    switch (event.key) {
      case "Enter":
        if (this.state.searchQuery.length < (this.props.minimumQueryChars || 2)) {
          return;
        }
        this.performSearch();
        break;
      case "Escape":
        event.target.value = "";
        break;
      default:
        break;
    }
  };

  performSearch = () => {
    if (this.state.searchQuery) {
      this.handleMenuClick(NavBar.menuActions.CLOSE);
      this.props.onRouteNavigate(NavBar.routes.SEARCH, `?q=${this.state.searchQuery}`);
    }
    this.setState({ searchQuery: "" });
  };

  handleFocusChange = e => {
    this.setState({ isSearchFocused: e.type === "focus" });
  };

  onSearchQueryChange = event => {
    event.persist();
    this.setState({ searchQuery: event.target.value });
  };

  renderLogo() {
    return (
      <LogoContainer
        className="bwl-navbar__logo-container"
        data-route={NavBar.routes.ROOT}
        onClick={e => {
          this.handleMenuClick(NavBar.menuActions.CLOSE);
          this.handleRouteNavigate(e);
        }}
      />
    );
  }

  menuToggleButton(icon, text = "", mobile = false) {
    return (
      <button
        className="menu-toggle-button"
        onMouseDown={e => {
          e.preventDefault();
        }}
      >
        <CollectionItem icon={icon} noMargin={mobile} text={mobile ? "" : text} />
      </button>
    );
  }

  handleRouteNavigate = e => {
    e.preventDefault();
    const route = e.currentTarget.dataset.route;
    if (this.props.onRouteNavigate) {
      this.props.onRouteNavigate(route);
    }
  };

  toggleUserMenu = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ userMenuOpen: !this.state.userMenuOpen });
  };

  handleUserMenuButtonClicked =
    (onClick = () => {}) =>
    e => {
      onClick(e);
      this.setState({ userMenuOpen: false });
    };

  renderDesktopMenu() {
    const link = { url: "#", ...NavBar.LINK_INTERNAL };
    const { user, userMenu, userMenuProps } = this.props;
    const containerClass = classnames("bwl-nav-container bwl-nav-container--desktop", { "bwl-nav-container--darker": this.props.menuOpen });
    return (
      <div className={containerClass}>
        <React.Fragment>
          <div className={"bwl-navbar__menu-container bwl-navbar__menu-container--left"}>
            {this.props.menuOpen ? (
              <MenuItem onClick={() => this.handleMenuClick(NavBar.menuActions.CLOSE)} key="desktop-close-nav-menu-button" aria-label="Menu" leftPaddingDesktop>
                {this.menuToggleButton("close")}
              </MenuItem>
            ) : (
              <React.Fragment>
                <MenuItem onClick={() => this.handleMenuClick(NavBar.menuActions.OPEN)} aria-label="Menu" leftPaddingDesktop>
                  {this.menuToggleButton("navBurger", "Meny")}
                </MenuItem>
                <MenuItem onClick={() => this.handleMenuClick(NavBar.menuActions.OPEN_SEARCH)} aria-label="Search" className="bwl-navbar__search">
                  {this.menuToggleButton("search", "Søk")}
                </MenuItem>
                <MenuItem onClick={() => this.handleMenuClick(NavBar.menuActions.OPEN_SEARCH)} aria-label="Search" className="bwl-navbar__search--icon-only">
                  {this.menuToggleButton("search")}
                </MenuItem>
              </React.Fragment>
            )}
          </div>
          {this.renderLogo()}
          <div className="bwl-navbar__menu-container bwl-navbar__menu-container--right">
            {user ? (
              <>
                <MenuItem
                  onClick={this.toggleUserMenu}
                  // onClick={this.props.onLogout}
                  aria-label="Log out"
                >
                  <div
                    className={classnames("bwl-navbar__menu-item--button-profile", {
                      "bwl-navbar__menu-item--button-profile--user-menu-open": this.state.userMenuOpen,
                    })}
                  >
                    <Button
                      variant="secondary"
                      customIcon="navProfile"
                      // onClick={this.props.onLogout}
                    />
                    <CollectionItem text={user.name} />
                    <Button
                      variant="tertiary"
                      customIcon="close"
                      ref={this.userMenuRef}

                      // onClick={this.props.onLogout}
                    />
                  </div>
                </MenuItem>
                {this.renderPopupMenu(this.userMenuRef)}
              </>
            ) : (
              <>
                <MenuItem aria-label="Log in" className="bwl-navbar__login">
                  <LinkWrapper link={{ url: ROUTES.LOG_IN }}>
                    <CollectionItem text="Min side" />
                  </LinkWrapper>
                </MenuItem>
                <MenuItem aria-label="Profile" className="bwl-navbar__member--small">
                  <LinkWrapper link={{ url: ROUTES.LOG_IN }}>
                    <CollectionItem noMargin icon={this.props.user ? "navProfile" : "login"} />
                  </LinkWrapper>
                </MenuItem>
                <div className="bwl-navbar__menu-item--button bwl-navbar__member">
                  <Button variant="secondary" text="Bli medlem" onClick={this.handleRouteNavigate} data-route={NavBar.routes.REGISTER_MEMBER} />
                </div>
                <div className="bwl-navbar__menu-item--button bwl-navbar__login">
                  <LinkButton
                    variant="i-dont-know-why-it-this-style-isnt-defined-but-it-fits-better-than-any-that-are-I-guess-its-some-fallback-but-why-not-make-it-a-variant"
                    text="Styreportalen"
                    link={{ url: ROUTES.BOARD_PORTAL, type: 2, target: "_blank" }}
                  />
                </div>
              </>
            )}
          </div>
        </React.Fragment>
      </div>
    );
  }

  renderMobileMenu() {
    const topLeftIcon = this.state.selectedLinkCollection ? "back" : this.props.menuOpen ? "close" : "navBurger";
    const menuClickAction = this.state.selectedLinkCollection
      ? NavBar.menuActions.BACK
      : this.props.menuOpen
      ? NavBar.menuActions.CLOSE
      : NavBar.menuActions.OPEN;
    const link = { url: "#", ...NavBar.LINK_INTERNAL };
    return (
      <div className="bwl-nav-container bwl-nav-container--mobile">
        {menuClickAction !== NavBar.menuActions.OPEN ? (
          <React.Fragment>
            <div className="bwl-navbar__menu-container bwl-navbar__menu-container--open">
              {topLeftIcon === "back" ? (
                <MenuItem
                  onClick={() => this.handleMenuClick(menuClickAction)}
                  key="mobile-close-nav-menu-button-1"
                  aria-label="Menu"
                  leftPaddingMobile
                  rightPaddingMobile
                >
                  {this.menuToggleButton("back", "", true)}
                </MenuItem>
              ) : (
                <MenuItem
                  onClick={() => this.handleMenuClick(menuClickAction)}
                  key="mobile-close-nav-menu-button-5"
                  aria-label="Menu"
                  leftPaddingMobile
                  rightPaddingMobile
                >
                  {this.menuToggleButton(topLeftIcon, "", true)}
                </MenuItem>
              )}
            </div>
            <MenuItem fullWidth noHover>
              <Search
                label="Søk..."
                renderTransitionLine
                onKeyDown={this.handleSearchKeyDown}
                onFocus={this.handleFocusChange}
                onBlur={this.handleFocusChange}
                keepLabelOnFocus
                placeholder="Søk..."
                onClickIcon={this.performSearch}
                onChange={this.onSearchQueryChange}
                value={this.state.searchQuery}
              />
            </MenuItem>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="bwl-navbar__menu-container bwl-navbar__menu-container--left">
              <MenuItem
                onClick={() => this.handleMenuClick(NavBar.menuActions.OPEN)}
                key="mobile-close-nav-menu-button-2"
                aria-label="Menu"
                leftPaddingMobile
                rightPaddingMobile
              >
                {this.menuToggleButton(topLeftIcon, "", true)}
              </MenuItem>
            </div>

            {this.renderLogo()}
            <div className="bwl-navbar__menu-container bwl-navbar__menu-container--right">
              <MenuItem
                onClick={this.props.user ? this.toggleUserMenu : () => (window.location.href = ROUTES.LOG_IN)}
                data-route={NavBar.routes.LOG_IN}
                aria-label="Profile"
                ref={this.userMenuMobileRef}
                rightPaddingMobile
              >
                <LinkWrapper link={{ url: ROUTES.LOG_IN }}>
                  <CollectionItem icon={this.props.user ? "navProfile" : "login"} noMargin />
                </LinkWrapper>
              </MenuItem>
            </div>
            {this.props.user && this.renderPopupMenu(this.userMenuMobileRef, 0)}
          </React.Fragment>
        )}
      </div>
    );
  }

  renderPopupMenu = (ref, distance = 24) => {
    const { userMenu, userMenuProps } = this.props;
    return (
      <PopupMenu anchorEl={ref.current} open={this.state.userMenuOpen} className="bwl-navbar__user-menu" distance={distance}>
        {userMenu}
        {!userMenu && userMenuProps && this.state.userMenuOpen && (
          <ClickOutsideListener onClick={this.toggleUserMenu}>
            <div className="bwl-navbar__user-menu--container">
              <div className="bwl-navbar__user-menu--links">
                {userMenuProps.buttonLinks.map(linkProps => (
                  <Anchor
                    key={linkProps.text}
                    arrow={typeof linkProps.arrow !== "undefined" ? linkProps.arrow : true}
                    {...linkProps}
                    onClick={this.handleUserMenuButtonClicked(linkProps.onClick)}
                  >
                    {linkProps.text}
                  </Anchor>
                ))}
              </div>

              <div className="bwl-navbar__user-menu--buttons">
                <Button text="Logg ut" onClick={this.handleUserMenuButtonClicked(userMenuProps.logout)} />
                {userMenuProps.boardPortalLinkProps && (
                  <Button
                    variant="secondary"
                    text="Til styreportalen"
                    {...userMenuProps.boardPortalLinkProps}
                    onClick={this.handleUserMenuButtonClicked(userMenuProps.boardPortalLinkProps.onClick)}
                  />
                )}
              </div>
              <div className="bwl-navbar__user-menu--info">
                <Text variant="description">{`Medlemsnummer: ${userMenuProps.memberNumber}`}</Text>
                <Text variant="description">{`Ansiennitet fra: ${userMenuProps.seniorityDate}`}</Text>
              </div>
            </div>
          </ClickOutsideListener>
        )}
      </PopupMenu>
    );
  };

  render() {
    const { items, additionalItems, location, drawerProps, user } = this.props;
    return (
      <div className="bwl-navbar">
        {this.renderDesktopMenu()}
        {this.renderMobileMenu()}
        <Drawer
          user={user}
          className={drawerProps && drawerProps.className}
          anchor="top"
          open={this.props.menuOpen}
          onNavigate={this.props.onRouteNavigate}
          handleMenuClick={this.handleMenuClick}
          handleOpenSubNav={this.handleOpenSubNav}
          selectedLinkCollection={this.state.selectedLinkCollection}
          items={items}
          additionalItems={additionalItems}
          location={location}
          focusSearch={this.state.focusSearch}
          searchCallbacks={{
            onKeyDown: this.handleSearchKeyDown,
            handleFocusChange: this.handleFocusChange,
            onChange: this.onSearchQueryChange,
            onClickIcon: this.performSearch,
          }}
          currentSearchQuery={this.state.searchQuery}
        />
      </div>
    );
  }
}

NavBar.propTypes = {
  isLoggedIn: PropTypes.bool,
  items: PropTypes.array.isRequired,
  additionalItems: PropTypes.array.isRequired,
  onOpenMenu: PropTypes.func.isRequired,
  menuOpen: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default NavBar;
