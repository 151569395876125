import Quote from "../bob-web-library/components/Quote";
import { contentTypeAliasAsCssClass, get, getUniqueIdForContentTypeAlias } from "../logic/contentLogic";

export const renderQuoteNestedContent = (content, props = {}) => {
  return (
    <Quote
      key={getUniqueIdForContentTypeAlias(content.contentTypeAlias)}
      className={contentTypeAliasAsCssClass(content.contentTypeAlias)}
      author={get(content, "props.author.value")}
      quote={get(content, "props.quote.value")}
    />
  );
};
