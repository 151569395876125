import classnames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import Grid from "../../../Grid/components/Grid";
import GridColumn from "../../../Grid/components/GridColumn";
import GridRow from "../../../Grid/components/GridRow";
import { PlayIcon } from "../../../Icons";
import "./fullscreenVideo.scss";




const FullscreenVideo = (props) => {
    const { theme, caption, video, spaced } = props;

    // ************************************
    // Properties
    // ************************************

    const classPrefix = "bwl-FullscreenVideo";
    const desktopMinWidth = 720;

    // ************************************
    // Lifecycle
    // ************************************

    const videoRef = useRef();
    const [paused, setPaused] = useState(!video.autoplay);
    const [isDesktop, setIsDesktop] = useState(false);

    // ************************************
    // Lifecycle
    // ************************************

    // 1. On initial render
    useEffect(() => {
        window.removeEventListener('resize', updateSize);
        window.addEventListener('resize', updateSize);

        setIsDesktop(window.innerWidth > desktopMinWidth);
    }, [])

    // 2. If autoplay is set
    useEffect(() => {
        if (video.autoplay) {
            playerPlay(video.autoplay);
        }
    }, [videoRef, video]);

    // 3. On state paused change
    useEffect(() => {
        if (videoRef) {
            playerPlay(!paused);
        }
    }, [paused]);

    // ************************************
    // Update
    // ************************************

    const updateSize = () => {
        setIsDesktop(window.innerWidth > desktopMinWidth);
    }

    // ************************************
    // Video Settings
    // ************************************

    const playerPlay = (state) => {
        if (videoRef) {
            if (state) {
                videoRef.current?.play();
            } else {
                videoRef.current?.pause();
            }
        }
    }

    const videoSource = () => {
        return video.src;
    }

    const canFullscreen = () => {
        return isDesktop && !spaced && !caption;
    }

    // ************************************
    // Render
    // ************************************

    return (
        <div className={classnames(`${classPrefix}`, {
            [`${classPrefix}__background__${theme}`]: theme
        })}>
            {video &&
                <>
                    <Grid className={classnames(`${classPrefix}__container`, {
                        [`${classPrefix}__container__spaced`]: !canFullscreen()
                    })}>
                        <GridRow
                            className={`${classPrefix}__container__row-1`}
                            alignItem={"center"}
                        >
                            <GridColumn
                                className={`${classPrefix}__container__column-1`}
                                offset={canFullscreen() ? 0 : 2}
                                offsetMobile={0}
                                span={canFullscreen() ? 12 : 10}
                                spanMobile={12}
                            >
                                {video.src &&
                                    <div className={classnames(`${classPrefix}__container__wrapper`, {
                                        [`${classPrefix}__container__wrapper__spaced`]: isDesktop && (caption || spaced)
                                    })}>
                                        <video
                                            className={
                                                classnames(`${classPrefix}__container__wrapper__video`, {
                                                    [`${classPrefix}__container__wrapper__video__spaced`]: isDesktop && (caption || spaced)
                                                })
                                            }
                                            onClick={() => {
                                                if (video.controls) {
                                                    setPaused(!paused);
                                                }
                                            }}
                                            autoPlay={video.autoplay}
                                            muted={video.autoplay ? true : video.muted}
                                            loop={video.loop}
                                            ref={videoRef}
                                            key={videoSource()}
                                            poster={video.poster}
                                            controls={video.controls && !paused ? true : false}
                                        >
                                            <source src={videoSource()} type="video/mp4" />
                                        </video>
                                        {paused && video.controls &&
                                            <div
                                                className={`${classPrefix}__play-button`}
                                                onClick={() => {
                                                    setPaused(!paused);
                                                }}
                                            >
                                                <PlayIcon
                                                    className={classnames(`${classPrefix}__play-button__icon`, {
                                                        [`${classPrefix}__fill__${theme}`]: theme
                                                    })}
                                                    width={222}
                                                    height={222}
                                                />
                                            </div>
                                        }
                                    </div>
                                }
                            </GridColumn>
                        </GridRow>
                        {caption &&
                            <GridRow alignItem={"start"}>
                                <GridColumn
                                    offset={canFullscreen() ? 0 : 2}
                                    offsetMobile={0}
                                    span={canFullscreen() ? 12 : 10}
                                    spanMobile={12}
                                >
                                    <div className={`${classPrefix}__container__caption`}>
                                        <label className={classnames(`${classPrefix}__container__caption__label`, {
                                            [`${classPrefix}__text__${theme}`]: theme,
                                            [`${classPrefix}__container__caption__label__m`]: !isDesktop
                                        })}>{caption}</label>
                                    </div>
                                </GridColumn>
                            </GridRow>
                        }
                    </Grid>
                </>
            }
        </div>
    );
}

// ************************************
// Validation
// ***********************************

FullscreenVideo.propTypes = {
    theme: PropTypes.oneOf(["light", "dark"]),
    caption: PropTypes.string,
    spaced: PropTypes.bool,
    video: PropTypes.shape({
        src: PropTypes.string,
        poster: PropTypes.string,
        controls: PropTypes.bool,
        autoplay: PropTypes.bool,
        loop: PropTypes.bool,
        muted: PropTypes.bool
    }).isRequired
}

FullscreenVideo.defaultProps = {
    theme: "light",
    spaced: false,
    video: {
        src: undefined,
        poster: undefined,
        controls: false,
        autoplay: false,
        muted: false,
        loop: false
    }
}

export default FullscreenVideo;